@import '../../.styles/global.scss';

.container {
  .input-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    pointer-events: none;
    transform: translate(14px, 16px) scale(1);
    z-index: 1;

    &:global(.MuiInputLabel-shrink) {
      transform: translate(14px, -6px) scale(.85);
    }
  }

  .input-root {
    height: 48px;
    padding: 5px 9px;

    .input {
      font-size: 14px;
      font-weight: 400;
      padding: 5px 4px;
    }

    .end-adornment {
      .clear-indicator {
        background-color: transparent;
        border: 1px solid $primary;
        color: $primary;
        font-size: 12px;
        margin-right: 5px;
        visibility: hidden;

        :global(.MuiSvgIcon-root) {
          color: $primary;
          font-size: 12px;
        }
      }

      .dropdown-icon__open {
        color: $primary;
      }
    }

    &:global(.Mui-focused) {
      .clear-indicator {
        visibility: visible;
      }
    }
  }
}
