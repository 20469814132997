@import '../../src/.styles/global';

.spinner {
  $root: &;

  font-size: 10px;
  text-align: center;

  @keyframes sk-stretchdelay {
    0%,
    40%,
    100% { transform: scaleY(.4); }
    20% { transform: scaleY(1); }
  }

  &-bar {
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
    background-color: $primary-450;
    display: inline-block;
    height: 100%;

    &:nth-child(2) { animation-delay: -1.1s; }
    &:nth-child(3) { animation-delay: -1s; }
    &:nth-child(4) { animation-delay: -.9s; }
    &:nth-child(5) { animation-delay: -.8s; }
  }

  &--large {
    height: 40px;
    width: 50px;

    #{$root}-bar {
      margin: 0 2px;
      width: 6px;
    }
  }

  &--medium {
    height: 26px;
    padding: 5px 0;
    width: 44px;

    #{$root}-bar {
      margin: 0 1px;
      width: 4px;
    }
  }

  &--small {
    height: 16px;
    padding: 4px 0;
    width: 20px;

    #{$root}-bar {
      margin: 0 1px;
      width: 2px;
    }
  }

  &-backdrop {
    align-items: center;
    background: rgba($black, .6);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100000;
  }
}

.center {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  width: 100%;
}

.screenCenter {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.whiteLabel {
  color: white;
}
