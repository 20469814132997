.country-select {
  &__error {
    font-size: 10px;
  }
}

.country-select-input {
  :global(.MuiInputBase-input) {
    margin-right: 50px;
  }
}
