@import '~@gateway/components/src/.styles/global';

.exchange-rate-info {
  border-left: 1px solid $gray;
  color: $light-black;
  margin-left: 10px;
  padding: 34px 8px;

  :global(.MuiTypography-body2) {
    font-weight: 400;
  }

  &--container {
    display: flex;
    flex-direction: column;
  }

  &--label {
    align-items: center;
    display: flex;
  }
}

.exchange-rate-info__empty {
  border-left: 1px solid $gray;
  color: $light-black;
  margin-left: 10px;
  padding: 20px 8px;
}

.exchange-rate-info__empty_for_split {
  border-top: 1px solid $gray;
  color: $light-black;
  margin-top: 24px;
  padding: 20px 8px;
  width: 4%;
}

.loader {
  margin: auto;
}

.help-tooltip {
  left: -165px;
  position: fixed;
  top: -5px;
  width: 280px;

  :global(.MuiTooltip-arrow) {
    left: 50%;
    transform: translateX(1200%);
  }
}

.help-label-payment-form {
  border-bottom: 1px dotted rgba(33, 33, 33, .38);
  position: absolute;
}
