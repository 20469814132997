@import './colors';

// Grid variables
$grid-breakpoints: (desktop: 1024px, tablet: 768px, phone: 0 );
$grid-columns: (desktop: 12, tablet: 8, phone: 4);
$grid-margin: (desktop: 24px, tablet: 16px, phone: 16px);
$grid-gutter: (desktop: 24px, tablet: 16px, phone: 16px);
$grid-column-width: (desktop: 72px, tablet: 72px, phone: 72px);
$grid-column-span: 4;
$grid-max-width: null;
$grid-xl-breakpoint: 1921px;
$grid-xl-max-width: 1672px;

// Custom Grid variables
$grid-child-padding: 0;
$grid-child-min-width: 100%;

// Visual variables
$tile-box-shadow: 0 3px 4px 0 rgba($black, .14), 0 3px 3px -2px rgba($black, .12), 0 1px 8px 0 rgba($black, .2);
$footer-box-shadow: 0 -2px 4px rgba(0, 0, 0, .25);
$menu-box-shadow: 0 4px 5px 0 $box-shadow, 0 1px 10px 0 $box-shadow, 0 2px 4px -1px $box-shadow;

$zIndex-snackbar: 1400;

/*
 * If these variables are going to be changed
 * Please update them in the following components as well
 * packages/components/src/layout/media/use-menu-offset.tsx
 * packages/components/src/layout/media/breakpoint.ts
 */
$nav-sidebar-width: 72px;
$nav-sidebar-md-breakpoint: 768px;
$nav-sidebar-lg-breakpoint: 1280px;
$nav-sidebar-xl-breakpoint: 1440px;
$nav-sidebar-xl-width: 200px;

$header-appbar-height: 56px;
$header-height: 88px;
$header-mobile-height: 120px;
$footer-height: 60px;
$footer-padding: 50px;
$footer-full-height: $footer-height + $footer-padding;

$login-form-width: 326px;

$footer-and-header-height: $header-appbar-height + $footer-full-height;
$content-height: calc(100vh - #{$footer-and-header-height});

$bottom-navigation-height: 74px;
$bottom-navigation-padding-bottom: 15px;
$bottom-navigation-full-height: $bottom-navigation-height + $bottom-navigation-padding-bottom;

$bottom-navigation-height-submenu-enabled: 82px;
$bottom-navigation-padding-bottom-submenu-enabled: 16px;

$textfield-margin: 20px 0;
$large-textfield-margin: 20px 0 28px;
$textfield-double-margin: 40px 0;

/* Font Sizes */
$body1-font-size: 1rem;
$body2-font-size: .875rem;

$badge-height: 20px;
