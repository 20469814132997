@import '../.styles/global';

.overlay-actions {
  align-items: center;
  cursor: pointer;
  display: flex;
  user-select: none;

  button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font: inherit;
    outline: none;
    padding: 0;
  }

  &-left,
  &-right {
    align-items: center;
    display: flex;
  }

  &-verticalRule {
    border-left: 1px solid $white;
    cursor: not-allowed;
    height: 30px;
    margin: 8px;
  }

  &-title {
    &:global(.MuiTypography-root) {
      font-weight: 500;
    }
  }

  &-disabled {
    color: $surface-disabled-ME;
    cursor: not-allowed;
  }
}
