@import '~@gateway/components/src/.styles/global';

@mixin tablet-screen {
  height: auto;
}

.sms-mfa-container {
  background-color: $white;
  width: 100%;

  .title-container {
    display: inline-block;
    height: 50px;
    line-height: 45px;
    margin-bottom: 10px;
    vertical-align: middle;
    width: 100%;

    .sms-icon {
      background-image: url('../mfa-auth-option/assets/mfa-sms-icon.svg');
      background-repeat: no-repeat;
      background-size: 40px 40px;
      float: left;
      height: 50px;
      margin-left: -5px;
      padding-right: 10px;
      vertical-align: middle;
      width: 35px;
    }
  }

  .title {
    color: $info;
    font-size: 20px;
    line-height: 34px;
    margin: 0 0 15px;
  }

  .body-copy {
    color: $info;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 13px;
    width: 100%;

    @include mobile {
      width: 300px;
    }

    &__text {
      font-weight: 400;
    }

    &__instructions {
      font-size: 16px;
      font-weight: 500;
      line-height: 2.6rem;
    }

    &__req {
      margin: 0 0 16px;
    }
  }

  .request-link {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    line-height: 2.6rem;

    @include mobile {
      font-size: 14px;
    }
  }

  .auth-form-text-field {
    background-color: $white;
    border-color: $light-gray;
    color: $darker-gray;
    font-size: 14px;
    margin: $textfield-margin;
    margin-top: 10px;

    @include mobile {
      width: 100%;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;

    .cancel-button {
      width: fit-content;
    }

    .verify-button {
      margin-left: 10px;
      padding: 8px;
      width: fit-content;
    }

    @include mobile {
      justify-content: space-between;

      .cancel-button {
        width: 100%;
      }

      .verify-button {
        width: 100%;
      }
    }
  }

  .help-text {
    color: $darker-gray;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    width: 95%;

    &__info {
      font-weight: normal;
    }

    @include mobile {
      font-size: 10px;
    }
  }
}

@include mobile {
  .frm-text-field {
    width: 100%;
  }

  .auth-form-text-field {
    width: 100%;
  }

  .body-copy {
    font-size: 13px;
    line-height: 22px;
  }
}

.payment-container {
  background-color: $primary-light;
  margin: 8px 0 24px;
  padding: 8px 24px;

  .to {
    color: $light-black;
    padding-right: 29px;
  }

  .amount {
    color: $light-black;
  }
}

.payment-details {
  align-items: center;
  display: flex;
  gap: 8px;
  overflow: auto;
  padding-top: 8px;
}

.payment-details-title {
  align-items: center;
  display: flex;
  gap: 8px;
}
