@import '~@gateway/components/src/.styles/global';

.languages {
  &__languages {
    color: $primary;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    height: 50px;
    margin-right: 25px;
    padding-right: 25px;
    width: 200px;
  }
}
