@import '../.styles/_colors.scss';

.autocomplete {
  &-root {
    :global(.MuiInputLabel-root) {
      font-size: 16px;
      font-weight: 400;
    }

    :global(.MuiInputBase-input) {
      height: 19px;
    }

    :global(.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']) {
      padding: 0;
    }

    .input-root {
      .clear-indicator {
        background-color: transparent;
        border: 1px solid $primary;
        color: $primary;
        font-size: 12px;
        margin-right: 5px;

        :global(.MuiSvgIcon-root) {
          color: $primary;
          font-size: 12px;
        }
      }

      .input {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        padding: 5px 4px;
        text-overflow: unset;
      }

      .input--focused:not(:disabled) {
        color: $black;
      }

      .end-adornment {
        padding-left: 13px;
        position: relative;

        .popup-indicator__open {
          color: $primary;
        }
      }
    }
  }
}

.autocomplete-popper {
  .autocomplete-paper {
    box-shadow: 0 4px 5px 0 $box-shadow, 0 1px 10px 0 $box-shadow, 0 2px 4px -1px $box-shadow;
    margin: 0;
  }

  .autocomplete-listbox {
    padding: 0;
  }

  .autocomplete-option {
    align-items: center;
    border-bottom: 1px solid $light-gray;
    color: $info;
    display: flex;
    margin: 0;
    overflow: hidden;
    padding: 10px 16px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      background-color: $hovered-faded-bg-color;
    }

    &[aria-selected='true'] {
      background-color: $hovered-faded-bg-color;
      font-weight: 500;
    }

    &[data-focus='true']:not([aria-selected='true']) {
      background-color: $hovered-faded-bg-color;
    }

    &:last-child {
      border: none;
    }
  }
}
