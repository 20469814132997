@import '~@gateway/components/src/.styles/global';

.manage-user-profile {
  margin-top: $header-height;

  :global(.MuiDrawer-paper),
  :global(.MuiBackdrop-root) {
    top: $header-height;
  }

  :global(.MuiDrawer-paper) {
    height: calc(100% - #{$header-height});
    width: 322px;
  }

  :global(.MuiPaper-root) {
    width: 322px;
  }

  &__container {
    margin: 0 0 16px 16px;

    &--with-border {
      border-bottom: 1px solid rgba($grey-border, .38);
      margin: 0 16px;
      padding: 15px 0;
    }
  }

  &__label {
    color: $primary;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;

    &:hover,
    &:active {
      color: $on-secondary;
    }
  }

  &__link {
    align-items: center;
    color: $primary;
    cursor: pointer;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
    width: fit-content;

    &-indicator {
      color: $error;
      position: absolute;

      &:global(.material-icons) {
        font-size: 9px;
      }
    }
  }
}
