@import '../../.styles/global.scss';

.form-control {
  box-sizing: border-box;
  width: 100%;
}

.form-label :global(.MuiTypography-root) {
  color: #000;
  font-size: 16px;
  margin-bottom: 8px;
  margin-left: 16px;
}

.form-value {
  &:global(.MuiFormControlLabel-root):not(:first-of-type) {
    margin-left: 5px;
  }

  :global(.MuiTypography-root) {
    font-size: 14px;
    font-weight: normal;
  }

  :global(.MuiIconButton-root):hover {
    background-color: transparent;
  }
}
