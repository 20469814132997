@import '~@gateway/components/src/.styles/global';

@mixin tablet-screen {
  height: auto;
}

.biometric-notification-container {
  background-color: $white;

  .title {
    color: $info;
    font-size: 24px;
    line-height: 34px;
    margin: 0 0 15px;
    padding-left: .5rem;
  }

  .title-cas {
    color: $info;
    font-size: 20px;
    line-height: 20px;
    margin: 0 0 15px;
  }

  .body-copy-cas {
    color: $info;
    font-size: 16px;
    line-height: 21px;
    margin: 0 0 10px;

    &__text {
      font-size: 14px;
      font-weight: 400;
    }

    &__text1 {
      font-size: 14px;
      font-weight: 400;
      margin: 0 0 15px;
    }

    &__text2 {
      font-size: 15px;
      padding-left: 350px;
    }

    &__text3 {
      font-size: 14px;
      font-weight: 400;
      padding-left: 260px;
    }
  }

  .body-copy {
    color: $info;
    font-size: 13px;
    line-height: 20px;
    padding-left: .5rem;
    width: 90%;

    &__text {
      font-weight: 400;
      margin-bottom: 14px;
    }

    &__text1 {
      font-weight: 400;
    }

    &__text2 {
      font-weight: 400;
    }
  }

  .request-link {
    cursor: pointer;
    font-size: 16px;

    @include mobile {
      font-weight: 400;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;

    .cancel-button {
      width: fit-content;
    }

    .continue-button {
      margin-left: 10px;
      padding: 8px;
      width: fit-content;
    }

    .resend-button {
      margin-left: 10px;
      padding: 8px;
      width: fit-content;
    }

    @include mobile {
      justify-content: flex-end;

      .cancel-button {
        width: 100%;
      }

      .resend-button {
        width: 100%;
      }

      .continue-button {
        width: 100%;
      }
    }
  }
}

.pending-message {
  display: flex;
  justify-content: flex-end;

  .pending-message-body {
    color: $error;
    font-size: 12px;
    font-weight: 400;
  }
}

.timer-container {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 200px;
}
