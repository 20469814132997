@import '~@gateway/components/src/.styles/global';

.details {
  $self: &;

  background-color: $white;
  padding: 8px 0;
  text-align: left;

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 0;

    @include desktop-or-tablet {
      padding: 16px 0;

      :global(.MuiTypography-caption) {
        font-size: 1.25rem;
      }
    }
  }

  .actions--wrapper {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }

  &__header-info {
    display: block;

    :global(.MuiTypography-body1):first-child {
      font-weight: 400;
    }

    &--content {
      display: inline-flex;
      padding: 16px 0;
    }

    &--icon {
      color: $utility-info;
      font-size: 24px;
      margin-right: 8px;
    }
  }

  &__header-icon {
    color: $primary;
    font-size: 24px;
    height: 32px;
    padding: 0;
    width: 32px;
  }

  &__divider {
    border-color: $darker-gray;
    margin: 5px 0;
  }

  &__header-shadow {
    background: var(--neutrals-grey-00, $white);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .06), 0 4px 8px 0 rgba(0, 0, 0, .04);
  }

  &__field {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
  }
}

.field {
  &__label {
    color: $light-black;
  }

  &__value {
    padding-top: 4px;
    word-break: break-all;

    &:global(.MuiTypography-body1) {
      font-weight: 400;
    }
  }
}

.mobile-subtext {
  color: $light-black;
  font-size: 12px;
}

.two-column {
  display: grid;
  grid-auto-flow: dense;
  grid-gap: 9px;
  grid-template-columns: 1fr 1px 1fr;

  &.two-column-no-divider {
    grid-template-columns: 1fr 1fr;
  }

  &__left,
  &__right {
    display: flex;
    flex-direction: column;
  }

  &__delimiter {
    background-color: $secondary-5;
  }
}

.three-column {
  display: grid;
  grid-auto-flow: dense;
  grid-gap: 9px;
  grid-template-columns: 1fr 1px 1fr 1px 1fr;

  &.three-column-no-divider {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__left,
  &__middle,
  &__right {
    display: flex;
    flex-direction: column;
  }

  &__delimiter {
    background-color: $secondary-5;
  }
}
