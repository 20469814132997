@import '~@gateway/components/src/.styles/global';
@import './styles/mixins';

:root {
  background-color: $white;
  scroll-behavior: smooth;
}

.dialog {
  @include dialog-content;

  .message {
    font-weight: 400;
  }

  &:global(.mdc-button) {
    height: 32px;
    letter-spacing: 0;

    @include mobile {
      margin-top: 8px;
    }
  }
}

.topAppBar {
  display: flex;
  padding: 0 24px;

  @include mobile {
    padding: 0 16px;
  }
}

.topAppBar .topAppBarIconStyle {
  color: $black;
}

.topAppBarTitle {
  flex: 1;

  @include mobile {
    text-align: right;
  }
}

.hero-content {
  background-color: $neutral-400;
}

.hero-actions {
  color: $primary;
}

.button-container {
  display: flex;
  justify-content: space-between;
  padding: 16px;

  .select-template-btn {
    width: inherit;
  }
}

.errorMessage {
  background-color: rgba(246, 144, 179, .04);
  border: 1px solid $red;
  border-radius: 4px;
  color: $error;
  height: 58px;
  margin: 15px auto 0;
  padding: 0 10px;
  text-align: center;
  width: 325px;
}

.page-layout {
  overflow-x: hidden;
  padding: 0;
}

.container {
  background: transparent;
  margin: 0;
  padding: 0;

  & > div {
    grid-gap: 0;
    height: 100%;
    width: 100%;

    & > div {
      width: 100%;
    }

    @include mobile {
      grid-template-rows: auto 20%;
    }

    @include tablet-portrait {
      display: flex;
      flex-direction: row;
    }
  }
}

.continue-button {
  height: 48px;

  &:not(:disabled) {
    background-color: $primary;
  }
}

.back-button,
.cancel-button {
  height: 48px;
}

.action-btns {
  &:global(.mdc-button) {
    height: 48px;
  }

  :global(.mdc-button--unelevated:not(:disabled)) {
    background-color: $primary;
    border-color: $primary;
  }
}

.payment-content {
  background-color: $surface-disabled;
  height: unset;
  overflow-x: hidden;
  overflow-y: hidden;

  @include mobile {
    height: calc(100vh - 64px);
    overflow-y: scroll;
  }
}

.snackbar {
  &:global(.MuiSnackbar-anchorOriginBottomLeft) {
    @include desktop-or-tablet {
      bottom: 90px;
    }
  }
}

.mobile-banner-container {
  background-color: $white;
  position: sticky;
  top: $header-appbar-height;
  z-index: $z-index-slider;
}

.layout {
  background-color: $surface-disabled;
}

.tab-bar {
  background: $white;
  border-bottom: 1px solid $alto-gray;
  padding-left: 28px;
}

.warning-icon {
  color: $warning;
  font-size: 40px;
}

.warning-container {
  align-items: center;
  display: flex;
  gap: 10px;

  &__icon {
    color: $warning;
    font-size: 40px;
  }

  &__error-icon {
    color: $error;
    font-size: 40px;
  }
}

.spinner {
  margin: 400px 50% 0;
}

.banner {
  position: sticky;
}

.message-line-height {
  line-height: 22px;
}
