@import '~@gateway/components/src/.styles/global';

$header_and_buttons_height: 250px;

.mobile-bulletin {
  background-color: $white;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1400;

  :global(.mdc-button) {
    height: 32px;
    margin-top: 16px;
    width: 101px;

    &::before {
      transition: none;
    }
  }

  &--hide {
    z-index: 1200;
  }

  &__button-container {
    height: 56px;
    padding-bottom: 8px;
  }

  &__button {
    margin-right: 8px;
    position: absolute;
    right: 0;
  }

  &__header {
    height: 56px;
    padding-left: 20px;
    padding-top: 8px;
  }

  &__icon {
    vertical-align: middle;
  }

  &__title {
    font-weight: 500;
    margin-left: 12px;
  }
}

.alert-root {
  width: 736px;

  :global(.mdc-button) {
    margin-top: 0;
  }

  &--hide:global(.MuiPaper-root) {
    display: none;
  }

  &:global(.MuiBackdrop-root) {
    background-color: transparent;
  }

  &:global(.MuiPaper-root) {
    max-width: unset;

    :global(.MuiDialogContent-root) {
      margin-bottom: 0;
      word-break: break-word;
    }
  }
}

.alert-content:global(.MuiDialogContent-root) {
  overflow: hidden;
  padding-right: 8px;
}

.alert-title:global(.MuiDialogTitle-root) {
  font-weight: 500;
  padding-top: 24px;
  word-break: break-word;
}

.alert-icon:global(.rmwc-icon) {
  font-size: 38px;
  margin-right: 14px;
}

.start-date {
  display: inline-block;
  font-size: 14px;
  height: 24px;
  line-height: 20px;
  margin-bottom: 5px;

  @include mobile() {
    padding-left: 25px;
    padding-top: 15px;
  }
}

.content {
  display: block;
  line-height: 22px;
  max-height: 429px;
  overflow-y: auto;
  padding-right: 16px;

  @include scrollbar-thin();

  @include mobile() {
    height: calc(100% - 167px);
    max-height: unset;
    padding-left: 25px;
  }
}
