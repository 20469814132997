@import '~@gateway/components/src/.styles/global';

html,
body,
:global(#root) {
  height: 100%;
  overflow: auto;
}

body {
  padding: 0 !important;
}

.app,
body {
  font-family: 'Roboto', sans-serif;
  height: 100%;
}
