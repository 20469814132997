@import '~@gateway/components/src/.styles/global';

.mfa-dialog {
  @include mobile {
    height: 100%;
    width: 100%;
  }

  /* stylelint-disable declaration-no-important */
  z-index: 1500 !important;

  :global(.MuiDialog-paperFullScreen) {
    align-items: center;
    display: grid;
    margin: 7px;
    max-height: 96%;
  }
}

.dialog-container {
  background: $white;
  height: auto;
  position: relative;
  width: 536px;

  @include mobile {
    height: 100%;
    padding: 24px;
    width: 100%;
  }

  @include tablet {
    padding: 24px;
  }

  .mfa-modal-close-btn {
    background-color: $white;
    color: $primary-400;
    cursor: pointer;
    height: 20px;
    margin: 23px 16px 0 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    z-index: 1;

    @include mobile {
      margin: 13px 13px 0 0;
    }
  }

  a {
    color: $primary-400;
    font-weight: 500;
    text-decoration: none;
  }
}
