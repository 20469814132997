@import '~@gateway/components/src/.styles/global.scss';

.statement {
  $this: &;

  &__compact {
    #{$this}__date {
      font-weight: 500;
    }
  }

  &__label {
    :global(.MuiTypography-body2) {
      font-weight: 400;
    }
  }
}
