@mixin grid-inner($default-height, $phone-height) {
  :global(.mdc-layout-grid) {
    height: 100%;
  }

  :global(.mdc-layout-grid__inner) {
    grid-template-rows: 1fr auto;
    height: 100%;
  }

  @include desktop-or-tablet {
    height: $default-height;

    :global(.mdc-layout-grid__inner) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }

  @include mobile {
    height: $phone-height;

    :global(.mdc-layout-grid__inner) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

@mixin grid-item {
  @include desktop-or-tablet {
    grid-column: 4/10;
  }

  @include tablet {
    grid-column: 3/11;
  }

  @include mobile {
    grid-column: 1 / span 4;
  }
}

@mixin dialog-content {
  display: grid;

  :global(.MuiDialog-container) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    min-height: 100vh;

    @include desktop-or-tablet {
      column-gap: 24px;
      margin-left: 72px;
    }

    @include mobile {
      column-gap: 16px;
    }

    :global(.MuiPaper-root) {
      margin: 0;
      max-width: inherit;

      @include desktop {
        grid-column: 4 / span 6;
        margin: 0 16px;
      }

      @include tablet {
        grid-column: 3 / span 8;
      }

      @include mobile {
        grid-column: 1 / span 13;
        margin: 0 32px;
      }
    }
  }
}

@mixin text-field-margin {
  margin: $textfield-margin;

  @include mobile {
    margin: 16px 0;
  }
}

@mixin input-text-field-margin {
  margin: 24px 0 40px;

  @include mobile {
    margin: 24px 0 32px;
  }
}
