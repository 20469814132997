@import '~@gateway/components/src/.styles/global';

@mixin drawer-button() {
  border-bottom: 1px solid $light-gray;
  border-left: 1px solid $light-gray;
  height: 58px;
}

.country-text {
  margin-left: 8px;
}

.holiday-drawer {
  margin-top: $header-height;

  :global(.MuiDrawer-paper),
  :global(.MuiBackdrop-root) {
    top: $header-height;
  }

  :global(.MuiDrawer-paper) {
    height: calc(100% - #{$header-height});
    width: 322px;
  }

  :global(.MuiPaper-root) {
    width: 337px;
  }

  :global(.mdc-button--outlined) {
    margin: 22px auto;
    min-height: 32px;
    width: 274px;
  }
}

.spinner {
  margin-top: 70px;
  width: 100%;
}

.toolbar {
  background-color: $surface-dark;
  height: 58px;
  margin: 0;
  padding: 0;

  :global(.mdc-layout-grid__inner) {
    gap: 0;
  }

  &--button {
    border-left-color: $light-black;
    height: 100%;

    :global(.MuiButton-label) {
      color: $black;
      font-size: 20px;
    }
  }

  &--primary:global(.mdc-layout-grid__cell) {
    @include drawer-button();

    :global(.MuiButton-label) {
      justify-content: start;
    }

    :global(.MuiButtonBase-root) {
      padding-left: 16px;
    }
  }

  &--secondary:global(.mdc-layout-grid__cell) {
    @include drawer-button();
  }
}

.calendar-view {
  height: 116px;
}
