@import '~@gateway/components/src/.styles/global';

.success-dialog {
  @include mobile {
    height: 100%;
    width: 100%;
  }

  /* stylelint-disable declaration-no-important */
  z-index: 1500 !important;

  :global(.MuiDialog-paperFullScreen) {
    align-items: center;
    border-radius: 5px;
    display: grid;
    margin: 7px;
    max-height: 96%;
  }
}

.dialog-container {
  background: $white;
  height: auto;
  position: relative;
  width: 536px;

  @include mobile {
    height: 100%;
    width: 100%;
  }

  @include tablet {
    padding: 24px;
  }

  .dialog-close-btn {
    background-color: $white;
    color: $primary-400;
    cursor: pointer;
    height: 20px;
    margin: 23px 16px 0 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    z-index: 1;

    @include mobile {
      margin: 35px 13px 0 0;
    }
  }

  a {
    color: $primary-400;
    font-weight: 500;
    text-decoration: none;
  }
}

.page-layout {
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.container {
  width: 100%;

  .innerContainer {
    display: flex;
    flex-direction: column;
    margin-top: 73px;
    padding-left: 16px;
    padding-right: 16px;

    .warning-title-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin: 0;

      .dialog__warning-icon {
        color: $warning-dark;
        font-size: 40px;
        margin-right: 11px;
      }

      .title-cas {
        color: $info;
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
      }
    }

    .activation-step-container {
      padding-left: 5px;
    }

    .warning-subtitle {
      color: $info;
      font-size: 14.5px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 18px;
      margin-top: 29px;
    }

    .activation-step {
      color: $info;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
    }

    .step-one-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
    }

    .profile-icon {
      margin-left: 8px;
      width: 27px;
    }

    .slider-image-container {
      display: flex;
      justify-content: center;
      margin-top: 8px;

      .slider-image {
        width: 250px;
      }
    }
  }

  .bottom-container {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin-top: 140px;
    max-height: 96%;
  }

  .img {
    height: 60px;
    margin-bottom: 22px;
    width: 60px;
  }

  .cta:global(.mdc-button--outlined) {
    background: $primary;
    height: 30px;
    margin-bottom: 16px;
    margin-top: auto;
    width: 313px;

    span {
      color: white;
      font-weight: 500;
    }
  }
}
