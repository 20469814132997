@import '~@gateway/components/src/.styles/mixins';

.bank-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__address {
    margin-right: 15px;
  }
}
