@import '~@gateway/components/src/.styles/global';

.holiday-calendar {
  :global(.date-picker_day-selected) {
    background-color: $warning-deep-dark;
  }
}

.holiday-selected,
.holiday-selected:hover {
  background-color: $warning-deep-dark;
}

.holiday-table {
  border: none;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  :global(.rmwc-data-table__content) {
    width: 90%;
  }

  :global(.rmwc-data-table__row) {
    border-bottom: 1px solid $light-gray;

    &:last-of-type {
      border-bottom: none;
    }
  }
}

.holiday-wrapper {
  button {
    color: $white;
  }
}

.day-wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 2px 0;
  width: 40px;

  .current {
    border: 1px solid $primary;
    border-radius: 100%;
  }

  .day {
    border-radius: 100%;
    color: $info;
    font-size: 14px;
    font-weight: 500;
    height: 32px;
    padding: 0;
    width: 32px;

    :global(#circle) {
      color: $warning-deep-dark;
    }
  }

  &__hidden {
    opacity: 0;
    pointer-events: none;
  }

  .holiday {
    color: $warning-deep-dark;
  }

  .holiday-selected {
    &:hover {
      background-color: $warning-deep-dark;
      color: $white;
    }

    background-color: $warning-deep-dark;
    color: $white;
  }

  .selected {
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: $primary;
    }
  }
}
