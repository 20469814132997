@import '../.styles/global';

.filter-actions {
  display: inline-block;
  padding: 0;
  position: relative;
  top: -6px;
}

.filters-bar {
  align-items: center;
  display: flex;
  margin: 0 24px 12px;
  padding: 4px 0 0;

  @include mobile {
    margin: 0 14px 4px;
  }

  .filter {
    background-color: $light-gray;
    border-radius: 12px;
    color: $info;
    font-size: 12px;
    font-weight: 500;
    height: 24px;
    line-height: 24px;
    margin: 0 12px 12px 0;

    @include mobile {
      font-size: 10px;
    }
  }

  .aggregate-chip-container {
    background: $white;
    border: 1px solid $alto-gray;
    border-radius: 4px;
    box-shadow:
      0 4px 5px $light-box-shadow,
      0 1px 10px $light-box-shadow,
      0 2px 4px $light-box-shadow;
    box-sizing: border-box;
    margin-top: -12px;
    max-height: 345px;
    overflow-y: auto;
    position: absolute;
    z-index: 5;

    @include mobile {
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $light-gray;
        border-radius: 20px;
      }
    }

    &-box {
      left: 0;
      position: absolute;
    }
  }

  .aggregate-chip-container-v2 {
    position: relative;
  }

  .aggregate-chip-dropdown {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 18px 17px 8px 14px;
  }

  .aggregate-chip {
    background-color: $white;
    border: 1px solid $divider-gray;
    color: $info;
    font-size: 14px;
    font-weight: 400;
    height: 24px;
    line-height: 20px;
    margin-bottom: 8px;

    &:global(.MuiChip-root) {
      max-width: 100%;
    }
  }
}
