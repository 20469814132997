@import '~@gateway/components/src/.styles/global';

.masked-account {
  display: inline-block;

  .spinner {
    position: absolute;
    right: -25px;
    top: 2px;

    &--left-aligned {
      left: -25px;
    }
  }

  .no-border {
    border: none;
  }

  .content {
    display: inline;
    position: relative;
    white-space: nowrap;
  }

  .value {
    border-bottom: 1px dotted;
    cursor: pointer;
  }
}

.error-container {
  @include desktop-or-tablet {
    padding: 16px;
  }
}

.error-title-container {
  display: flex;
}

.error-icon {
  color: $white;
  text-align: center;
}

.error-title {
  padding-left: 8px;
}

.error-content {
  padding-left: 32px;
  padding-top: 4px;
}
