@import '~@gateway/components/src/.styles/global';

.final-rate-dialog {
  display: grid;

  :global(.MuiDialog-container) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    min-height: 100vh;

    @include desktop-or-tablet {
      column-gap: 24px;
      margin-left: 72px;
    }

    @include mobile {
      column-gap: 16px;
    }

    :global(.MuiPaper-root) {
      margin: 0 16px;
      max-width: inherit;

      @include desktop {
        grid-column: 4 / span 6;
      }

      @include tablet {
        grid-column: 2 / span 10;
      }

      @include mobile {
        grid-column: 1 / span 13;
        margin: 0 32px;
      }

      :global(.MuiDialogTitle-root) {
        padding: 24px 24px 10px;
      }

      span {
        display: flex;
        flex-direction: column;

        @include mobile {
          padding: 0;
        }
      }
    }
  }

  .message-container {
    /**
    * Disabled because overriding .MuiTypography styling in .message-container
    * does not violate specificity. We do not want to override .MuiTypography
    * for the entire dialog, only inside the .message-container as per UI spec.
    **/
    /* stylelint-disable no-descending-specificity */
    :global(.MuiTypography-body1) {
      font-size: 16px;
      font-weight: 400;
    }

    :global(.MuiTypography-body2) {
      font-weight: 400;
    }
    /* stylelint-enable no-descending-specificity */

    .expired-label {
      padding-bottom: 16px;
    }

    .rate-timer {
      display: flex;
      justify-content: space-between;
      padding-bottom: 24px;

      @include mobile {
        flex-direction: column;
      }

      .timer-container {
        display: flex;

        @include mobile {
          padding-top: 4px;
        }

        .timer-label {
          color: $darker-gray;
        }

        .timer-safe {
          color: $success;
        }

        .timer-warning {
          color: $error;
        }
      }
    }

    .amount-container {
      display: flex;

      @include mobile {
        flex-direction: column;
      }

      .amount-label {
        color: $surface-disabled-ME;
        padding-bottom: 4px;
      }

      .receive-amount {
        @include desktop-or-tablet {
          padding-right: 52px;
        }

        @include mobile {
          padding-bottom: 16px;
        }
      }
    }
  }
}
