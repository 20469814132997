@import '../.styles/global';

:global(.MuiOutlinedInput-notchedOutline p) {
  font-size: 14px;
  letter-spacing: 0;
  margin: 0;
  padding: 0;

  @media (max-width: 767px) {
    font-size: 12.5px;
  }
}

.calendar-input {
  :global(.MuiInputBase-root) {
    height: 48px;
    width: 100%;
  }

  :global(.MuiInputLabel-root),
  :global(.MuiInputBase-input) {
    font-size: 14px;
    font-weight: 400;
    line-height: 8px;
  }

  :global(.MuiInputBase-input) {
    font-size: 14px;
    font-weight: 400;
  }

  :global(.MuiInputBase-adornedEnd) {
    padding: 0;
    position: relative;
  }

  :global(.Mui-error) {
    .calender-icon {
      color: $error;
    }
  }

  :global(.Mui-focused:not(.Mui-error)) {
    .calender-icon {
      color: $primary;
    }
  }

  :global(.MuiInputBase-root.Mui-disabled) {
    color: $info;
    padding-right: 12px;
  }

  &--disabled {
    :global(.MuiIconButton-label .rmwc-icon) {
      color: $surface-disabled-ME;
    }

    :global(.MuiInputBase-root.Mui-disabled) {
      padding-right: 0;
    }
  }

  :global(.MuiInputBase-adornedEnd button.Mui-focusVisible) {
    background-color: rgba(0, 0, 0, .04);
  }

  :global(.MuiInputBase-root.date-warning .MuiOutlinedInput-notchedOutline) {
    border: 1px solid $warning-deep-dark;
    width: 100%;
  }

  &--disabled :global(.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled) {
    color: $surface-disabled-ME;
  }

  &--disabled :global(.MuiInputBase-root.Mui-disabled .MuiInputBase-input) {
    cursor: unset;
  }

  @media (min-width: 0) {
    :global(.MuiFormLabel-root.Mui-disabled) {
      color: $darker-gray;
    }

    &.input-focus {
      :global(.MuiInputLabel-root),
      :global(.MuiIconButton-label) {
        color: $primary;
      }

      :global(.MuiOutlinedInput-notchedOutline),
      :global(.MuiOutlinedInput-notchedOutline.Mui-disabled) {
        border-color: $primary;
        border-width: 2px;
      }

      :global(.MuiInputLabel-root.Mui-disabled) {
        color: $primary;
      }

      :global(.MuiOutlinedInput-root:hover > .MuiOutlinedInput-notchedOutline),
      :global(.MuiOutlinedInput-root.Mui-disabled > .MuiOutlinedInput-notchedOutline) {
        border-color: $primary;
        border-width: 2px;
      }
    }
  }
}

.day-wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 2px 0;
  width: 40px;

  .current {
    border: 1px solid $primary;
    border-radius: 100%;
    color: $white;
  }

  .day {
    border-radius: 100%;
    color: $info;
    font-size: 14px;
    font-weight: 500;
    height: 32px;
    padding: 0;
    width: 32px;

    &:hover {
      background-color: $hovered-item-color;
      color: $info;
    }
  }

  .day-disabled {
    color: $text-on-surface-disabled;
    pointer-events: none;
  }

  &__hidden {
    opacity: 0;
    pointer-events: none;
  }

  .day-selected {
    &:hover {
      background-color: $primary;
    }

    background-color: $primary;
    color: $white;
  }
}

.date-picker-wrapper {
  align-content: center;
  display: flex;
}

button.button-icon {
  padding-right: 12px;

  @include mobile-or-tablet {
    border-radius: 0;
    display: flex;
    justify-content: flex-end;
    left: 0;
    padding: 12px;
    position: absolute;
    right: 0;
    width: 100%;
  }
}
