@import '~@gateway/components/src/.styles/global';

.profile-settings-drawer {
  @include desktop-or-tablet {
    margin-top: $header-height;

    :global(.MuiDrawer-paper),
    :global(.MuiBackdrop-root) {
      top: $header-height;

      @include tablet-portrait {
        top: $header-mobile-height;
      }
    }

    :global(.MuiDrawer-paper) {
      @include tablet-portrait {
        height: calc(100% - #{$header-mobile-height});
      }

      height: calc(100% - #{$header-height});
      width: 322px;
    }
  }

  :global(.MuiPaper-root) {
    width: 322px;
  }

  &_pre-migrated-client {
    :global(.MuiDrawer-paper),
    :global(.MuiBackdrop-root) {
      top: 130px;

      @include mobile {
        height: calc(100% - 178px);
        overflow-y: auto;
        top: 178px;
      }

      @include tablet {
        top: 146px;
      }
    }
  }

  &__biometric {
    display: flex;
    height: 22px;

    &-label {
      color: $primary;
      font-weight: 400;
      margin: 0;
      white-space: nowrap;
    }

    &-switch {
      align-self: center;
      grid-area: switch;
      justify-self: end;
      margin-left: auto;
    }
  }

  &__scan-code {
    display: flex;
    height: 22px;

    &-label {
      color: $primary;
      font-weight: 400;
      margin: 0;
      white-space: nowrap;
    }

    &-icon {
      background-image: url('../../../../components/mfa-components/mfa-cronto-display/assets/mfa-cronto-icon.svg');
      background-position: 90% 50%;
      background-repeat: no-repeat;
      background-size: 55px 30px;
      width: 100%;
    }
  }

  &__container {
    margin: 0 0 16px 16px;

    &--with-border {
      border-top: 1px solid rgba($grey-border, .38);
      margin: 0 16px;
      padding: 23px 0;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &_logout-icon {
    color: $primary;
    margin-right: 10px;
  }

  &_logout-link {
    align-items: center;
    color: $primary;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 120px;

    &:hover {
      background-color: $transparent-90;
    }
  }

  &__indented {
    margin-left: 25px;
  }

  &__label {
    color: $primary;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;

    &:hover,
    &:active {
      color: $on-secondary;
    }
  }

  &__link {
    align-items: center;
    color: $primary;
    cursor: pointer;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
    width: fit-content;

    &-indicator {
      color: $error;
      position: absolute;

      &:global(.material-icons) {
        font-size: 9px;
      }
    }
  }

  .no-border {
    border: none;
  }

  &__Feedback-tooltip {
    width: 250px;

    :global(.MuiTooltip-arrow) {
      left: 55px;
    }
  }

  &__Feedback-assistance {
    &_title {
      &:global(.MuiTypography-body1) {
        margin-bottom: 10px;
      }
    }

    &_text {
      &:global(.MuiTypography-body2) {
        font-weight: 400;
      }
    }

    &_actions {
      display: flex;
      flex-direction: row-reverse;
      padding-top: 10px;

      @include mobile {
        flex-direction: column;
      }
    }

    &_logout-button {
      height: 30px;
      width: 60px;

      :global(.MuiTypography-button) {
        color: $white;
      }

      @include mobile {
        margin-top: 15px;
        width: 100%;
      }
    }

    &_survey-button {
      height: 30px;
      width: 100px;

      :global(.MuiTypography-button) {
        color: $white;
      }

      @include mobile {
        height: 35px;
        margin-top: 10px;
        width: 100%;
      }
    }
  }

  &__group-label {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__arrow {
    color: $primary;
    cursor: pointer;
    user-select: none;
  }

  &__user-info {
    border-bottom: 1px solid rgba($grey-border, .38);
    margin: 24px 16px 26px;
    padding-bottom: 16px;
  }
}
