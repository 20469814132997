@import '../../src/.styles/global';
$gradient: linear-gradient(315deg, $primary-950 0%, $primary-400 46.72%, $primary-650 100%);
$header_and_buttons_height: 210px;

.transition-modal {
  &-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0;
    width: 100%;

    & > div {
      width: 100%;

      @include mobile {
        bottom: 0;
        margin-bottom: -15px;
        position: absolute;
      }
    }
  }

  &-info {
    margin-top: 15px;
    overflow-wrap: break-word;
    white-space: pre-line;
  }

  &-content {
    background-color: $white;
    min-height: 110px;
    padding: 16px 20px;
    position: relative;
  }

  &-error {
    color: $error;
    /* stylelint-disable declaration-no-important */
    font-size: 40px !important;
    margin-right: 11px;
    vertical-align: middle;
  }

  &-header {
    color: $white;
    display: inline;
    font-family: Roboto, sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 28px;
    vertical-align: middle;
  }

  &-close-btn {
    cursor: pointer;
    height: 13px;
    padding-right: 20px;
    padding-top: 10px;
    position: absolute;
    right: 0;
    top: 0;
    width: 13px;
  }

  &-list {
    list-style: none;
    padding: 0;

    &-item {
      margin-top: 15px;

      a {
        color: $white;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        letter-spacing: .25px;
        text-decoration: none;
      }
    }
  }

  &-buttons {
    display: flex;
    justify-content: flex-end;

    .default-button {
      margin: 10px 0 10px 10px;
      width: auto;
    }
  }

  &.transition-modal--small {
    .transition-modal-info {
      &-message {
        font-size: 12px;
      }
    }
  }

  &.transition-modal--no-close-btn {
    .transition-modal-close-btn {
      display: none;
    }

    .default-button {
      width: 150px;
    }
  }

  &.transition-modal--scrollable {
    .transition-modal-wrapper {
      & > div {
        @include mobile {
          margin-bottom: 0;
          top: -15px;
        }
      }
    }

    .transition-modal-content {
      position: static;

      @include mobile {
        height: 100vh;
      }

      @include tablet-landscape {
        height: calc(100vh - 64px);
        max-height: 700px;
      }

      .transition-modal-info {
        height: calc(90vh - #{$header_and_buttons_height});
        max-height: 500px;
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll;

        @include mobile {
          height: calc(100vh - #{$header_and_buttons_height});
          max-height: unset;
        }
      }
    }
  }

  &.transition-modal--bright {
    .transition-modal {
      &-wrapper {
        align-items: unset;

        & > div {
          display: block;
          position: relative;
          top: 64px;
          width: 500px;

          @include mobile {
            display: grid;
            top: -15px;
          }
        }
      }

      &-content {
        background-color: $white;
        border: none;
        color: $black;
        padding-bottom: 0;

        .transition-modal-buttons {
          justify-content: center;
          margin: 0;
          padding: 15px 0;

          .default-button {
            margin: 10px;
            max-width: 50%;
            min-width: 100px;

            &--small {
              width: 105px;
            }
          }
        }
      }

      &-header {
        align-items: center;
        background-color: $primary-950;
        display: flex;
        font-size: 16px;
        font-weight: 500;
        height: 40px;
        justify-content: center;
        letter-spacing: .2px;
        margin: -16px -20px 20px;
      }

      &-close-btn {
        display: none;
      }
    }

    &.transition-modal--scrollable {
      .transition-modal-wrapper > .mdc-layout-grid__inner {
        @include mobile {
          width: 100%;
        }
      }
    }
  }

  /* stylelint-disable */
  &.transition-modal--no-header {
    .transition-modal {
      &-wrapper {
        margin-top: 30px;
      }

      &-content {
        .transition-modal-buttons {
          .default-button {
            width: 50%;
          }
        }
      }

      &-header {
        background: $gradient;
        height: 14px;
      }

      &-info {
        font-size: 16px;
        text-align: center;
      }
    }
  }
  /* stylelint-enable */

  &--hidden {
    display: none;
  }
}
