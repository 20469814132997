@import '~@gateway/components/src/.styles/global';

.manage-trusted-devices {
  padding: 0 16px;

  &-title-container {
    padding-bottom: 16px;
  }

  &-other-devices-container {
    padding-top: 16px;
  }

  &-empty-list-title-1 {
    padding-top: 8px;
  }

  &-empty-list-title-2 {
    padding-top: 24px;
  }

  &-empty-list-body {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 12px;
  }

  &-empty-list-body-list {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 44.5px;
    padding: 0 0 0 21px;
  }

  &-empty-list-profile-settings {
    color: $primary;
    font-weight: 500;
  }

  &-empty-list-image {
    background-image: url('~@gateway/components/src/.assets/images/mfa-profile-and-settings.png');
    background-position: 10% 62%;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #ef6c00;
    border-radius: 8px;
    box-sizing: border-box;
    height: 53.51px;
    left: 26.1px;
    margin: 15px 0;
    overflow: hidden;
    top: 0;
    width: 238px;
  }

  &-empty-list-divider {
    background: $surface-dark;
    display: block;
    height: 8px;
    margin: 0 0 40px -16px;
    width: 322px;
  }

  &-empty-list-sub-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  &-empty-list-no-trusted-devices {
    color: $light-black;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  &-empty-list-icon {
    background-image: url('~@gateway/components/src/.assets/images/mfa-empty-state-icon.svg');
    background-repeat: no-repeat;
    background-size: 50px 50px;
    display: inline-block;
    height: 50px;
    margin-bottom: 10px;
    width: 50px;
  }
}
