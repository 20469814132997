@import '~@gateway/components/src/.styles/global';

@mixin table-cell-style-overide {
  &:global(.MuiTableRow-root) {
    background-color: transparent;
  }
}

@mixin detail-value {
  color: $info;
  font-weight: 400;
  white-space: break-word;
}

@mixin overflow-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cutoff-send-date {
  color: $warning-dark;
}

.payment-details {
  padding: 0 16px 16px;
  text-align: left;

  &__children {
    color: $dark-font-color;
    padding: 4px 7px 10px;

    :global(.MuiTypography-body1) {
      font-weight: 400;
    }
  }

  &__header {
    color: $light-black;
    padding: 0 7px;

    :global(.MuiTypography-body2) {
      font-weight: 500;
    }
  }

  .secondary-text {
    color: $darker-gray;
    font-weight: 400;
    padding-top: 2px;
  }
}

.recurring-transaction-container {
  align-items: center;
  display: flex;

  :global(.MuiTypography-body2) {
    font-weight: 400;
  }
}

.font-medium {
  font-weight: 400;

  :global(.MuiTypography-body2) {
    font-weight: 400;
  }
}

.list-root {
  border: none;
  width: 100%;

  &:global(.MuiTableContainer-root) {
    overflow-x: unset;
  }

  :global(.mdc-checkbox__background) {
    border: 2px solid $light-gray;
  }

  .transaction-amount {
    display: block;
  }

  .content {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    @include mobile {
      overflow: hidden;
      white-space: normal;
    }

    @include tablet {
      white-space: normal;
    }

    :global(.MuiTableRow-root) {
      background-color: transparent;
      border-bottom: 1px solid $divider-black;

      :global(.MuiTableCell-root) {
        border: 0;
        box-sizing: border-box;
      }
    }

    .currency-key-row {
      background: $surface-dark;
      height: 56px;
      padding-top: 16px;

      .total_amount {
        &:global(.MuiTableCell-root) {
          border-bottom: none;
          padding: 8px;
        }
      }
    }

    :global(.MuiTableRow-root):nth-of-type(even) {
      background-color: transparent;
    }

    tr.recurring-cell {
      background-color: transparent;
    }

    tr.row--recurring {
      border-bottom: none;
    }

    .bold {
      font-weight: 500;
    }

    .font-medium {
      font-weight: 400;

      :global(.MuiTypography-body2) {
        font-weight: 400;
      }
    }

    .info-row {
      border-bottom: 1px solid $light-gray;
      cursor: pointer;

      @include table-cell-style-overide;

      &:hover {
        background-color: $hovered-bg-item-color;
      }

      .icon {
        font-size: 12px;
        margin-right: 8px;

        @include tablet {
          font-size: 10px;
        }
      }

      &__cell {
        background: transparent;
        overflow: hidden;
        padding: 8px 24px 8px 8px;
        vertical-align: top;
        white-space: nowrap;

        &--tax {
          padding-right: 0;
        }

        &.vertical-align-middle {
          vertical-align: middle;
        }

        &.checkbox-row {
          width: 32px;
        }

        &.multi-icon-header-cell {
          cursor: pointer;
        }

        @include tablet {
          padding: 8px;
          width: 144px;
        }

        &:last-of-type {
          white-space: nowrap;
        }

        .checkbox {
          left: 15px;
          position: absolute;
          text-align: center;

          @include tablet {
            left: 0;
          }
        }

        .cutoff-send-date {
          color: $warning-dark;
        }

        .date {
          height: 36px;
          position: relative;
          text-align: center;

          .cutoff-send-date {
            color: $warning-dark;
          }

          &.date-wo-checkbox {
            margin-left: 10px;
            text-align: left;
          }

          :global(.MuiTypography-body2) {
            font-weight: 400;
          }
        }

        .primary-text {
          align-items: center;
          display: flex;
          height: 20px;
        }

        .secondary-text {
          color: $darker-gray;
          font-weight: 400;
        }

        &.amount-row {
          padding: 8px 8px 8px 24px;

          @include tablet {
            padding: 8px;
            width: 168px;
          }
        }

        .send-date-row {
          font-weight: 400;
        }
      }

      &__cell-multiple {
        padding: 8px;

        &.checkbox-row {
          width: 32px;
        }

        @include tablet {
          width: 133px;
        }

        &.transaction-date-row {
          @include tablet {
            width: 142px;
          }
        }

        &.amount-row {
          padding: 8px;

          @include tablet {
            width: 160px;
          }
        }
      }
    }

    @include tablet {
      .account-number {
        margin: 0 4px;
      }

      .fiber-icon {
        margin: 0 3px 4px 0;
      }
    }

    .transaction-id__row {
      padding-left: 0;

      @include table-cell-style-overide;

      &__cell {
        background-color: $hovered-bg-item-color;
        padding-left: 30px;
        padding-right: 0;

        @include tablet {
          padding: 16px 0 16px 12px;
          vertical-align: top;

          &:first-child {
            padding-left: 72px;
          }
        }

        .pay-from {
          display: flex;
          white-space: nowrap;
        }

        .pay-from-value {
          align-self: center;
          display: flex;
          white-space: nowrap;

          .account-name {
            margin: 0 2px;
            max-width: 90px;

            @include detail-value;
          }
        }

        .transaction-id {
          margin-left: 42px;
          padding-top: 8px;

          @include tablet {
            margin-left: 0;
            padding: 0;
          }

          &__value {
            display: inline-block;
            margin-bottom: -6px;
            max-width: 96px;

            @include tablet {
              max-width: 58%;
            }

            @include detail-value;
          }
        }
      }
    }

    .header {
      border-bottom: 1px solid $surface-disabled-ME;
      cursor: pointer;
      width: 100%;

      .header-row {
        border-bottom: 1px solid $light-black;

        &-checkbox.selected {
          visibility: visible;
        }

        &__cell {
          border: none;
          color: $primary;
          height: 38px;
          padding: 8px 16px;
          position: relative;
          vertical-align: middle;

          @include tablet {
            padding: 8px 12px;
          }

          &:first-child {
            padding-left: 8px;
            vertical-align: middle;
          }
        }

        &__disabled {
          color: $darker-gray;
          cursor: default;
        }

        :global(.MuiTypography-body1) {
          color: $info;
          font-weight: 400;
        }

        .header-cell-wrapper {
          cursor: default;
          padding: 8px 24px 8px 8px;

          :global(.MuiTypography-body2) {
            font-weight: 500;
          }

          .header-row__cell__content {
            :global(.MuiTypography-body2) {
              font-weight: 400;
            }
          }

          .header-date {
            position: relative;
            text-align: center;

            &--no-checkbox {
              text-align: left;
            }

            .checkbox {
              left: 15px;
              position: absolute;
              top: -12px;

              @include tablet {
                left: 0;
              }
            }
          }

          .sort-icon {
            color: $primary;
            font-size: 16px;
            left: -16px;
            position: absolute;

            &--down {
              transform: rotate(90deg);
            }

            &--up {
              transform: rotate(270deg);
            }
          }

          .hidden-icon {
            visibility: hidden;
          }

          .header-row__cell__content:hover {
            color: $on-secondary;

            .sort-icon {
              color: $on-secondary;
            }

            .hidden-icon {
              color: $on-secondary;
              visibility: visible;

              &:not(.numeric-field-icon) {
                transform: rotate(270deg);
              }

              &.numeric-field-icon {
                transform: rotate(90deg);
              }
            }
          }

          @include tablet {
            padding: 8px;
            width: 144px;
          }
        }

        .checkbox-header {
          width: 32px;
        }

        .multi-icon-header-cell {
          cursor: pointer;
        }

        .empty-header,
        .multi-icon-header-cell {
          width: 28px;
        }

        .column-header-amount {
          padding: 8px 8px 8px 24px;

          @include tablet {
            width: 168px;
          }
        }

        .header-cell-wrapper-multiple {
          padding: 8px;

          &.checkbox-header {
            width: 32px;
          }

          &.empty-header,
          &.multi-icon-header-cell {
            width: 28px;
          }

          @include tablet {
            width: 133px;
          }

          &.transaction-date-col {
            @include tablet {
              width: 142px;
            }
          }

          &.column-header-amount {
            padding: 8px 8px 8px 24px;

            @include tablet {
              width: 160px;
            }
          }
        }
      }
    }
  }
}

.recurring-trx-text {
  color: $light-black;
}

.recurring-trx-sub-text {
  color: $dark-font-color;
}

.recurring-icon {
  color: $green;
  font-size: 16px;
  line-height: 16px;
  margin-right: 5px;
  vertical-align: middle;
}

.recurring-transaction {
  padding-left: 22px;
}

.expand-icon {
  color: $primary;
  transform: rotate(0deg);

  &_up {
    transform: rotate(90deg);
  }
}

.info-payment-group-container {
  background: $alto-gray;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 8px 8px 8px 22px;

  .group-content {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .title-container {
      display: flex;
    }
  }
}

.payee-name {
  display: inline-block;
  margin-bottom: -5px;
  max-width: 50%;

  &.payee-name-checkbox {
    max-width: 40%;
  }

  &.split-name {
    max-width: 75%;
  }

  @include overflow-value();

  @include desktop {
    margin-bottom: -5px;
  }
}

.split-badge {
  background-color: $white;
  border: 1px solid $black;
  border-radius: 100%;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  margin-left: 8px;
  min-width: 20px;

  @include mobile {
    font-size: 12px;
    margin-left: 4px;
    min-width: 16px;
  }
}

.ellipsis-text {
  @include overflow-value();
}

.pay-to-content {
  span {
    max-width: 50%;
  }

  &.pay-to-content-checkbox {
    span {
      max-width: 40%;
    }
  }

  &.pay-to-content--tax {
    span {
      max-width: 100%;
    }
  }
}

.space-between-text {
  padding-top: 4px;
}
