@import '~@gateway/components/src/.styles/global';

.dialog-container:global(.MuiDialog-container) {
  background-color: $white;
}

.dialog-icon:global(.rmwc-icon) {
  font-size: 34px;
}

.dialog-root:global(.MuiPaper-root) {
  box-shadow: none;
  height: 100%;
  margin: 0;
  max-height: unset;
  position: absolute;
  top: 0;
  width: 100%;
}

.dialog-title:global(.MuiDialogTitle-root) {
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}

.mobile-container {
  background-color: $white;
  width: 100%;
}
