@import '../.styles/global';

.status-indicator {
  background-color: $light-gray;
  border-radius: 50%;
  display: block;
  font-size: 10px;
  height: 10px;
  margin-right: 4px;
  overflow: hidden;
  width: 10px;

  &--is-valid {
    background-color: $success-medium;
  }

  &--is-invalid {
    background-color: $error;
  }

  &__checkmark {
    color: $white;
    position: relative;
    right: -2px;
    top: -1px;
  }
}
