@import '~@gateway/components/src/.styles/global';

.error-popup {
  clear: both;
  padding: 20px;
  z-index: $z-index-slider;

  .dialog-container {
    margin: 0;
    padding: 20px;
  }

  .dialog-title {
    align-items: center;
    display: flex;
  }

  .icon-error {
    color: $error;
    float: left;
    font-size: 40px;
    padding-bottom: 10px;
    padding-right: 8px;
  }

  .icon-warning {
    color: $warning-deep-dark;
    float: left;
    font-size: 40px;
    padding-bottom: 10px;
    padding-right: 8px;
  }

  .error-popup-title {
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 16px;
    padding-right: 10px;
  }

  .error-popup-body {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;

    .cancel-button {
      width: fit-content;
    }

    .support-button {
      margin-left: 10px;
      padding: 0 14px;
      width: auto;
    }

    @include tablet-portrait {
      width: 510px;
    }
  }

  .modal-close-btn {
    cursor: pointer;
    margin-bottom: 30px;
    margin-right: 10px;
    position: absolute;
    right: 0;
    z-index: 1;
  }
}
