@import '../.styles/global';

.dialog {
  @include mobile {
    width: 280px;
  }

  .close-button {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .actions {
    flex-wrap: wrap-reverse;
    padding-top: 0;

    :global(.mdc-button) {
      padding: 0 8px;
      width: auto;
    }

    &__button {
      margin-top: 8px;

      &:hover {
        color: $on-secondary;
      }

      &--filled:hover {
        background-color: $on-secondary;
        color: $white;
      }
    }

    &--spinner {
      height: 36px;
      margin-right: 24px;
    }
  }

  .content {
    &:global(.MuiDialogContent-root) {
      margin-bottom: 28px;
      padding-bottom: 0;
      padding-top: 0;
    }

    &__message {
      color: $info;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      white-space: pre-line;
    }
  }
}

.title {
  align-items: center;
  color: $info;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;

  @include mobile {
    align-items: center;
    flex-direction: column;
  }

  &:global(.MuiDialogTitle-root) {
    @include mobile {
      padding-bottom: 12px;
      padding-top: 28px;
    }
  }

  &__icon {
    color: $primary;

    &:global(.material-icons) {
      font-size: 40px;
      margin-right: 8px;

      @include mobile {
        margin-bottom: 8px;
      }
    }

    &--warning {
      color: $warning-dark;
    }

    &--error {
      color: $error;
    }
  }
}
