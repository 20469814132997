.expansion-panel-summary {
  &__root {
    &:global(.MuiExpansionPanelSummary-root),
    &:global(.MuiExpansionPanelSummary-root.Mui-expanded) {
      min-height: 24px;
      padding: 12px 24px 12px 8px;
    }
  }

  &__content {
    &:global(.MuiExpansionPanelSummary-content),
    &:global(.MuiExpansionPanelSummary-content.Mui-expanded) {
      margin: 0;
    }
  }

  &__expand-icon {
    &:global(.MuiIconButton-root) {
      padding: 0;
    }
  }
}
