@import '~@gateway/components/src/.styles/global';

.bottom-navigation {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  &-wrapper {
    background-color: $primary;
    bottom: 0;
    display: grid;
    grid-template-columns: 3fr 1fr;
    max-height: 0;
    position: fixed;
    transition: max-height .5s;
    width: 100%;
    z-index: 101;

    @include tablet {
      display: none;
    }

    &--visible {
      max-height: $bottom-navigation-height;
      padding-bottom: $bottom-navigation-padding-bottom;
      transition: max-height .5s;
    }

    &--visible--submenu-enabled {
      height: $bottom-navigation-height-submenu-enabled;
      max-height: $bottom-navigation-height-submenu-enabled;
      padding-bottom: $bottom-navigation-padding-bottom-submenu-enabled;
      transition: none;
    }
  }

  &-burger {
    align-items: center;
    border-left: 1px solid $white;
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    &--submenu-enabled {
      border-left: none;

      &-active-item::after {
        border-bottom: 4px solid $white;
        border-radius: 2px;
        content: '';
        display: block;
        margin: 7px auto 0;
        width: 80%;
      }
    }
  }

  &-menu-label {
    color: $white;
    margin: 0;
  }

  &-close-icon,
  &-menu-icon {
    margin: 6px auto 8px;
  }
}
