@import '~@gateway/components/src/.styles/global';

.change-password-tab {
  height: 100%;
  left: unset;
  margin-top: 56px;
  overflow-y: auto;
  position: fixed;
  right: 0;
  width: 322px;
  z-index: 1400;

  &-content {
    height: calc(100% - 150px);
    overflow-y: scroll;
    padding: 16px;
  }

  :global(.slide-wrapper__header) {
    height: 14px;
  }
}
