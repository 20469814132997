@import '~@gateway/components/src/.styles/global';

.devices-detail {
  &-other-devices-container {
    margin-top: 16px;
  }

  &-device-container {
    box-shadow: 0 4px 5px rgba(0, 0, 0, .05), 0 1px 10px rgba(0, 0, 0, .05), 0 2px 4px rgba(0, 0, 0, .05);
    margin-bottom: 4px;
    padding: 12px 8px;
  }

  &-device-detail-container {
    background-color: $lighter-blue;
    padding: 22px 8px;
  }

  &-device-list-1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  &-device-list-2 {
    display: flex;
    justify-content: space-between;
    margin-right: 5px;
  }

  &-label-text {
    color: $light-black;
  }

  &-criteria-elem {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;

    &__text {
      color: $success-darker;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      padding-left: 4px;

      &--deleted {
        color: $placeholder-gray;
      }
    }
  }

  &-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    &:global(.mdc-button) {
      background-color: green;
    }
  }
}
