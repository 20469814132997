@import '../../.styles/global';

.circle {
  bottom: -3px;
  position: absolute;
}

.legend {
  align-items: center;
  color: $darker-gray;
  display: flex;
  left: 16px;
  position: absolute;
  top: 42px;
}

.medium-month {
  padding-top: 40px;
}

.small-month {
  padding-top: 80px;
}
