@import '~@gateway/components/src/.styles/global';

.secondary-navigation-items {
  &__close-btn-container {
    align-items: start;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: end;

    @include mobile {
      display: none;
    }
  }

  &__main-title {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    padding: 16px;
    width: 128px;

    :global(.MuiSvgIcon-root) {
      height: 16px;
      width: auto;
    }

    @include mobile {
      font-size: 20px;
      padding: 0;
      width: auto;

      :global(.MuiSvgIcon-root) {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  &__content {
    color: $black;
    cursor: pointer;
    display: block;
    font-size: 12px;
    font-weight: 500;
    padding: 12px 16px;
    text-align: left;

    &:hover {
      background-color: $surface-dark;
    }

    :global(.MuiSvgIcon-root) {
      height: 16px;
      width: 16px;
    }

    @include mobile {
      font-size: 14px;
    }
  }

  &__close-btn:global(.MuiButton-root) {
    &:global(.MuiButton-root) {
      min-width: 0;
      padding: 0;
    }

    &:global(.MuiButton-text) {
      padding: 11.33px;
    }

    :global(.MuiSvgIcon-root) {
      height: 16px;
      width: 16px;
    }

    &:hover {
      background-color: transparent;
    }
  }

  &__sub-title {
    font-size: 12px;
    font-weight: 400;
    padding-top: 8px;
  }

  &__external-icon-wrapper {
    align-items: center;
    display: flex;
    margin-right: 10px;
  }

  &__category-title {
    color: $light-black;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    padding: 16px 16px 8px;

    @include mobile {
      font-size: 12px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;

    @include mobile {
      height: 56px;
    }
  }

  &__menu-divider {
    background-color: $alto-gray;
    border: 0;
    border-top: 1px solid $alto-gray;
    display: block;
    height: 0;
    margin: 4px 0;
  }

  &--isFirstSubmenuItem {
    @include desktop-or-tablet {
      display: none;
    }
  }

  &-menuItem {
    display: flex;
    flex-direction: column;
    height: 24px;
    justify-content: center;
  }

  &__external-link {
    @include mobile {
      display: none;
    }
  }

  &__item-content {
    align-items: center;
    display: flex;
    gap: 8px;
    margin: 0;

    &--external-link {
      justify-content: space-between;
    }
  }

  &__badge {
    align-items: center;
    background-color: $primary;
    border-radius: 100px;
    display: flex;
    height: 16px;
    justify-content: center;
    padding: 1px 6px;
  }

  &__badge-text {
    color: $white;
    font-size: 10px;

    @include mobile {
      font-size: 10px;
    }

    @media screen and (min-width: $nav-sidebar-lg-breakpoint) {
      margin: 0 1px 0 0;
    }
  }

  &__back-arrow-btn:global(.MuiButton-root) {
    &:hover {
      background-color: transparent;
    }

    @include desktop-or-tablet {
      display: none;
    }

    @include mobile {
      margin: 12px 8px 12px 10px;
      min-width: 0;
      padding: 0;
    }
  }

  &--has-width-auto {
    width: auto;
  }
}

.hasBottomBorder {
  border-bottom: 1px solid $alto-gray;
}

.hasBottomBorder:last-child {
  border-bottom: none;
}
