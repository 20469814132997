@import '~@gateway/components/src/.styles/global';

.logo {
  display: flex;
  flex-direction: row;
  margin-top: 45px;
  user-select: none;
  width: 263px;

  @include tablet-portrait-or-mobile {
    height: 48px;
    width: auto;
  }

  &-wrapper {
    align-items: center;
    cursor: pointer;
    display: flex;
  }

  &-logo-wrapper {
    display: flex;
    flex-direction: column;

    &__fullWidth {
      align-items: start;
      width: 100%;
    }

    &__relatively-positioned {
      position: relative;
    }
  }

  &-fdic-logo {
    display: none;
  }

  &-fdic-logo-mobile {
    align-items: center;
    border-bottom: 1px solid $shadow-light-grey;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 2px 0 12px;
    position: absolute;
    width: 100%;

    @include tablet-portrait {
      justify-content: flex-start;

      img {
        max-width: $login-form-width;
      }
    }

    &-image {
      max-width: 100%;
    }
  }
}

@include desktop-or-tablet-landscape {
  .logo {
    margin-top: 0;

    &-logo-wrapper {
      align-items: center;
      flex-direction: row;
    }

    &-fdic-logo {
      border-left: 1px solid $shadow-light-grey;
      display: block;
      margin-left: 12px;
      padding-left: 12px;
      width: 425px;
    }

    &-fdic-logo-mobile {
      display: none;
    }
  }
}
