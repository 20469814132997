@import '../.styles/global.scss';

.anchor {
  color: $anchor-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
