@import '~@gateway/components/src/.styles/global';

.generating-item-status-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 8px 0;

  &__message {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &__button--borderless {
    border: 0;
    margin-left: 10px;
    margin-right: 2px;
  }

  &__link {
    color: $primary;
    font-weight: 500;
    text-decoration: none;
  }
}
