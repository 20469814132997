@import '~@gateway/components/src/.styles/global';

:global(.rmwc-data-table) {
  overflow: unset;
}

.collapsable-table {
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;

  &:global(.MuiTableContainer-root) {
    overflow: unset;
    position: sticky;
  }

  :global(.MuiTableSortLabel-root) {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  tr:global(.MuiTableRow-root) {
    &:nth-child(n):hover {
      background-color: rgba($on-secondary, .05);
    }
  }

  &__header--cell {
    color: $primary;

    &:last-child {
      svg {
        display: none;
      }
    }

    &:global(.MuiTableCell-stickyHeader) {
      border-bottom: 1px solid $shadow-gray;
      position: sticky;
      // hero area height + action bar height
      top: 320px;
      z-index: 6;
    }

    &__text,
    :hover {
      color: $primary;
      white-space: nowrap;
    }

    &__text:global(.MuiTypography-root) {
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__header--row {
    height: 32px;

    #collapse-table-head {
      // hero area height + submit btn bar height + action bar height
      padding-left: 24px;
      top: 108px;
    }
  }

  :global(.MuiTableCell-root) {
    padding-left: 24px;
  }

  :global(.MuiTableCell-root:first-child),
  :global(.MuiTableCell-root:last-child) {
    width: 43px;
  }

  :global(.MuiTableCell-root:last-child) {
    padding: 16px 0 16px 12px;
  }

  :global(.MuiTableCell-root:first-child) {
    padding-left: 24px;
    text-align: start;
  }

  &__content {
    border: none;
    table-layout: fixed;
    width: 100%;
  }

  &__data--cell {
    cursor: pointer;

    &:not(:last-child),
    &:not(:first-child) {
      vertical-align: top;
    }

    &:first-child,
    &:last-child {
      vertical-align: middle;
    }
  }

  &__row {
    &:global(.MuiTableRow-root:nth-of-type(n)) {
      background-color: $white;
    }
  }

  &__row--expanded {
    &:global(.MuiTableRow-root:nth-of-type(n)) {
      background-color: var(--primary-primary-100, $lighter-blue);
    }
  }

  .value {
    font-size: 14px;
    font-weight: 400 !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .outer-span {
    display: inline-block;
    white-space: nowrap;
    width: 300px;
  }

  .account-name {
    display: inline-block;
    margin: 0;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
  }

  .value-right-align-inline {
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    padding-right: 78px;
    position: relative;
    text-align: right;
    text-overflow: ellipsis;
  }

  .value-right-align-deal {
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    padding-right: 78px;
    position: relative;
    text-align: right;
  }

  .icon-button--cell {
    color: $primary;
  }

  &__cell {
    border-top: none;
    margin-left: 40px;
    vertical-align: top !important;

    &--last {
      &:global(.MuiTableCell-root) {
        padding-left: 136px;
        vertical-align: top;
      }
    }
  }

  &__second-cell {
    border-top: none;
    margin-left: 40px;
    padding-right: 170px !important;
    vertical-align: top !important;
  }

  &__cell-margin {
    margin-bottom: 12px;
    white-space: break-spaces;
  }

  .cell-data {
    color: $black;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 8px;
    white-space: break-spaces;
    width: 293px;
  }

  .cell {
    white-space: break-spaces;
  }

  .details-widget__header {
    color: $light-black;
    margin-bottom: 4px;
  }
}

.payment-instruction-table__sequence-number:global(.MuiTypography-root) {
  padding-right: 24px;
}

.amt-cell {
  padding-left: 100px;
}

.payee-details {
  &:global(.MuiTypography-body1) {
    font-size: 14px;
  }
}
