@import '../../.styles/global';

.date-interval {
  &__error {
    color: $info;
    display: flex;
    justify-content: center;
    padding: 8px;
    width: 100%;

    &:global(.MuiTypography-root) {
      margin-top: 20px;
    }
  }

  &__title.date-interval__title {
    color: $info;
    font-weight: 400;
    padding-bottom: 18px;
  }
}
