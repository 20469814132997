// This material library import is commented-out intentionally:
// @import '~@material/theme/dist/mdc.theme.css';
//
// There are a lot of !important definitions like this which impede component
// custom styling (when it is neccessary):
// .mdc-theme--error {
//   color: #b00020 !important;
//   /* @alternate */
//   color: #b00020 !important;
//   color: var(--mdc-theme-error, #b00020) !important;
// }
@import './theme-colors.scss';

:root {
  --mdc-theme-primary: #{$mdc-theme-primary};
  --mdc-theme-secondary: #{$mdc-theme-secondary};
  --mdc-theme-background: #{$mdc-theme-background};
  --mdc-theme-surface: #{$mdc-theme-surface};
  --mdc-theme-error: #{$mdc-theme-error};
  --mdc-theme-on-primary: #{$mdc-theme-on-primary};
  --mdc-theme-on-secondary: #{$mdc-theme-on-secondary};
  --mdc-theme-on-surface: #{$mdc-theme-on-surface};
  --mdc-theme-on-error: #{$mdc-theme-on-error};
  --mdc-theme-text-primary-on-background: rgba(0, 0, 0, .87);
  --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, .54);
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, .38);
  --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, .38);
  --mdc-theme-text-icon-on-background: rgba(0, 0, 0, .38);
  --mdc-theme-text-primary-on-light: rgba(0, 0, 0, .87);
  --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, .54);
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, .38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, .38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, .38);
  --mdc-theme-text-primary-on-dark: white;
  --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, .7);
  --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, .5);
  --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, .5);
  --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, .5);
}
