@import '../.styles/_mixins';

.paper {
  :global(.MuiPaper-root) {
    width: 617px;

    @include tablet {
      width: 472px;
    }

    @include mobile {
      width: 100vw;
    }
  }

  :global(.MuiDrawer-paper) {
    -webkit-overflow-scrolling: auto;
  }
}
