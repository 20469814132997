@import '~@gateway/components/src/.styles/global';

.payment-template {
  background-color: white;

  .input-field {
    margin: $textfield-margin;

    :global(.mdc-text-field__icon:first-child) {
      color: $primary;
    }

    .icon-show {
      color: $primary;
      font-size: 16px;
    }

    .icon-hide {
      font-size: 16px;
      visibility: hidden;
    }

    @include mobile {
      display: flex;
      margin: 24px;
    }

    @include tablet {
      display: flex;
      margin-left: 26px;
      margin-right: 24px;
    }

    @include desktop {
      width: 450px;
    }
  }

  @include desktop-or-tablet {
    .save-template-switch {
      display: inline-block;
      margin: 28px 5px 5px 24px;
    }

    :global(.textfield-container) {
      padding: 5px 27px 16px;
    }
  }

  @include mobile {
    margin: 0 -16px 24px;

    .save-template-switch {
      display: inline-block;
      margin: 24px 5px 5px 24px;
    }

    :global(.textfield-container) {
      padding: 5px 22px 16px;
    }
  }

  .header-text {
    margin: 24px 0 0 24px;
  }

  .divider {
    border-color: $alto-gray;
    margin: 16px 16px 16px 0;
  }

  .payment-template-card {
    overflow: hidden;
    padding-bottom: 24px;
  }

  .payment-category {
    margin: 24px;

    @include desktop {
      display: flex;
    }

    &--auto-select {
      margin: $textfield-margin;

      @include desktop {
        margin: 0;
        width: 450px;
      }
    }
  }
}
