@import '~@gateway/components/src/.styles/global.scss';

.unmasked-account .field {
  border-bottom: 1px solid $white-light-border;
  display: flex;
  flex-direction: column;
  min-width: 276px;
  padding: 8px 0 12px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .name,
  .number {
    align-items: center;
    display: flex;

    @include mobile {
      margin-right: 24px;
    }
  }

  &:first-child {
    padding-top: 0;
  }

  .flex {
    flex: 1;
    margin-right: 8px;
    max-width: 276px;

    @include mobile {
      max-width: 375px;
      overflow-wrap: break-word;
      width: 100%;
    }
  }

  .copy-icon {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    vertical-align: middle;
  }

  :global(.MuiTypography-body2) {
    @include mobile {
      font-weight: 500;
    }
  }
}
