@import '~@gateway/components/src/.styles/global';

.container {
  :global(.transition-modal-content) {
    max-width: 800px;
    width: 45rem;

    @include mobile {
      width: 15rem;
    }

    @include tablet {
      width: 30rem;
    }
  }
}

.external-link {
  color: $primary;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.message {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
