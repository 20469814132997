.close-icon {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  margin: 0;
  padding: 0;
  user-select: none;
  width: auto;
}
