@import '~@gateway/components/src/.styles/global';

.trusted-devices {
  height: calc(100% - 70px);
  left: unset;
  margin-top: 56px;
  overflow-y: auto;
  position: fixed;
  right: 0;
  width: 322px;
  z-index: $z-index-slider;

  :global(.slide-wrapper__header) {
    height: 14px;
  }

  &-content {
    height: 100%;
    padding: 16px 0;
  }

  &-info-container {
    padding: 8px 16px;
  }

  &-info {
    color: $light-black;
  }

  &__container {
    margin: 0 0 16px 16px;

    &--with-border {
      border-bottom: 1px solid rgba($grey-border, .38);
      margin: 0 16px;
      padding: 15px 8px;
    }
  }

  &-spinner {
    align-items: flex-start;
    display: flex;
    height: 100%;
    justify-content: center;
    margin-top: 30px;
  }
}

.dialog {
  /* needed to render the warning dialog on top of slider */
  z-index: 1501 !important; /* stylelint-disable-line declaration-no-important */
}
