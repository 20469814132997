@import '~@gateway/components/src/.styles/global';
@import '../../../../styles/mixins';
@import '../../../../styles/common';

.dialog {
  @include desktop-or-tablet {
    margin-left: 62px;
  }

  :global(.mdc-button) {
    height: 30px;
    min-width: 51px;
  }

  .message {
    font-weight: 400;
  }
}

.icon {
  color: $error;
  margin-top: 4px;

  @include dialog-icon;
}

.root {
  background-color: $surface-dark;
  min-height: calc(100vh - 275px);

  .title {
    color: $black;

    @include desktop-or-tablet {
      padding: 8px 16px 32px 7px;
    }

    @include mobile {
      padding: 8px 0 24px;
    }
  }

  article {
    background: #fff;

    /* Neutrals/Light Grey */
    border: 1px solid #e0e0e0;

    /* Elevation - Cards */
    border-radius: 4px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, .05), 0 1px 10px rgba(0, 0, 0, .05), 0 2px 4px rgba(0, 0, 0, .05);
    margin-bottom: 32px;
    padding: 8px 24px 24px;

    @include mobile {
      margin: 0 -16px 24px;
    }
  }

  .details-divider {
    border-color: $divider-bg-color;
  }

  .details-grid {
    grid-gap: 154px;
    padding-top: 16px;
  }

  .details-header-icon {
    color: $primary;
    font-size: 24px;
    height: 32px;
    margin-right: 12px;
    padding: 0;
    width: 32px;
  }

  .deal-grid {
    grid-gap: 162px;
    padding-top: 16px;
  }

  .details-header {
    color: $black;
  }

  .fx-contract-details-article {
    background: #fff;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;

    @include mobile {
      margin: 0 -16px 24px;
    }
  }

  .fx-contract-details-divider {
    display: none;
  }

  .fx-contract-details-grid {
    grid-gap: 162px;
    padding-top: 3px;
  }

  .fx-contract-details-header {
    color: $black;
  }

  .grid-item-content {
    grid-column: 1/13;

    @include tablet {
      grid-column: 2/8;
    }

    @include tablet-landscape {
      grid-column: 3/11;
    }

    &:global(.MuiTypography-root),
    :global(.MuiTypography-body2) {
      font-weight: 400;
    }

    &:global(.MuiTypography-body1) {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .verification-buttons {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    margin-top: 24px;

    :global(.mdc-button) {
      border-color: $primary;
      color: $white;
      height: 42px;
      padding: 11px 8px;
      width: 200px;

      @include mobile {
        width: 100%;
      }
    }

    :global(.mdc-button#cancel-action-button) {
      color: $primary;

      &:disabled {
        color: $primary-light;
      }
    }

    :global(.mdc-button#continue-button):disabled {
      background-color: $light-gray;
      border-color: $light-gray;
      color: $surface-disabled-ME;
    }

    @include mobile {
      padding-bottom: 40px;
    }
  }
}

.field-value {
  display: inline;
}

.value {
  display: inline;
  font-size: 14px;
  overflow: hidden;
  word-break: break-word;

  @include desktop {
    max-width: 75%;
  }
}

.convert-icon {
  display: inline-flex;
  font-size: 14px;
  overflow: hidden;
  word-break: break-word;

  @include desktop {
    max-width: 75%;
  }
}

.compare-arrows {
  transform: scaleX(1);
}

.compare-arrows-reversed {
  transform: scaleX(-1);
}

.info {
  align-items: center;
  background-color: var(--primary-primary-200, $light-blue9);
  display: flex;
  padding: 6px 8px;
  width: fit-content;
}

.info-icon {
  color: $primary-dark;
  margin-right: 8px;
}

.info-label {
  align-items: center;
  font-size: 16px;
  font-weight: 400;
}

.loading-label {
  color: $white;
  padding-top: 24px;
  text-align: center;
}

.compare-arrows-icon {
  cursor: pointer;
}

.payment-instruction-table__amount {
  &:global(.MuiTableCell-root) {
    padding-right: 93px;
  }
}
