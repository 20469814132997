@import '~@gateway/components/src/.styles/global';

.dialog-message {
  :global(.transition-modal-content) {
    background: $white;
    border-radius: 4px;
    max-width: 500px;
    padding: 16px 24px;
    position: relative;
  }

  @include mobile {
    :global(.transition-modal-content) {
      margin: 0 auto;
      max-width: 70%;
    }

    :global(.mdc-layout-grid__inner) {
      height: 100%;
    }
  }

  :global(.transition-modal-header),
  :global(.transition-modal-content) {
    color: $info;
  }

  :global(.transition-modal-close-btn) {
    display: none;
  }

  :global(.mdc-button.default-button--blue:hover) {
    background-color: $primary;
  }

  &__button {
    margin: 23px 0 0 8px;
    width: auto;
  }

  &__header {
    align-items: center;
    display: flex;

    @include mobile {
      align-items: unset;
      flex-direction: column;
    }

    &-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin: 0;
    }

    & &-icon {
      font-size: 40px;
      margin-right: 8px;

      @include mobile {
        margin-bottom: 4px;
      }
    }

    &-icon-warning {
      color: $warning-deep-dark;
    }

    &-icon-error {
      color: $error;
    }

    &-icon-info {
      color: $primary;
    }

    &-icon-check_circle {
      color: $success-medium;
    }
  }

  .modal-close-btn {
    background-color: $white;
    cursor: pointer;
    height: 20px;
    margin: 23px 16px 0 0;
    position: absolute;
    right: 0;
    top: -12px;
    width: 17px;
    z-index: 1;

    @include mobile {
      margin-top: 25px;
    }
  }
}
