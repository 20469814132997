
@import '../.styles/global';

.dialog-popup {
  @include desktop {
    :global(.MuiPaper-root) {
      grid-column: 4 / span 6;
      justify-self: center;
      width: 65%;
    }
  }

  .close-button {
    color: $black;
    cursor: pointer;
    margin: 8px;
    position: absolute;
    right: 0;
  }

  &__drawer {
    @include desktop {
      :global(.MuiPaper-root) {
        width: 616px;
      }
    }

    @include desktop-or-tablet {
      @include side-drawer();

      :global(.MuiBackdrop-root) {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }

  &__title {
    &:global(.MuiDialogTitle-root) {
      padding: 24px 24px 10px;
    }
  }

  &__content {
    &:global(.MuiDialogContent-root) {
      padding: 0 24px 28px;
    }

    &--text {
      display: inline-flex;
      font-weight: 400;
      margin-right: 6px;
      white-space: pre-line;

      &-margin {
        margin-right: 0;
      }
    }

    &--subtext {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }

    .anchor-link {
      color: $primary;
      display: inline-flex;
      font-weight: 500;
    }
  }

  &__buttons-container {
    align-self: flex-end;
    display: flex;
    flex-wrap: wrap;

    &:global(.MuiDialogActions-root) {
      @include mobile {
        align-items: flex-end;
      }
    }

    :global(.mdc-button) {
      border: 0;
      height: 30px;
      min-width: auto;
      order: 2;
      padding: 8px;
      width: auto;

      &:not(:disabled) {
        color: $primary;
      }

      &:last-child:not(:disabled) {
        background: $primary;
        color: $white;
        margin-left: 4px;
      }
    }
  }

  &__header {
    align-items: flex-start;
    display: flex;
    word-break: break-word;

    @include mobile {
      flex-direction: column;
    }

    .icon {
      flex-shrink: 0;
      font-size: 40px;
      height: 40px;
      margin-right: 8px;
      width: 40px;
    }

    & .text {
      font-weight: 500;
      margin-top: 4px;
    }
  }

  & &__full-screen {
    &--paper-full-screen {
      align-items: center;
      justify-content: center;

      @include desktop-or-tablet {
        background-color: $white;
        background-image: url('../.assets/images/svb_chevron_light_edge.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    &--app-bar {
      justify-content: unset;
    }

    &__title {
      padding: 0;
    }

    &__title-mt {
      margin-top: 250px;
    }

    &__header {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 0 40px 32px;
      text-align: center;
    }

    &__content {
      align-items: center;
      flex: none;
      padding: 0 40px;
      text-align: center;
    }

    &__buttons-container {
      align-self: center;
      display: flex;
      padding: 108px 0 0;

      &__btn--full-screen {
        height: 42px;
        min-width: 272px;

        @include tablet {
          min-width: 200px;
        }
      }

      &__secondary-btn--full-screen {
        border: 2px solid $primary;
        height: 42px;
        margin-right: 24px;
        min-width: 272px;

        @include tablet {
          min-width: 200px;
        }
      }

      &__reject-transaction-btn {
        background-color: $graph-red;
        border: 2px solid $graph-red;
        border-radius: 4px;
        color: $white !important; /* stylelint-disable-line declaration-no-important */
        height: 30px;
        line-height: 20px;
        margin-right: 16px;
        min-width: 84px;
        padding: 4px;
      }

      &__approve-transaction-btn {
        background-color: $primary;
        border: 2px solid $primary;
        border-radius: 4px;
        color: $white;
        height: 30px;
        line-height: 20px;
        margin-right: 16px;
        min-width: 84px;
        padding: 4px;
      }

      &__reject-btn {
        border: 2px solid $graph-red;

        &:global(.mdc-button:last-child:not(:disabled)) {
          background-color: $graph-red;
        }
      }
    }
  }
}
