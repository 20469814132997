@import '../.styles/global';

.pagination {
  align-items: center;
  cursor: pointer;
  display: flex;
  user-select: none;

  &-prev,
  &-next {
    align-items: center;
    background: none;
    border: none;
    border-top: 1px solid rgba(255, 255, 255, .38);
    color: $white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font: inherit;
    height: 80px;
    justify-content: center;
    outline: none;
    padding: 0;
    width: 50%;
  }

  &-next {
    border-left: 1px solid rgba(255, 255, 255, .38);
  }

  &-label {
    padding-top: 4px;
  }

  &-disabled {
    color: rgba(255, 255, 255, .38);
    cursor: not-allowed;
  }
}
