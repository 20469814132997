@import '~@gateway/components/src/.styles/global';

.no-recent-bulletins {
  text-align: center;

  .list-icon {
    color: $light-gray;

    &:global(.material-icons) {
      font-size: 40px;

      @include tablet {
        font-size: 70px;
      }
    }
  }

  &-message {
    color: $darker-gray;
    margin-top: 5px;

    @include tablet {
      &:global(.MuiTypography-body1) {
        font-size: 20px;
      }
    }
  }
}
