@import '~@gateway/components/src/.styles/global';

.progress-item {
  &__icon {
    &--success {
      color: $success;
    }

    &--error {
      color: $error;
    }
  }
}
