@import '~@gateway/components/src/.styles/global';

.bulletin-card {
  $this: &;

  &:first-child {
    border-top: 1px solid $light-gray;
  }

  border-bottom: 1px solid $light-gray;
  padding: 8px 0;

  &__content {
    border-left: 5px solid $on-secondary;
    color: $info;
    cursor: pointer;
    min-height: 36px;
    padding: 5px 0 0 8px;

    &--read {
      border-left: none;
      color: $neutral-666;
      padding-left: 13px;
    }
  }

  &__bulletin-subject {
    @include mobile {
      &:global(.MuiTypography-body2) {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  &__date {
    color: $darker-gray;
  }
}
