.time-picker {
  display: flex;
  margin-left: 16px;

  &__time-select {
    margin-right: 16px;
  }

  &__time-select-field {
    width: 125px;

    :global(.MuiInputBase-root) {
      height: 48px;
    }

    :global(.MuiFormLabel-root) {
      font-size: 14px;
    }

    :global(.MuiOutlinedInput-notchedOutline span) {
      padding-right: 0;
    }
  }

  &__timezone-select {
    // to match the MUI Autocomplete default height
    height: 48px;
    width: 75px;
  }
}
