@import '../.styles/global';
@import '~@material/fab/dist/mdc.fab.css';

.mdc-fab {
  background-color: $primary;
  color: $white;
  font-weight: 400;
  text-transform: none;
}

.mdc-fab--mini.mdc-fab--extended {
  font-size: 12px;
  height: auto;
  line-height: 16px;
  padding: 4px 12px;

  .mdc-fab__icon {
    font-size: 1.5em;
    height: 16px;
    margin-left: 4px;
    margin-right: 4px;
    width: 16px;
  }

  @include mobile {
    font-size: 10px;
  }
}
