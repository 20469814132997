@import '~@gateway/components/src/.styles/global';

.success-conetent {
  &_sucess-label {
    text-align: center;

    &_icon {
      color: $success;

      &:global(.material-icons) {
        font-size: 40px;
      }
    }
  }

  &_copy-section {
    border-bottom: 1px solid rgba($grey-border, .38);
    margin-bottom: 25px;

    &_type {
      display: flex;
      flex-direction: row;
      padding-bottom: 10px;
    }

    &_text {
      color: $secondary-650;
    }

    &_icon {
      color: $primary;
      font-size: 20px;
      margin-left: auto;

      &:global(.material-icons) {
        cursor: pointer;
        font-size: 16px;
      }
    }
  }

  &_warn-info-section {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    &_text {
      color: $info;
      font-size: 12px;
    }

    &_icon {
      color: $warning-dark;
      margin-right: 5px;

      &:global(.material-icons) {
        font-size: 16px;
      }
    }
  }

  &_sucess-info {
    color: $secondary-650;
    /* stylelint-disable declaration-no-important */
    line-height: 1.25rem !important;
    padding: 24px 0;
  }
}
