@import '~@gateway/components/src/.styles/global';

.container {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto auto;
}

.status {
  text-transform: uppercase;

  &:global(.MuiTypography-body2) {
    font-weight: 700;
  }
}

.enabled {
  color: $success;
}

.disabled {
  color: $error;
}

.table {
  height: 700px;

  :global(.MuiTableCell-root) {
    box-sizing: border-box;
    height: 40px;
  }

  :global(.MuiTableCell-head) {
    border-bottom: 1px solid $placeholder-gray;
  }

  :global(.MuiTableCell-paddingCheckbox) {
    padding: 0 4px;
  }
}
