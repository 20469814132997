@import '~@gateway/components/src/.styles/colors';

.progress-collection {
  &__summary--dark {
    &:global(.MuiExpansionPanelSummary-root) {
      background: $primary;
    }

    :global(.MuiTypography-root) {
      color: $white;
    }
  }

  &__heading {
    line-height: 16px;
  }

  &__subheading--dark {
    color: $white;
  }

  &__subheading--light {
    color: $darker-gray;
  }

  &__caret--light {
    color: $primary;
  }

  &__caret--dark {
    color: $white;
  }

  &__expansion-panel-details {
    &:global(.MuiExpansionPanelDetails-root) {
      display: block;
      padding: 0;
    }
  }
}
