@import '../.styles/global.scss';

.mdc-button {
  border: 1px solid $button-border;
  color: $button-color;
  height: 36px;
  letter-spacing: inherit;
  text-transform: none;

  &--full-width {
    width: 100%;
  }

  &-capitalize {
    text-transform: capitalize;
  }

  &--outlined {
    background-color: $transparent-90;
    border-width: 2px;
  }

  &.text-button-large,
  &.text-button-small {
    font-size: $text-button--font-size;
    line-height: $text-button--line-height;
  }

  &.text-button-large {
    height: $text-button--large--height;
    padding: $text-button--large--padding;
  }

  &.text-button-small {
    height: $text-button--small--height;
    padding: $text-button--small--padding;
  }

  &.default-button {
    background-color: $white;
    background-color: $button--default-background;
    border: none;
    color: $button--default-color;

    &:hover {
      background-color: $button--default--hovered-background;
      border-color: $button--default--hovered-border;
    }

    &:disabled {
      background-color: $button--default--disabled-background;
      border: none;
      color: $button--default--disabled-color;
      pointer-events: auto;

      &:hover {
        background-color: $button--default--disabled--hovered-background;
      }
    }

    &.mdc-button::before,
    .mdc-button::after {
      background-color: $button--default__before__after-background;
    }

    &--bordered {
      border: 1px solid $button--default--bordered-border;
      border-radius: 3px;

      &:hover {
        background-color: $button--default--bordered--hovered-background;
        color: $button--default--bordered--hovered-color;
      }
    }

    &--bordered-consent-login {
      border: 2px solid $button--default--bordered-border;
      border-radius: 3px;
    }

    &--blue {
      background-color: $button--default--blue-background;
      color: $button--default--blue-color;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.25px;
    }

    &--grey {
      background-color: #007db8;
      color: $button--default--blue-color;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.25px;
    }

    &--grey-disabled {
      background-color: #e0e0e0;
      color: #757575;
      // color: $button--default--blue-color;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.25px;
    }
  }

  &#cancel-button {
    border-color: $button--cancel-border;
    color: $button--cancel-color;

    &:disabled {
      background-color: $button--cancel--disabled-background;
      color: $button--cancel--disabled-color;
    }

    &.mdc-button::before,
    .mdc-button::after {
      background-color: $button--cancel--disabled__before__after-background;
    }
  }

  &#confirm-button {
    background-color: $button--confirm-color;
    border-color: $button--confirm-border;
    color: $button--confirm--disabled-color;

    &:disabled {
      background-color: $button--confirm--disabled-background;
      border-color: $button--confirm--disabled-border;
      color: $neutral-800;
    }

    &.mdc-button::before,
    .mdc-button::after {
      background-color: $button--confirm__before__after-background;
    }
  }

  &--dense {
    &::before,
    &::after {
      content: none;
    }

    &:hover {
      color: $button--dense--hovered-color;
    }

    &:active {
      color: $mdc-theme-secondary;
    }

    &[disabled] {
      color: $button--dense--disabled-color;
    }
  }
}
