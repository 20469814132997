@import '../.styles/global.scss';

:global(.MuiInputBase-root) {
  height: $textfield--height;
}

.text-field {
  height: $textfield--height;

  :global(.MuiFormHelperText-contained) {
    margin-left: $textfield--helperText--margin-left;
    margin-top: $textfield--helperText--margin-top;
  }

  :global(.MuiInputBase-input) {
    cursor: pointer;
    padding: $textfield--padding;
    width: 100%;
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0 48px #fff inset;
  }

  :global(.MuiInputLabel-marginDense) {
    transform: translate(14px, 14px) scale(1);
  }

  :global(.MuiInputLabel-shrink) {
    transform: translate(14px, -6px) scale(.75);
  }

  :global(.MuiInputLabel-root) {
    white-space: nowrap;
  }

  :global(.MuiInputBase-input:focus) {
    cursor: auto;
  }

  &.multiline {
    height: unset;

    :global(.MuiInputBase-root) {
      height: unset;
    }

    :global(.MuiInputBase-inputMultiline) {
      padding: 5px;
    }
  }

  :global(.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']) {
    &:global(.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-marginDense']) {
      padding: $autocomplete--padding;
    }
  }

  .helper-text {
    display: flex;
    gap: 8px;
    justify-content: space-between;

    @include mobile {
      gap: unset;
    }

    &--overflow {
      font-weight: 400;
      white-space: break-spaces;
    }
  }
}

.warning {
  color: $error-2;
}
