@import '~@gateway/components/src/.styles/global';

// If footer height is changed, please update accordingly
// $footer-height / $footer-padding in _variables.scss

@mixin footer-padding($device) {
  padding: 40px map-get($grid-gutter, $device) 10px;
}

.footer {
  bottom: 0;

  @include desktop {
    @include footer-padding(desktop);
  }

  @include tablet {
    @include footer-padding(tablet);
  }

  @include mobile {
    @include footer-padding(phone);
  }

  &-secondary, {
    bottom: $footer-height;
    position: relative;
  }

  &-links,
  &-paragraph {
    color: $darker-gray;
    letter-spacing: .4px;
    padding-bottom: 6px;
    text-align: left;
  }

  &-pipe {
    color: $primary-650;
  }

  &-cookieIcon {
    margin-bottom: -2px;
    padding-left: 5px;
  }

  &-links {
    margin-bottom: 6px;

    a {
      color: $primary-650;
      display: inline-block;
      padding: 0 4px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
