@import '../.styles/global.scss';

$nav-sidebar-xl-breakpoint: 1440px;

.snackbar {
  @include mobile {
    margin-bottom: 60px;
  }

  &:global(.MuiSnackbar-anchorOriginBottomLeft),
  &:global(.MuiSnackbar-anchorOriginBottomCenter),
  &:global(.MuiSnackbar-anchorOriginBottomRight) {
    bottom: 40px;
  }

  &:global(.MuiSnackbar-anchorOriginBottomLeft) {
    left: 96px;

    @media (min-width: $nav-sidebar-xl-breakpoint) {
      left: 224px;
    }
  }

  &:global(.MuiSnackbar-anchorOriginBottomRight) {
    right: 24px;
  }

  &__content {
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 4px 5px 0 rgba($black, .05), 0 1px 10px 0 rgba($black, .05), 0 2px 4px -1px rgba($black, .05);
    color: $white;
    display: grid;
    grid-template: 'icon message action' / auto 1fr auto;
    height: auto;
    min-height: 24px;
    min-width: 319px;
    padding: 12px 8px 12px 16px;

    &--long-action {
      @include mobile {
        grid-row-gap: 10px;
        grid-template: 'icon message' '. action' / auto 1fr;
      }
    }

    @include mobile {
      height: auto;
      min-height: 24px;
      width: 319px;
    }
  }

  .close-icon {
    display: block;
  }

  &__message {
    font-size: 14px;
    grid-area: message;
    margin: 0;
    padding: 0 10px;
    text-align: left;
  }

  &__icon {
    align-self: start;
    grid-area: icon;
  }

  &__checkWithGreenColor {
    background: $green;
    border-radius: 50%;
  }

  &__action {
    cursor: pointer;
    grid-area: action;
    height: auto;
    text-align: end;

    &:global(.mdc-button) {
      border: none;
    }
  }

  &__consent-managment-success {
    background: $green;
    border-radius: 50%;
  }

  &__consent-management-error {
    border-radius: 50%;
    color: $red;
  }
}

.success {
  background-color: $utility-info;

  :global(.MuiTypography-root) {
    color: $white;
  }
}

.warning {
  background-color: $warning-dark;
}

.error {
  background-color: $error;
}

.info {
  background-color: $utility-info;

  :global(.MuiTypography-root) {
    color: $white;
  }
}
