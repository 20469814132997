@import '~@gateway/components/src/.styles/global';

.bulletin-list {
  height: 100%;
  left: unset;
  margin-top: 56px;
  position: fixed;
  right: 0;
  width: 322px;
  z-index: 1400;

  @include mobile {
    margin-top: 56px;
  }

  &-content {
    height: calc(100% - 150px);
    overflow: auto;
    padding: 20px 16px 16px;
  }

  &-description {
    color: $info;
    font-size: 12px;
  }

  &-subject {
    &:global(.MuiTypography-body1) {
      margin-bottom: 15px;
    }
  }

  :global(.slide-wrapper__header) {
    height: 14px;
  }
}
