@import '../.styles/global';

.image-preview {
  align-items: center;
  background-color: $backdrop-overlay;
  display: flex;
  justify-content: center;
  user-select: none;

  :global(.MuiButton-text) {
    background-color: $white;
    border: 2px solid $white;
    border-radius: 4px;
    color: $black;
    font-weight: 500;
    margin: 14px 10px;
    padding: 0;
  }

  :global(.MuiButton-outlined) {
    background-color: transparent;
    border: 2px solid $white;
    border-radius: 4px;
    color: $white;
    font-weight: 500;
    margin: 14px 10px;
    padding: 10px 24px;
  }

  :global(.MuiButton-text:hover) {
    background-color: $white;
  }

  :global(.MuiButton-outlined:hover) {
    background-color: transparent;
  }

  &__deposit-label {
    padding: 10px;
  }

  &__check-label {
    padding: 10px 24px;
  }

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    padding: 24px 24px 0;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__image {
    min-height: auto;
    min-width: auto;
    object-fit: contain;
    width: 100%;

    @media (orientation: portrait) and (max-width: 767px) {
      height: 500px;
      object-fit: contain;
      transform: rotate(90deg);
      width: 500px;
    }
  }

  &__controls--disable {
    color: $gray;
    cursor: default;
  }

  &__vertical-line {
    background: $black;
    height: 24px;
    margin: 0 7.5px;
    width: 1px;
  }
}
