@import '../.styles/colors';

.chip {
  &.MuiChip-root {
    max-width: 95%;
    user-select: none;
  }
}

.chip--outlined.MuiChip-root {
  background-color: transparent;
  border: 1px solid currentColor;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 500;
  height: .875rem;
  line-height: 14px;

  &:disabled {
    color: $text-on-surface-disabled;
    cursor: default;

    &:hover::before,
    &:hover::after {
      background-color: transparent;
    }
  }
}

.chip--regular.MuiChip-root {
  font-family: Roboto, sans-serif;
  font-size: .875rem;
  font-weight: 500;
  letter-spacing: .01rem;
  line-height: 1.25rem;

  @media (max-width: 1280px) {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  @media (max-width: 1023px) {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  @media (max-width: 767px) {
    font-size: .75rem;
    font-weight: 400;
    line-height: 1rem;
  }
}

.chip-content {
  overflow-x: hidden;
  text-overflow: ellipsis;
}
