@import '~@gateway/components/src/.styles/global';

.progress-manager {
  bottom: 40px;
  position: fixed;
  right: 24px;
  width: 303px;
  z-index: $z-index-slider;

  &__expansion-panel-details {
    max-height: 365px;
    overflow: hidden;

    &__scrollbar {
      overflow-y: scroll;
    }
  }
}
