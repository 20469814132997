@import '../.styles/global.scss';

.data-table-cell {
  &__light {
    &:global(.MuiTableCell-root) {
      font-weight: 400;
    }
  }

  &:global(.MuiIcon-root) {
    cursor: pointer;
  }

  :global(.MuiCheckbox-root) {
    color: $light-gray;
  }
}

.data-table-icon-cell {
  &:global(.MuiTableCell-root) {
    color: $primary;
    width: 24px;
  }
}
