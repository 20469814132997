@import '../.styles/global.scss';

.content {
  grid-area: content;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  position: relative;

  &:global(.lock-scroll) {
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
  }
}
