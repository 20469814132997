@import '~@gateway/components/src/.styles/global';

@mixin touch-device {
  &:hover,
  &:active,
  &:focus {
    background: none;
  }

  &.active-submenu {
    background: $light-blue6;

    @include mobile {
      background: none;
    }
  }
}

$shadow: 3px 1px 4px 0 rgba(36, 36, 36, .16);

.icon {
  background-repeat: no-repeat;
  fill: $white;
  height: 22px;
  margin: 16px auto 8px;
  width: 22px;

  &--submenu-enabled {
    height: 24px;
    width: 24px;

    @include mobile {
      margin: 8px auto;
    }
  }
}

.navigation-divider {
  border-top: 1px solid $alto-gray;
  display: block;
  margin-bottom: 8px;
  margin-top: 8px;

  @media screen and (min-width: $nav-sidebar-lg-breakpoint) {
    border-top: 0 solid $white;
  }
}

.navigation {
  &-link {
    align-items: center;
    color: $white;
    cursor: default;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    padding-bottom: 16px;
    text-align: center;
    text-decoration: none;

    &:hover {
      background-color: $transparent-90;
    }

    &-wrapper {
      position: relative;
    }

    &-title {
      margin: 0;

      @include mobile {
        font-weight: 500;
      }
    }

    &-arrow {
      cursor: pointer;
      opacity: .6;
      padding-right: 16px;
      position: absolute;
      right: 5px;
      top: 15px;

      @include mobile {
        top: 15px;
      }
    }

    &--expanded,
    &--side,
    &--bottom {
      cursor: pointer;
    }

    &--bottom {
      padding: 0 0 10px;
    }

    &--side {
      @include mobile {
        .icon--submenu-enabled {
          align-items: center;
          display: flex;
          height: 24px;
          justify-content: center;
          margin: 12px 8px 12px 16px;
          width: 24px;

          path {
            fill: $light-black;
          }
        }

        .deposit-icon {
          path {
            fill: none;
            stroke: $light-black;
          }
        }
      }

      @media (min-width: $nav-sidebar-xl-breakpoint) {
        flex-direction: row;
        height: 72px;
        padding-bottom: 0;
        position: relative;
        text-align: start;

        .icon {
          height: 24px;
          margin: 14px 3px 14px 24px;
          width: 24px;
        }
      }

      .navigation-link-title {
        font-size: 10px;
        margin: 0;
        padding: 0 5px;

        @media (min-width: $nav-sidebar-xl-breakpoint) {
          font-size: 16px;
          font-weight: 500;
        }
      }

      &--horizontal {
        flex-direction: row;
        padding-bottom: 0;
        text-align: start;

        &:hover {
          background-color: $primary-900;
        }

        .icon {
          height: 24px;
          margin: 14px 16px 14px 24px;
          width: 24px;
        }

        &.navigation-link--expanded {
          &.active-item {
            background: none;

            &:hover {
              background-color: $primary-900;
            }

            &.touch {
              @include touch-device();
            }
          }
        }

        &.active-item {
          background: $primary-900;
        }

        .navigation-link-title {
          font-size: 20px;
          padding: 0;

          @include mobile {
            font-size: 16px;
          }
        }

        &--submenu-enabled {
          .navigation-link-title {
            font-size: 14px;

            @media screen and (min-width: $nav-sidebar-xl-breakpoint) {
              font-size: 16px;
            }
          }

          &:hover {
            background-color: $light-blue6;
          }

          @include mobile {
            color: $light-black;

            &:hover {
              background: none;
            }
          }

          &.active-item {
            background: linear-gradient(to right, $black 4px, $white 4px);
          }
        }
      }

      div {
        margin: 0;
      }

      @include mobile {
        padding: 4px 0 4px 20px;

        .icon {
          margin: 0 15px 0 0;
        }

        .navigation-link-title {
          vertical-align: super;
        }
      }
    }

    &-logo {
      background: none;
    }
  }
}

.navigation-link--side--submenu-enabled {
  &--active-submenu {
    background: linear-gradient(to right, $white 4px, $light-blue6 4px);
  }

  &:hover {
    background: $light-blue6;
  }

  @include mobile {
    &.navigation-link--side--horizontal--submenu-enabled {
      padding: 0;
    }

    &.--navigation-link--side--horizontal .icon {
      &--submenu-enabled {
        height: 48px;
        margin-right: 10px;
        width: 48px;
      }
    }

    &:hover {
      background: none;
    }

    &.active-item {
      color: $primary;
      padding: 0;

      .icon--submenu-enabled {
        fill: $primary;

        path {
          fill: $primary;
        }
      }
    }

    &.navigation-link--bottom--submenu-enabled {
      background: none;
      color: $white;
      padding: 0;

      &.active-item::after {
        border-bottom: 4px solid $white;
        border-radius: 2px;
        content: '';
        display: block;
        margin: 7px auto 0;
        width: 80%;
      }

      &.active-item::before {
        display: none;
      }

      .icon--submenu-enabled {
        fill: $white;

        path {
          fill: $white;
        }
      }

      .deposit-icon {
        path {
          fill: none;
        }
      }
    }

    .primary-menu {
      justify-content: space-between;
    }
  }

  @media (min-width: $nav-sidebar-md-breakpoint) {
    align-items: center;
    gap: 4px;
    justify-content: center;
    padding: 15px 11px;

    &.active-submenu {
      background: $light-blue6;
    }
  }

  @media (min-width: $nav-sidebar-xl-breakpoint) {
    flex-direction: row;
    gap: 8px;
    height: 40px;
    margin: 0;
    padding: 16px 8px;

    .icon {
      &--submenu-enabled {
        align-items: center;
        display: flex;
        flex-shrink: 0;
        font-size: 24px;
        height: 24px;
        justify-content: end;
        margin: 0;
        width: 24px;
      }
    }
  }

  @media (min-width: $nav-sidebar-xl-breakpoint) {
    .navigation-link-title {
      font-size: 16px;
      font-weight: 500;
      padding: 0;
    }
  }

  &--horizontal {
    color: $black;

    @include mobile {
      path {
        fill: $black;
      }
    }
  }

  &.navigation-link--expanded {
    &:hover {
      background: $light-blue6;
    }

    &.active-item {
      &:hover {
        background: $light-blue6;
      }

      @include mobile {
        background: linear-gradient(to right, $black 4px, $white 4px);

        &:hover {
          background: none;
        }
      }
    }
  }

  .navigation-link-title {
    div {
      @media (min-width: $nav-sidebar-lg-breakpoint) and (max-width: ($nav-sidebar-xl-breakpoint - 1)) {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.primary-menu {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  @include mobile {
    color: $black;
    width: calc(100% - 48px);
  }

  @media (min-width: $nav-sidebar-xl-breakpoint) {
    display: flex;
    justify-content: space-between;
  }

  @include tablet {
    display: flex;

    .navigation-link-title {
      flex-shrink: 0;
      line-height: 14px;
      max-width: 64px;
    }
  }

  :global(.MuiSvgIcon-root) {
    height: 24px;
    width: 24px;

    @include mobile {
      font-size: 1.75rem;
      height: 24px;
      margin: 12px 16px 12px 0;
      padding: 0;
      width: 24px;
    }

    @include tablet {
      display: none;
    }

    @media (min-width: $nav-sidebar-lg-breakpoint) and (max-width: ($nav-sidebar-xl-breakpoint - 1)) {
      display: none;
    }
  }
}

.submenu {
  &__drawer {
    margin-top: $header-height;

    @media (min-width: $nav-sidebar-lg-breakpoint) {
      margin-left: $nav-sidebar-width;
    }

    @media (min-width: $nav-sidebar-xl-breakpoint) {
      margin-left: $nav-sidebar-xl-width;
    }

    @include tablet-portrait {
      margin-left: $nav-sidebar-width;
    }

    @include tablet {
      margin-top: $header-mobile-height;
    }

    @include mobile {
      margin-top: 0;
    }

    &:global(.MuiDrawer-root) {
      @include mobile {
        height: calc(100% - 98px);
      }

      @media (min-width: $nav-sidebar-md-breakpoint) {
        /* stylelint-disable declaration-no-important */
        z-index: 950 !important;
      }
    }

    :global(.MuiDrawer-paper) {
      box-shadow: none;
      position: relative;
      width: $nav-sidebar-xl-width;

      @include mobile {
        height: calc(100%);
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1300;
      }

      @media (min-width: $nav-sidebar-md-breakpoint) {
        z-index: 950 !important;
      }
    }

    :global(.MuiBackdrop-root) {
      background-color: rgba(33, 33, 33, .75);
      top: $header-height;

      @media (min-width: $nav-sidebar-xl-breakpoint) {
        left: $nav-sidebar-xl-width;
      }

      @include mobile {
        bottom: 98px;
        top: 0;
      }

      @include tablet {
        left: $nav-sidebar-width;
      }
    }
  }
}

.active-item {
  &.navigation-link--side {
    background: linear-gradient(to right, $white 4px, $primary 4px);

    &.active-submenu,
    &:hover {
      background: linear-gradient(to right, $white 4px, $light-blue6 4px);
    }

    @include mobile {
      background: none;

      &:hover {
        background: none;
      }

      .primary-menu {
        color: $primary;
        width: calc(100% - 48px);
      }

      &--submenu-enabled::before {
        border-left: 4px solid $primary;
        border-radius: 0 2px 2px 0;
        content: '';
        display: block;
        height: 48px;
        margin-right: -4px;
      }
    }

    &--submenu-enabled {
      &.active-submenu,
      &:hover {
        @include mobile {
          background: none;
        }
      }
    }
  }
}

.navigation-link--bottom--submenu-enabled {
  margin-top: 10px;
  padding: 0 0 10px;

  &.active-item {
    color: $white;
  }
}

.touch {
  @include touch-device();
}

.submenu--emulated {
  :global(.MuiDrawer-paper) {
    left: 10px;
    top: 68px;

    @include mobile {
      height: calc(100% - 98px);
      left: 10px;
      right: 10px;
      top: 63px;
      width: calc(100% - 20px);
    }
  }

  :global(.MuiBackdrop-root) {
    left: 10px;
    right: 10px;
    top: 68px;

    @include mobile {
      display: none;
    }
  }
}

.submenu--pre-migrated-client {
  :global(.MuiDrawer-paper) {
    top: 0;
    z-index: 100 !important;

    @include tablet {
      top: 115px;
    }

    @include desktop-or-tablet-landscape {
      height: calc(100vh - 150px);
      top: 90px;
    }

    @include desktop {
      top: 90px;
    }
  }
}
