@import '~@gateway/components/src/.styles/global';

.change-phone-number-form {
  height: 100%;
  left: unset;
  margin-top: 56px;
  overflow-y: auto;
  position: fixed;
  right: 0;
  width: 322px;
  z-index: $z-index-slider;

  &-content {
    height: calc(100% - 150px);
    overflow-y: scroll;
    padding: 16px;
  }

  &-desc {
    margin-bottom: 20px;
  }

  .change-phone-number-form-save-btn {
    height: 42px;
  }

  :global(.slide-wrapper__header) {
    height: 14px;
  }
}

.country-code-input {
  &:global(.MuiFormControl-root) {
    margin: $textfield-margin;
  }
}

.phone-number-input {
  &:global(.MuiFormControl-root) {
    margin-bottom: 42px;
    margin-top: $textfield--margin-top;
  }
}
