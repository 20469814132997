@import '../.styles/global';

.holiday-calendar {
  color: $primary;
  cursor: pointer;
  height: 24px;
  margin-right: 24px;
  padding: 0;
  user-select: none;
}
