@import '~@gateway/components/src/.styles/global';

$bottom-navigation-padded-height: 90px;

.default-layout {
  display: grid;
  grid-template-areas:
    'header header'
    'aside content'
    'aside content';
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  height: 100vh;
  overflow-y: hidden;

  @include mobile {
    grid-template-columns: 0 1fr;
  }

  &--no-header {
    grid-template-rows: 0 1fr auto;
  }

  &--emulation {
    border-left: 10px solid $emulation-background;
    border-right: 10px solid $emulation-background;
    grid-template-areas:
      'emulation emulation'
      'header header'
      'aside content'
      'aside content';
    grid-template-rows: auto auto 1fr;
  }
}

.content-only-layout {
  background-color: $white;
  display: block;
  padding: 0 map-get($grid-gutter, desktop);

  @include mobile {
    padding: 0 map-get($grid-gutter, phone);
  }
}

.page-layout-blured {
  -webkit-filter: blur(16px);
  filter: blur(16px);
}

.top-nav {
  grid-area: header;
}

.side-nav {
  grid-area: aside;
  height: 100%;
  overflow: hidden;
  width: $nav-sidebar-width;
  z-index: 1000;

  @media (min-width: $nav-sidebar-xl-breakpoint) {
    width: $nav-sidebar-xl-width;
  }
}

.content {
  grid-area: content;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  position: relative;

  &:global(.lock-scroll) {
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
  }
}

.content-children--footer-padded {
  min-height: calc(100% - #{$footer-full-height});

  &.content-children--bottom-nav-padded {
    min-height: calc(100% - #{$bottom-navigation-padded-height} - #{$footer-full-height});
  }
}

.footer {
  grid-area: content;
  height: fit-content;

  &--bottom-padded {
    padding-bottom: $bottom-navigation-padded-height;
  }
}

.snackbar:global(.MuiSnackbar-root) {
  left: 24px;
  position: absolute;
  right: 24px;

  div {
    width: 100%;
  }
}

.password-updated-snackbar:global(.MuiSnackbar-anchorOriginBottomLeft) {
  left: 24px;
}

.deeplink-modal {
  :global(.mdc-button) {
    max-height: 30px;
  }
}

.deeplink-error-modal {
  :global(.mdc-button) {
    max-width: 96px;
  }

  :global(.transition-modal-info) {
    max-width: 488px;
  }

  :global(.transition-modal-info-message) {
    line-height: 22px;
  }
}

.emulation-header-pre-migrated {
  background-color: $pre-migrated-header-bg-color;
  border-bottom: 2px solid lightgrey;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .15), 0 2px 1px 0 rgba(0, 0, 0, .15), 0 1px 3px 0 rgba(0, 0, 0, .15);
  padding: 1rem;
  position: relative;
  z-index: 999;

  &__label {
    align-items: center;
    color: $black;
    display: flex;

    a {
      color: $primary;
      font-weight: 700;
    }

    :global(.material-icons) {
      color: $error;
      font-size: 40px;
      height: 40px;
      margin-right: 1rem;
      width: 40px;
    }

    &__header {
      color: $error-2;
      font-weight: 700;
    }

    &__days_container {
      align-items: center;
      display: flex;
      gap: .5rem;

      &__remaining_days {
        color: $primary-dark;
      }
    }
  }
}

.emulation-header {
  align-items: center;
  background-color: $emulation-background;
  display: flex;
  grid-area: emulation;
  justify-content: center;
  padding: 20px 0;
  position: relative;
  width: 100%;
  z-index: 1;

  &__label {
    color: $white;
    justify-self: center;
  }

  &__action {
    position: absolute;
    right: 0;

    :global(.mdc-button--outlined:not(:disabled)) {
      border-color: $white;
      color: $white;
    }
  }
}

.logo-mobile-fdic {
  max-width: calc(100% - 32px);
  padding: 0 16px;
}
