@import '~@gateway/components/src/.styles/global';

.holiday {
  padding: 0;
  width: 0;
}

.row {
  :global(.rmwc-data-table__row) {
    height: 32px;
  }

  &--selected :global(.rmwc-data-table__cell) {
    background: $warning-deep-dark-2;
    padding-left: 10px;
    padding-right: 17px;
  }
}

.table {
  &:global(.rmwc-data-table) {
    border: none;
  }

  :global(.rmwc-data-table__content) {
    font-size: 12px;
    width: 100%;
  }

  :global(.rmwc-data-table__row) {
    height: 32px;
  }

  :global(.rmwc-data-table__cell) {
    height: 32px;

    &:nth-of-type(2) {
      padding-left: 0;
      padding-right: 10px;
      width: 40px;
    }

    &:last-of-type {
      padding-right: 40px;
      width: 40px;
    }
  }
}
