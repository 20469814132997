@import '~@gateway/components/src/.styles/global';
@import '../../../styles/mixins';

.reject-payment-btn :global(.MuiDialogActions-root) {
  gap: 12px;
}

@mixin table-cell-style-overide {
  &:global(.MuiTableRow-root) {
    background-color: transparent;
  }
}

@mixin overflow-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin detail-value {
  color: $dark-font-color;
  font-weight: 400;
  white-space: break-word;
}

.btns-container {
  @extend .action-buttons;
}

.tooltip-label {
  border-bottom: 0;
}

.tooltip {
  &:global(.MuiTooltip-tooltip) {
    background-color: $primary;
    padding: 16px;
  }

  :global(.MuiTooltip-arrow) {
    color: $primary;
  }

  .tooltip-account-name {
    padding-bottom: 8px;
  }
}

.fiber-icon {
  font-size: 5px;
  margin: 0 3px;
  vertical-align: middle;
}

.subtitle {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > span {
    &:first-child {
      @include mobile {
        font-size: 12px;
      }
    }

    &:last-child {
      @include mobile {
        font-size: 12px;
      }
    }
  }
}

.recurring-transaction-row {
  display: flex;
  padding-top: 8px;

  :first-child {
    padding-right: 64px;
  }

  .subtitle {
    color: $surface-disabled-ME;
  }

  .freq {
    font-size: 14px;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.account-number {
  border-bottom: 1px dotted $black;
}

.transaction-id-value {
  @include detail-value;
}

.transaction-id-field {
  margin-top: -8px;
  padding-top: 8px;
}

.transaction-id-label {
  display: flex;
  flex-direction: column;
}

.check-icon {
  color: $primary;
  margin-left: 5px;
  padding: 0;
  user-select: none;
  vertical-align: middle;
  width: 24px;

  @include mobile {
    font-size: 16px;
  }
}

.check-icon-row {
  display: flex;
}

.transaction-type {
  display: inline-block;
  margin-bottom: -4px;
  max-width: 36%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include tablet-portrait {
    width: 21%;
  }
}

.image {
  align-items: center;
  border: 1px solid $black;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  max-height: 198px;
  max-width: 460px;

  @include tablet {
    max-height: 120px;
    max-width: 247px;
  }

  @include mobile {
    width: 100%;
  }
}

.copy-icon {
  color: $primary;
  display: inline;
  font-size: 24px;
  margin: 0 0 0 4px;
}

.image-label {
  color: $primary;
  cursor: pointer;
  max-width: 460px;
  padding-top: 8px;

  &:hover {
    color: $on-secondary;
  }

  @include tablet {
    max-width: 247px;
  }

  @include mobile {
    width: 100%;
  }
}

.pipe-seperator {
  color: var(--neutrals-grey-500, #9e9e9e);
  padding-left: 3px;
  padding-right: 3px;
}

.image-row {
  display: flex;
  justify-content: space-between;

  @include desktop-or-tablet {
    padding-top: 8px;
  }
}

.image-col {
  flex-direction: column;
  text-align: center;
  width: 49%;
}

.fx-deal-number {
  display: inherit;
}

.total-contract-amount-label {
  display: inherit;

  @include desktop {
    padding-left: 15px;
  }
}

.total-contract-amount-value {
  font-weight: 400;
}

.spinner {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.split-labels {
  &:global(.MuiTypography-root) {
    margin-bottom: 5px;
  }
}

.template-approval-body {
  background-color: $disabled-background-gray;
  width: 100%;
}

.template-approval-message {
  display: flex;
}

.view-templates-pending-button {
  justify-content: left;
  padding: 36px;

  &:global(.mdc-button::before) {
    background-color: $disabled-background-gray;

    &:hover {
      background-color: $disabled-background-gray;
    }
  }
}

.default-message {
  align-items: flex-start;
  color: $darker-gray;
  display: flex;
  flex-direction: column;
  margin: 16px 0;

  :global(.MuiTypography-body2) {
    font-weight: 400;
  }
}

.loading-label {
  color: $white;
  padding-top: 24px;
  text-align: center;
}

.dialog {
  @include dialog-content;

  .dialog-message {
    @include mobile {
      font-size: 16px;
    }

    font-weight: 400;
  }
}

.footer {
  display: flex;
  white-space: nowrap;

  .bold {
    font-weight: 500;
  }

  .initiator-info {
    align-self: center;
    margin-left: 72px;

    &__value {
      margin-left: 3px;

      @include detail-value;

      @include mobile {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 2px;
        margin-left: 0;
      }
    }
  }

  .footer-value {
    color: var(--neutrals-grey-600, #757575);
    font-weight: 400;
    margin: 0 6px 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include mobile {
      line-height: 16px;
      margin-left: 0;
    }
  }

  .footer-label {
    @include mobile {
      color: var(--neutrals-grey-600, #757575);
      margin-bottom: 10px;
    }
  }

  @include mobile {
    flex-direction: column;
  }
}

.root {
  border: none;
  width: 100%;

  :global(.mdc-checkbox__background) {
    border: 2px solid $light-gray;
  }

  &:global(.MuiTableContainer-root) {
    overflow: visible;
  }

  .content {
    table-layout: fixed;
    width: 100%;

    @include mobile {
      overflow: hidden;
      white-space: normal;
    }

    @include tablet {
      white-space: normal;
    }

    :global(.MuiTableCell-root) {
      border: 0;
    }

    tr.row--expanded:not(.row--selected) {
      background-color: $hovered-bg-item-color;
    }

    tr.row--selected {
      background-color: $selected-bg-item-color;
    }

    .info-row {
      border-bottom: 1px solid $light-gray;
      cursor: pointer;

      @include table-cell-style-overide;

      &:hover {
        background-color: $hovered-faded-bg-color;
        cursor: pointer;
      }

      &--hover {
        background-color: $hovered-bg-item-color;
        border-bottom: none;
      }

      &:nth-child(2n):not(:hover):not(.row--selected):not(.row--expanded) {
        background-color: $surface-striped;
      }

      .icon {
        font-size: 12px;
        margin-right: 8px;

        @include tablet {
          font-size: 10px;
        }
      }

      &__cell {
        background: transparent;
        overflow: hidden;
        padding: 10px 16px;
        vertical-align: top;

        &:first-of-type {
          align-self: center;
          padding-left: 8px;
          text-decoration: none;
        }

        @include desktop {
          &:first-of-type {
            width: 15%;
          }

          &:nth-of-type(2) {
            width: 16%;
          }

          &:nth-of-type(3) {
            width: 17%;
          }

          &:nth-of-type(4) {
            width: 15%;
          }

          &:nth-of-type(5) {
            width: 11%;
          }

          &:nth-of-type(6) {
            width: 12%;
          }

          &:last-of-type {
            padding: 10px 6px;
            width: 4%;
          }
        }

        @include tablet {
          padding: 10px 12px;

          &:first-of-type {
            width: 28%;
          }

          &:nth-of-type(2) {
            width: 23%;
          }

          &:nth-of-type(3) {
            width: 24%;
          }

          &:nth-of-type(4) {
            padding-right: 0;
            width: 22%;
          }

          &:last-of-type {
            padding: 10px 6px;
            width: 8%;
          }
        }

        @include tablet-landscape {
          &:first-of-type {
            width: 27%;
          }

          &:nth-of-type(2) {
            width: 27%;
          }

          &:nth-of-type(3) {
            width: 17%;
          }

          &:nth-of-type(4) {
            width: 20%;
          }

          &:last-of-type {
            width: 10%;
          }
        }

        .checkbox {
          &--align-top {
            margin: -36px 0 0 3px;
          }
        }

        .date {
          margin: 0 0 -1px 16px;

          :global(.MuiTypography-body2) {
            font-weight: 400;
          }
        }

        .date-deal-payment {
          margin: 0 0 -8px 6px;

          :global(.MuiTypography-body2) {
            font-weight: 400;
          }

          &__scheduler {
            align-self: center;
            color: $primary;
            float: left;
            font-size: 23px;
            margin-left: -12px;
            margin-right: 7px;
            vertical-align: middle;

            @include mobile {
              text-align: left;
            }
          }
        }

        .primary-text {
          align-items: center;
          display: flex;
          height: 20px;
        }

        .secondary-text {
          color: $darker-gray;
          font-weight: 400;
        }

        .import-text {
          color: $darker-gray;
          margin-top: 3px;
        }

        .ref-field {
          border: 1px solid $darker-gray;
          border-radius: 2px;
          font-size: 12px;
          line-height: 14px;
          margin-top: 7px;
          padding: 1.6px 8px;
          white-space: nowrap;
          width: fit-content;

          @include tablet {
            align-content: center;
            display: flex;
            font-size: 10px;
            justify-content: center;
            margin: 0 0 0 4px;
            padding: 1.4px 5px;
          }
        }
      }

      .expand-btn__cell {
        color: $primary;

        @include tablet {
          padding: 0;
        }
      }
    }

    .transaction-details__row {
      border: 0;
      padding: 0;

      .recurring-transaction-row {
        margin-bottom: 14px;
      }

      @include table-cell-style-overide;

      &__cell {
        background-color: $hovered-bg-item-color;
        max-width: 0;
        padding: 16px;
        vertical-align: top;

        @include tablet {
          padding-left: 12px;
        }

        &:first-child {
          padding-left: 72px;

          @include mobile {
            padding-left: 16px;
          }
        }

        .details-widget {
          &__header {
            color: $info;
            font-weight: 500;

            &.space-top__desktop-only {
              @include desktop-or-tablet {
                margin-top: 16px;
              }
            }

            &.space-top--tax {
              margin-top: 24px;
            }
          }

          &__label {
            color: $light-black;
            display: block;
            font-weight: 400;
            padding-bottom: 4px;
            padding-top: 8px;
          }

          &__value {
            @include detail-value;
          }

          &__value--overflow {
            overflow-wrap: break-word;
            white-space: break-spaces;

            @include tablet {
              padding-right: 12px;
            }
          }

          &__add-bottom-padding {
            padding-bottom: 8px;
          }

          &__add-top-padding {
            padding-top: 8px;
          }
        }
      }
    }

    @include tablet {
      .account-number {
        margin: 0 4px;
      }

      .fiber-icon {
        margin: 0 3px 4px 0;
      }
    }

    .transaction-details-second-cell {
      padding-top: 35px;
    }

    .transaction-details-footer__row {
      border-bottom: 1px solid $light-gray;
      padding: 0;

      @include table-cell-style-overide;

      &__cell {
        background-color: $primary-25;

        @include tablet {
          padding: 8px 0;
        }

        &:first-child {
          padding-left: 72px;
        }

        &:nth-child(2) {
          padding-left: 16px;
        }

        .reject-transaction-btn {
          background-color: $graph-red;
          border: 2px solid $graph-red;
          border-radius: 4px;
          color: $white;
          height: 30px;
          line-height: 20px;
          margin-right: 16px;
          min-width: fit-content;
          padding: 8px;
          width: auto;
        }

        .approve-transaction-btn {
          background-color: $primary;
          border: 2px solid $primary;
          border-radius: 4px;
          color: $white;
          height: 30px;
          line-height: 20px;
          margin-right: 16px;
          min-width: fit-content;
          padding: 8px;
          width: auto;
        }

        .single-recurrent-transaction-btn {
          max-width: 150px;
          width: fit-content;
        }

        .action-buttons {
          display: flex;
          justify-content: flex-end;
        }

        .cancel-actions-btn {
          align-items: center;
          border: 2px solid $error;
          border-radius: 4px;
          color: $error;
          display: flex;
          height: 24px;
          line-height: 20px;
          margin-right: 16px;
          padding: 4px 0 4px 4px;
          position: relative;
          -webkit-tap-highlight-color: transparent;
          width: fit-content;

          &:hover {
            cursor: pointer;
          }

          .cancel-list {
            margin-top: 3px;
            right: 0;
            top: 100%;
            z-index: 5;

            @include dropdown;

            &__item {
              @include menu-item;

              font-size: 14px;

              &:hover {
                background-color: $light-blue4;
              }

              &:active {
                background-color: $light-blue3;
              }
            }
          }
        }

        .edit-actions-btn {
          align-items: center;
          background-color: $primary;
          border-radius: 4px;
          color: $white;
          display: flex;
          justify-content: flex-end;
          line-height: 20px;
          margin-right: 24px;
          min-width: 104px;
          position: relative;
          -webkit-tap-highlight-color: transparent;
          width: fit-content;

          &:hover {
            cursor: pointer;
          }

          :global(.MuiTypography-button) {
            margin-right: 11px;
          }

          :global(.material-icons) {
            margin-right: 6px;
          }

          .edit-list {
            margin-top: 1px;
            right: 0;
            top: 100%;
            z-index: 5;

            @include dropdown;

            &__item {
              @include menu-item;

              font-size: 14px;

              &:hover {
                background-color: $light-blue4;
              }

              &:active {
                background-color: $light-blue3;
              }
            }
          }
        }

        .cancel-btn {
          border: 2px solid $error;
          border-radius: 4px;
          color: $error;
          line-height: 20px;
          margin-right: 16px;
          padding: 4px;
          width: fit-content;
        }

        .edit-btn {
          background-color: $primary;
          border-radius: 4px;
          color: $white;
          line-height: 20px;
          margin-right: 24px;
          min-width: 104px;
          width: fit-content;
        }

        .edit-appr-btn {
          border: none;
          display: flex;
          justify-content: flex-end;
          padding: 0;
        }

        .divider-line {
          border-left: 1px solid $alto-gray;
          height: 30px;
          margin: 0 16px;
        }
      }
    }

    .swift-confirmation-field {
      height: 36px;
    }

    .row-divider {
      border-top: 1px solid $surface-disabled;
      margin: 0 42px;
      padding-top: 0;
    }

    .row-divider-wrapper {
      background-color: $hovered-bg-item-color;
      height: 1px;
      padding: 0 0 4px 30px;

      @include tablet {
        padding: 0 0 0 30px;
      }
    }

    .transfer-details__row {
      @include table-cell-style-overide;

      &__cell {
        background-color: $hovered-bg-item-color;
        padding: 0;
      }
    }

    .transaction-id__row {
      padding-left: 0;

      @include table-cell-style-overide;

      &__cell {
        background-color: $hovered-bg-item-color;
        height: 0;
        padding-left: 30px;
        padding-right: 0;

        @include tablet {
          padding: 12px 0 0 12px;

          &:first-child {
            padding-left: 72px;
          }
        }

        .pay-from {
          display: flex;
          white-space: nowrap;
        }

        .pay-from-value {
          align-self: center;
          display: flex;
          white-space: nowrap;

          .account-name {
            margin: 0 2px;
            max-width: 90px;

            @include detail-value;
          }
        }

        .failure-reason {
          color: $error;
          display: block;
          margin-right: 72px;
          white-space: break-spaces;

          @include tablet {
            margin-right: 30px;
          }
        }

        .transaction-id,
        .failure-reason {
          margin-left: 42px;
          padding-bottom: 0;
          padding-top: 0;

          @include tablet {
            margin-left: 0;
            padding: 0;
          }

          &__value {
            display: inline-block;
            margin-bottom: -6px;
            max-width: 96px;

            @include tablet {
              max-width: 58%;
            }

            @include detail-value;
          }
        }
      }

      &__split-cell {
        padding-top: 20px;
        vertical-align: top;
      }

      &__cell-swift {
        background-color: $hovered-bg-item-color;
        padding-left: 0;
        padding-right: 70px;

        @include tablet {
          background-color: transparent;
          padding-right: 0;
        }

        .ref-num-container {
          display: flex;
          margin-top: -8px;
          overflow: hidden;
          padding: 8px 15px 0;
          text-overflow: ellipsis;

          @include tablet {
            padding: 0;
          }
        }

        .ref-num-banner {
          margin-bottom: 0;
          margin-top: 8px;

          @include tablet {
            margin-top: 15px;
          }
        }

        .fx-deal-number {
          color: $black;
          display: flex;
          font-weight: 500;
          grid-column: 2;
          margin-top: -8px;
          overflow: hidden;
          padding: 8px 15px 0;
          text-overflow: ellipsis;

          @include tablet {
            padding: 0;
          }
        }

        .fx-split {
          display: inline;
        }

        .ref-num-label {
          color: $primary;
          cursor: pointer;
        }

        .fx-confirm-label {
          color: $black;
          display: flex;
          font-weight: 500;
          grid-column: 2;
          overflow: hidden;
          padding: 8px 0 0 16px;
          text-overflow: ellipsis;

          @include tablet {
            padding: 0;
          }
        }

        .fx-confirmation-value {
          font-weight: 400;
        }

        .copy-icon {
          color: $primary;
          cursor: pointer;
          display: block;
          font-size: 24px;
          margin: 0 0 0 4px;
        }
      }

      &__split-middle-cell {
        padding-bottom: 0;
        padding-left: 15px;
        padding-top: 0;
        vertical-align: top;
      }

      &__split-last-cell {
        padding-left: 18px;
        padding-top: 0;
        vertical-align: top;
      }
    }

    .split-row {
      border-bottom: 1px solid $light-gray;

      @include table-cell-style-overide;
    }

    .header {
      border-bottom: 1px solid $surface-disabled-ME;
      cursor: pointer;
      width: 100%;

      .header-row {
        &-checkbox.selected {
          visibility: visible;
        }

        &__cell {
          border: none;
          color: $primary;
          height: 38px;
          padding: 8px 16px;
          position: relative;
          vertical-align: middle;

          @include tablet {
            padding: 8px 12px;
          }

          &:first-child {
            padding-left: 8px;
            vertical-align: middle;
          }

          &--rate-tablet {
            padding-left: 0;
            padding-right: 0;
          }
        }

        &__disabled {
          color: $darker-gray;
          cursor: default;
        }

        .amount-cell {
          justify-content: flex-end;
        }

        .header-row__cell__wrapper {
          align-items: center;
          display: flex;
          padding-right: 0;

          &.header-row--no-checkbox {
            margin-left: 60px;
          }

          :global(.MuiTypography-body1) {
            font-weight: 400;
          }

          .header-row__cell__content {
            align-items: center;
            display: flex;
            padding-left: 16px;
            position: relative;

            .sort-icon {
              left: -16px;
              position: absolute;
            }

            :global(.MuiTypography-body1) {
              font-weight: 400;
            }
          }

          .header-row-checkbox {
            padding-bottom: 6px;
          }

          .estimated-rate-icon {
            @media (max-width: 809px) {
              left: 44px;
            }
          }

          .header-row__cell__content:hover {
            color: $on-secondary;

            .sort-icon {
              color: $on-secondary;
              left: -16px;
              position: absolute;
              visibility: visible;

              &:not(.numeric-field-icon) {
                transform: rotate(270deg);
              }

              &.numeric-field-icon {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
  }
}

.placeholder__cell {
  background-color: $hovered-bg-item-color;
  height: 169px;

  @include mobile {
    background-color: transparent;
  }

  .spinner {
    margin: auto;
    text-align: center;
  }

  .error-icon {
    color: $light-gray;
    font-size: 40px;
  }

  .support-link {
    color: $primary;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
  }

  .error-message-title {
    color: $darker-gray;
    font-size: 16px;
    line-height: 22px;
    padding-top: 8px;
  }

  .error-text {
    color: $darker-gray;
    font-size: 14px;
    line-height: 20px;
    padding: 3px 16px 8px;
    text-align: center;
  }
}

.network-error-message {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dialog-warning-icon {
  color: $warning;

  @include desktop-or-tablet {
    font-size: 32px;
  }

  @include mobile {
    font-size: 40px;
  }
}

.dialog-warning-danger {
  color: $error;

  @include desktop-or-tablet {
    font-size: 32px;
  }
}

.status-top-margin {
  margin-top: 20px;
}

.transaction-status {
  align-items: center;
  display: flex;

  @include mobile {
    align-items: center;
  }

  .indicator-icon-recurring {
    font-size: 16px;
    margin-right: 8px;

    @include mobile {
      font-size: 16px;
    }
  }

  .icon-recurring {
    font-size: 16px;
    margin-right: 8px;
    margin-top: 5px;
  }

  .indicator-icon {
    font-size: 10px;
    margin-right: 8px;

    @include mobile {
      font-size: 10px;
    }
  }
}

.table-title {
  padding: 16px 0;

  &--cross-currency {
    padding-bottom: 16px;
    padding-top: 0;
  }

  @include tablet {
    padding: 8px 0;
  }

  &--no-transactions {
    padding-bottom: 0;
  }
}

.cancel-by-bank,
.repair-pending,
.wire-return {
  background-color: $graph-light-red;
  border-left: solid $dark-red 4px;
  border-radius: 4px;
  display: inline-block;
  font-weight: normal;
  margin-right: 24px !important;

  @include tablet {
    margin-bottom: 5px;
  }

  @include mobile {
    background-color: $surface;
    border: none;
    display: flex;
    margin: 5px;
    margin: 0;
  }

  .cancel-by-bank-message,
  .repair-pending-message,
  .wire-return-message {
    font-weight: 400;
    margin: 14px;

    @include mobile {
      font-size: 14px;
    }

    .repair-reason-margin {
      margin-right: 5px;
    }
  }
}

.link {
  color: $black;
  font-weight: 500;
  text-decoration: underline;
}

.transaction-wire {
  color: $error;
}

.transactions-scheduled {
  color: $success;
}

.transactions-process {
  color: $primary;
}

.transactions-pending {
  i {
    display: none;
  }
}

.transactions-cancel {
  color: $utility-info;
}

.pb-10 {
  @include mobile {
    padding-bottom: 10px;
  }
}

.color-emphasized {
  color: $primary;
}

.weight-emphasized {
  font-weight: 600;
}

.weight-light-emphasized {
  font-weight: 500;
}

.tab-description {
  color: $darker-gray;
  font-weight: 600;
  line-height: 24px;
}

.secondary-info {
  color: $darker-gray;
}

.checkbox--light-colored {
  &:global(.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background) {
    border-color: $light-gray;
  }
}

.cell {
  &--compact {
    vertical-align: middle;
    width: 50%;

    &:first-of-type {
      padding: 12px 5px 8px 10px;
    }

    &:last-of-type {
      padding: 12px 8px 8px 5px;
      text-align: right;
    }
  }

  &--align-center {
    align-items: center;
    display: flex;
    width: max-content;
  }

  &--align-end {
    align-items: flex-end;
    display: flex;
  }

  &--align-right {
    text-align: right;

    .transaction-status {
      justify-content: flex-end;
    }
  }
}

.masked-account {
  display: inline-block;
}

.inititated-by {
  font-size: 14px;
  font-weight: 400;
  max-width: 120px;
  overflow: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.initiated-date-time {
  color: #666;
  font-weight: 400;
}

.spaced-left {
  display: inline-block;
  margin-left: 3px;
}

.spaced-bottom {
  padding-bottom: 4px;
}

.pay-from-nickname {
  display: inline-block;
  margin-bottom: -3px;
  width: auto;

  @include overflow-value();

  @include tablet {
    margin-bottom: -5px;
    width: 80px;
  }

  @include tablet-landscape {
    max-width: 200px;
    width: auto;
  }
}

.ref-num-mobile {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.payee-name-2 {
  display: inline-block;
  margin-bottom: -5px;
  max-width: fit-content;

  @include overflow-value();

  @include desktop {
    margin-bottom: -5px;
  }

  @include mobile {
    max-width: 50%;
  }

  @include tablet {
    max-width: 78%;
  }

  @include tablet-landscape {
    max-width: 100%;
  }

  &--tax-payment {
    max-width: 100%;
  }
}

.payee-name {
  display: inline-block;
  margin-bottom: -5px;
  max-width: 68%;

  @include overflow-value();

  @include desktop {
    margin-bottom: -5px;
  }

  @include mobile {
    max-width: 60%;
  }

  @include tablet {
    max-width: 50%;
  }

  @include tablet-landscape {
    max-width: 60%;
  }
}

.payee-nickname {
  display: inline-block;
  margin-bottom: -3px;
  max-width: 94%;

  @include overflow-value();
}

.modified-by {
  margin-left: 16px;

  @include tablet {
    margin-left: 0;
  }
}

.payto-amount-wrapper {
  display: block;
  width: 100%;

  @include mobile {
    display: block;
  }

  @include tablet {
    display: block;
  }

  @include tablet-landscape {
    display: block;
  }
}

.details-field-wrapper {
  display: flex;
  flex-wrap: wrap;

  .details-field {
    display: inline-block;
    width: 50%;

    @include tablet {
      &:nth-of-type(2n + 1) {
        width: calc(50.4% - 17.5px);
      }

      &:nth-of-type(2n) {
        width: calc(49.6% + 17.5px);
      }
    }

    @include tablet-landscape {
      &:nth-of-type(2n + 1) {
        width: calc(54% - 12.5px);
      }

      &:nth-of-type(2n) {
        width: calc(46% + 12.5px);
      }
    }
  }
}

.compact-row {
  border-bottom: 1px solid $light-gray;
  cursor: pointer;

  @include table-cell-style-overide;

  &:nth-child(2n):not(:hover):not(.row--selected):not(.row--expanded) {
    background-color: $surface-striped;
  }

  &__cell {
    background: transparent;

    &:last-child {
      text-align: right;
    }
  }
}

.pending-payment-table--wrapper {
  &:first-child:not(:last-child) {
    margin-bottom: 24px;
  }
}

.message-header {
  margin: 24px 0 16px;
}

.storage-icon {
  color: $light-gray;
  font-size: 43px;
  margin-bottom: 40px;
}

.load-more {
  align-self: center;
  color: $primary;
  margin-top: -16px;
}

.no-data-load-more {
  color: $primary;
  margin-top: 16px;
  text-align: center;
}

.submit-failure-dialog {
  .error-header {
    align-items: center;
  }

  .failure-message {
    font-weight: 400;
  }

  .submit-failure-icon {
    color: $error;
    font-size: 40px;
  }

  .submit-failure-icon-fail {
    color: $error;
    font-size: 40px;
    margin-top: 10px;
  }
}

.submit-failure {
  &-header-fail {
    &:global(.MuiTypography-h6) {
      margin-top: 10px;
    }
  }

  &-header {
    &:global(.MuiTypography-h3) {
      font-weight: 500;
    }
  }

  &-content-text {
    &:global(.MuiTypography-h6) {
      font-weight: 400;
      margin-bottom: 8px;
    }
  }

  &-content-text-fail {
    &:global(.MuiTypography-h6) {
      color: var(--neutrals-grey-900, #212121);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 10px;
      margin-top: -3px;
    }
  }

  &-client-support-center {
    &:global(.MuiTypography-h6) {
      color: $primary;
      cursor: pointer;
      display: inline-flex;
      font-weight: 400;
    }
  }
}

.payment-cancelled {
  &:global(.MuiTypography-h6) {
    color: var(--neutrals-grey-900, #212121);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-bottom: 5px;
    padding-top: 18px;
  }

  &-client-support-center {
    color: $primary;
    cursor: pointer;
    display: inline-flex;
  }
}

.launch {
  bottom: 18px;
  font-size: 24px;
  padding-left: 8px;
}

.title {
  align-items: center;
  display: flex;
  justify-content: center;
}

.no-more-pending-approval {
  &-header {
    &:global(.MuiTypography-h3) {
      font-weight: 500;
    }
  }

  &-content-text {
    &:global(.MuiTypography-h6) {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.deal-payment-label {
  float: right;
  font-size: 14px;
  padding: 7px 0 0;

  &__schedule {
    align-self: right;
    color: $primary;
    font-size: 23px;
    padding: 1px 4px 4px;
    vertical-align: middle;
  }

  @include mobile {
    display: none;
    text-align: left;
  }
}

.est-delivery {
  color: $light-black;
  font-weight: 400;
  margin-top: 8px;

  &--date {
    font-weight: 400;
    margin: 4px 0;
  }
}

.submit-spinner {
  color: $white;
  margin-top: 4px;
  text-align: center;
}

.few-min-msg {
  &:global(.MuiTypography-subtitle2) {
    margin-top: 4px;
  }
}

.row {
  margin-top: 4px;
}

.payfrom-secondary-text {
  color: $darker-gray;
  display: block;
  font-size: 12px;
  font-weight: 400;
}

.secondary-text-width-wrap {
  @include mobile {
    max-width: 150px;
  }
}

.split-expand-icon {
  background-color: $hovered-bg-item-color;
  padding-left: 30px;

  @include desktop {
    &:first-of-type {
      width: 4%;
    }
  }
}

.arrow-icon {
  color: $primary;
  margin-right: 17px;
}

.split-icon-cell {
  display: flex;
}

.split-row-cell {
  margin-right: 85px;
  min-width: 325px;
}

.split-row-cell-2 {
  display: inline-block;
  margin-right: 32px;
  max-width: fit-content;

  @include tablet {
    margin-right: 24px;
    max-width: 100%;
  }

  @include mobile {
    display: block;
    margin-right: 16px;
    max-width: 100%;
  }
}

.split-amount {
  display: inline-block;
  max-width: fit-content;

  @include tablet {
    display: block;
  }

  @include mobile {
    display: block;
    float: left;
  }
}

.split-pay-to-value {
  font-weight: 400;
}

.split-badge {
  background-color: $white;
  border: 1px solid $black;
  border-radius: 100%;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  margin-left: 8px;
  min-width: 20px;

  @include mobile {
    font-size: 12px;
    margin-left: 4px;
    min-width: 16px;
  }
}

.split-payment-badge {
  align-items: center;
  display: flex;
  font-size: 16px;
  margin-right: 16px;
}

.split-transaction-id {
  display: flex;
  flex-direction: column;
}

.split-payment-count {
  font-weight: 500;
  margin-top: 10px;

  @include tablet {
    padding-bottom: 10px;
  }
}

.split-mobile-temp {
  background-color: $hovered-bg-item-color;
  border-bottom: 1px solid $light-gray;
  padding: 16px 10px;
  width: 398px;

  &__fields {
    padding-top: 3px;
  }
}

.text-ellipsis {
  @include overflow-value();
}

.tax-type-code-container {
  display: flex;
  flex-direction: column;
}

.dialog-message-multi-approval-warning,
.dialog-message-multi-reject-warning {
  margin: 10px 0 0 12px;
  padding: 0 10px;
}

.deal-number {
  color: var(--neutrals-grey-900, #212121);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
