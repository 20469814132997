.item-status-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__message {
    align-items: center;
    display: flex;
  }

  &__actions {
    display: flex;

    button {
      margin-left: 8px;
    }
  }

  &__button--borderless {
    border: 0;
  }
}
