@import '~@gateway/components/src/.styles/global';

$shadow: 3px 1px 4px 0 rgba(36, 36, 36, .16);
$expand-shadow: 0 4px 5px 0 rgba(0, 0, 0, .05), 0 1px 10px 0 rgba(0, 0, 0, .05), 0 2px 4px -1px rgba(0, 0, 0, .05);
$navbarWidth: 256px;

.nav-bar-style {
  background: $primary;
  box-shadow: $shadow;
  color: $white;
  height: 100%;
  list-style-type: none;
  margin: 0;
  overflow-y: auto;
  padding: 16px 0 0;
  text-align: start;
  width: $nav-sidebar-width;

  @media (min-width: $nav-sidebar-xl-breakpoint) {
    width: $nav-sidebar-xl-width;
  }

  @include mobile {
    display: none;
    left: 0;
    padding: 0;
    position: fixed;
    width: $navbarWidth;
    z-index: 1;
  }
}

.nav-bar--expanded {
  background: $primary-950;
  box-shadow: $expand-shadow;
  display: flex;
  flex-direction: column;
  width: $navbarWidth;
  z-index: 101;

  @include mobile {
    bottom: 79px;
    height: auto;
    max-height: 500px;
    min-height: 260px;
    overflow-y: auto;
    padding: 0 0 10px;
    top: unset;
    width: 100%;
  }

  .close-button {
    align-self: flex-end;
    cursor: pointer;
    margin: 8px 24px 12px;

    @include mobile {
      display: none;
    }
  }
}

.nav-bar--submenu-enabled {
  @include mobile {
    background: $white;
    height: calc(100% - 98px);
    max-height: none;
    min-height: 0;
    overflow-y: hidden;
    padding-bottom: 0;
    top: 0;
    z-index: 1200;
  }
}

.nav-bar--overlay {
  background-color: $black-light;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.nav-bar--emulated {
  top: 120px;

  @include mobile {
    height: calc(100% - 160px);
    left: 10px;
    right: 10px;
    top: 62px;
    width: auto;
  }
}

.nav-bar-overlay--emulated {
  @include mobile {
    display: none;
  }
}

.nav-bar-header {
  &__close-btn-container {
    align-items: start;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: end;
  }

  &__title {
    align-items: center;
    color: $black;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    justify-content: space-between;
    padding: 28px 16px 16px;

    :global(.MuiSvgIcon-root) {
      height: 16px;
      width: auto;
    }
  }

  &__close-btn:global(.MuiButton-root) {
    &:global(.MuiButton-root) {
      min-width: 0;
      padding: 0;
    }

    &:global(.MuiButton-text) {
      padding: 0;
    }

    :global(.MuiSvgIcon-root) {
      height: 1.5rem;
      width: 1.5rem;
    }

    &:hover {
      background-color: transparent;
    }
  }
}
