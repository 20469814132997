@import '~@gateway/components/src/.styles/global';

.profile-icon-wrapper {
  height: 24px;
  position: relative;
}

.profile-icon {
  color: $primary;
  cursor: pointer;
  height: 24px;
  user-select: none;
}

.notifications-indicator {
  color: $error;
  position: absolute;
  right: 2px;
  top: 0;

  &:global(.material-icons) {
    font-size: 8px;
  }
}
