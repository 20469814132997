@import '~@gateway/components/src/.styles/global';

.cronto-display-container {
  background-color: $white;

  @include mobile {
    height: 100%;
  }

  .title-container {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    vertical-align: middle;
    width: 100%;

    .cronto-icon {
      background-image: url('../mfa-cronto-display/assets/mfa-cronto-icon.svg');
      background-repeat: no-repeat;
      background-size: 50px 50px;
      float: left;
      height: 50px;
      margin-left: -5px;
      padding-right: 10px;
      vertical-align: middle;
      width: 50px;
    }

    .title {
      display: inline-block;
      line-height: normal;
      vertical-align: middle;
    }
  }

  .body {
    height: auto;
    margin: 5px 0;
    padding: 0 0 0 5px;

    .body-text {
      line-height: 1.375rem;
    }

    @include mobile {
      padding: 0;
    }
  }

  .payment-container {
    background-color: $primary-light;
    margin: 12px -16px;
    padding: 8px 24px;

    .to {
      color: $light-black;
      padding-right: 29px;
    }

    .amount {
      color: $light-black;
    }
  }

  .payment-details {
    align-items: center;
    display: flex;
    gap: 8px;
    overflow: auto;
    padding-top: 8px;
  }

  .payment-details-title {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  .refresh-display-container-uk {
    color: $primary;
    left: 415px;
    top: 130px;
    z-index: 1;

    @include mobile {
      align-items: center;
      display: flex;
      justify-content: center;
      left: unset;
      margin: 10px 0 0 14px;
      position: unset;
      top: unset;
    }

    .refresh-button {
      &:global(.mdc-button) {
        height: 15px;
        justify-content: right;
      }
    }
  }

  .cronto-uk {
    display: block;
    height: 252px;
    margin: auto;
    width: 252px;
  }

  .timer-container-uk {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    left: 450px;
    top: 140px;
    z-index: 1;

    @include mobile {
      align-items: center;
      display: flex;
      justify-content: center;
      left: unset;
      margin-top: 10px;
      position: unset;
      top: unset;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;

    .cancel-button {
      width: fit-content;
    }

    .continue-button {
      margin-left: 10px;
      padding: 8px;
      width: fit-content;
    }

    @include mobile {
      justify-content: space-between;
      margin-top: 16px;

      .cancel-button {
        width: 100%;
      }

      .continue-button {
        width: 100%;
      }
    }
  }

  .error-message {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    .error-message-body {
      color: $error;
    }

    @include mobile {
      margin-top: 12px;

      .error-message-body {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
