$z-index-slider: 1500;
$z-index-actions-snackbar: 1000;
$z-spend-limit-popper: 1001;
$z-approvals-footer: 1001;
$z-live-chat: 99;
$z-live-chat-icon: 11;
$z-live-chat-icon-unread: 12;
$z-live-chat-icon-cancel: 12;

$z-dropdown-menu: 1500;

$z-carousel-button: 10;

$z-mobile-cancel-footer: 101;
$z-cancel-footer: 5;

$z-sticky-header: 2;

$z-confirmation-dialog: 1200;

$z-five9-end-chat-popup-backdrop: 100001;
$z-five9-end-chat-popup: 100000;
