@import '~@gateway/components/src/.styles/global';

.notification-card {
  $this: &;

  border-bottom: 1px solid $grey-border;
  padding: 8px 0;

  &__content {
    border-left: 5px solid $blue-border;
    display: grid;
    grid-template:
      'alert-name date'
      'message message';
    padding: 5px 0 0 8px;

    &--read {
      border-left: none;
      padding-left: 13px;
    }

    &--expanded {
      #{$this}__message {
        max-height: unset;
      }
    }
  }

  &__alert-name {
    color: $info;

    @include mobile {
      &:global(.MuiTypography-body2) {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  &__date {
    color: $darker-gray;
    text-align: end;
  }

  &__message {
    --line-height: 1.3rem;
    --max-lines: 2;

    grid-area: message;
    line-height: 20px;
    margin: 5px 0;
    max-height: calc(var(--line-height) * var(--max-lines));
    overflow: hidden;

    :global(.MuiTypography-body2) {
      font-weight: 400;

      @include mobile {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__expand-option {
    box-sizing: border-box;
    color: $primary;
    float: right;
    font-size: 12px;
    font-weight: 500;
    margin: 0 15px 0 10px;
    shape-outside: polygon(30% 85%, 30% 100%, 100% 100%, 100% 85%);
    text-align: end;
    width: 75px;
  }
}
