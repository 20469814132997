@import '~@gateway/components/src/.styles/global';

.mfa-fido-option {
  height: 146px;
  margin: 0 8px;
  width: 146px;
}

.mfa-fido-option-user-switch {
  width: 100%;
}

.mfa-fido-options-wrapper {
  display: flex;
  flex-direction: row wrap;

  .mfa-fido-option {
    height: 146px;
    margin: 0 8px;
    width: 146px;
  }
}
