@import '../../.styles/global.scss';

.select-input {
  &--dropdown {
    position: relative;
  }

  :global(.MuiIconButton-root) {
    padding-right: 2px;
  }

  .dropdown {
    left: 0;
    max-height: 400px;
    overflow-y: auto;
    padding-bottom: 8px;
    width: 100%;
    z-index: 2;

    &__loading {
      padding: 16px 0;
    }

    @include dropdown;
  }

  &__loading {
    color: $darker-gray;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: .01rem;
    line-height: 1.375rem;
    margin-left: 16px;
  }
}
