@import '~@gateway/components/src/.styles/global.scss';

.action-button {
  height: 42px;
  width: 280px;
}

.drawer-content-with-footer {
  background: $surface-dark;
  height: 80%;
  padding: 24px;
}

.contact-us-drawer {
  width: 632px;
}

.drawer-footer {
  align-items: center;
  background: $white;
  bottom: 0;
  box-shadow: $footer-box-shadow;
  box-sizing: border-box;
  display: flex;
  gap: 16px;
  height: 80px;
  padding: 0 20px;
  position: fixed;
  width: 100%;
  z-index: $z-sticky-header;
}
