@import '~@gateway/components/src/.styles/global';

.user-info {
  display: flex;
  flex-direction: column;

  .full-name {
    color: $info;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .user {
    color: $light-black;
    font-weight: 400;
  }

  .user-id {
    color: #212121;
    font-size: 14px;
    font-weight: 400;
  }

  .date {
    color: $darker-gray;
    padding-bottom: 12px;
    padding-top: 8px;
  }

  .user-switch-button {
    color: $primary;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;

    &:hover,
    &:active {
      color: $on-secondary;
    }
  }
}
