@import '../.styles/global.scss';

.appbar {
  $appBar: &;

  margin: 0;

  &:global(.MuiAppBar-colorPrimary) {
    background-color: $white;
    color: $black;
  }

  &.depth0 {
    background-color: $white;
    color: $black;
  }

  &.depth1 {
    background-color: $white;
    color: $primary;
  }

  &.depth2 {
    background-color: $primary-950;
    box-shadow: none;
    color: $white;
  }

  &.depth3 {
    background-color: $white;
    color: $primary-400;
  }

  &.depth4 {
    background-color: $primary-400;
    box-shadow: none;
    color: $white;
  }

  &.depth5 {
    background-color: $darker-gray;
    box-shadow: none;
    color: $white;
  }

  &-tool-bar {
    background-color: inherit;
    justify-content: space-between;

    &:global(.MuiToolbar-regular) {
      min-height: $header-appbar-height;
    }

    &--with-logo {
      &:global(.MuiToolbar-regular) {
        min-height: $header-height;

        @include tablet-portrait-or-mobile {
          min-height: $header-mobile-height;
        }
      }

      @include tablet-portrait-or-mobile {
        #{$appBar}-right {
          margin-top: 45px;
        }
      }
    }

    &:global(.MuiToolbar-gutters) {
      @media (min-width: 472px) {
        padding: 0 16px;
      }
    }
  }

  &-left {
    cursor: pointer;
    display: flex;
    z-index: 1101;
  }

  &-middle {
    left: 64px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 64px;
    text-align: center;
    white-space: nowrap;

    @media (min-width: 472px) {
      left: 72px;
      right: 72px;
    }
  }

  &-right {
    align-items: center;
    cursor: pointer;
    display: flex;
    text-align: center;
    z-index: 1101;
  }

  &-title {
    font-weight: 500;
    user-select: none;
  }
}
