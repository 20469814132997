@import '../.styles/global';

@mixin autocomplete-input-root {
  font-size: 14px;
  height: 48px;
  padding: 5px 9px;

  :global(.MuiFormLabel-root) {
    font-size: 14px;
  }
}

@mixin autocomplete-text-field {
  width: 100%;

  &:global(.MuiFormControl-root) {
    margin-bottom: 16px;
    margin-top: 0;
  }

  :global(.MuiInputLabel-root) {
    font-size: 14px;
  }

  :global(.MuiInputBase-root) {
    font-size: 14px;
  }

  :global(.MuiOutlinedInput-notchedOutline span) {
    padding-right: 10px;
  }
}

.dropdown {
  &__input-root {
    @include autocomplete-input-root;
  }

  &__text-field {
    @include autocomplete-text-field;
  }

  &__option {
    width: 100%;
  }

  &__option-chip {
    float: right;
  }

  &__input-chip {
    margin-right: 55px;
  }
}

.paper {
  &:global(.MuiPaper-root.MuiMenu-paper.MuiPopover-paper) {
    top: 252px;
  }
}

.basic {
  :global(.MuiInputBase-root) {
    color: $info;
    font-size: 14px;
    font-weight: 400;
  }

  :global(.MuiFormLabel-root) {
    color: $placeholder-gray;
    font-size: 16px;
    font-weight: 400;
  }

  &__menu {
    margin-top: 0;
    max-width: unset;
    width: 100%;
  }

  &__menu-item {
    &:global(.MuiMenuItem-root:hover) {
      background-color: $hovered-bg-item-color;
    }
  }
}
