@import '~@gateway/components/src/.styles/global';

.help {
  color: $primary;
  margin-right: 24px;
  padding: 0;

  @include mobile {
    margin-right: 20px;
  }
}
