@import '~@gateway/components/src/.styles/global';

.chat-icon-wrapper {
  z-index: $z-live-chat-icon;
}

.live-chat {
  background-color: $japanese-laurel;
  position: fixed;
  right: 32px;
  z-index: $z-live-chat-icon;

  &:hover {
    background-color: $japanese-laurel;
  }

  &--default {
    bottom: 32px;
  }

  &--position2 {
    bottom: calc(90px + 24px);
  }

  &--position3 {
    bottom: 90px;
  }
}

.live-chat-unread-icon {
  height: 16px;
  min-width: 16px;
  position: absolute;
  right: 40px;

  &:global(.MuiBadge-root) {
    position: fixed;
  }

  :global(.MuiBadge-colorError) {
    background-color: $graph-red;
    z-index: $z-live-chat-icon-unread;
  }

  &--default {
    bottom: 59px;
  }

  &--position2 {
    bottom: 143px;
  }

  &--position3 {
    bottom: 129px;
  }
}

.live-chat-close-icon {
  color: $placeholder-gray;
  cursor: pointer;
  height: 16px;
  min-width: 16px;
  position: absolute;
  right: 78px;
  z-index: $z-live-chat-icon-cancel;

  &--default {
    bottom: 78px;
  }

  &--position2 {
    bottom: 162px;
  }

  &--position3 {
    bottom: 138px;
  }
}

.end-confirmation-container {
  background-color: $black;
  border-radius: 20px;
  bottom: 4px;
  height: 568px;
  opacity: .5;
  position: absolute;
  right: 1px;
  width: 320px;
  z-index: $z-five9-end-chat-popup;
}

.popup-container {
  bottom: 200px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 166px;
  margin: 0 auto;
  min-height: 166px;
  padding: 8px;
  position: absolute;
  right: 16px;
  width: 292px;
  z-index: $z-five9-end-chat-popup-backdrop;

  &:global(.MuiPaper-root.MuiCard-root) {
    overflow: hidden;
  }
}

.title {
  padding: 16px 16px 8px;
}

.subtitle {
  padding: 0 16px;

  &:global(.MuiTypography-subtitle1) {
    line-height: 20px;
  }
}

.action-container {
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: flex-end;
}

.common-button-styles {
  width: min-content;
}
