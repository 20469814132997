@import '~@gateway/components/src/.styles/global';

.contact-us {
  &__contact-us-popper {
    min-width: 362px;
  }

  &__contact-us-link {
    color: $primary;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    padding-right: 25px;
  }

  &__help-section {
    min-width: 362px;

    &__header {
      align-items: center;
      background-color: $primary;
      color: white;
      font-size: 20px;
      font-weight: 400;
      padding: 7.5px;
      text-align: center;
    }

    &__footer {
      background-color: $primary;
      height: 6px;
    }

    &__support_type {
      color: $primary-dark;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
    }

    &__phone {
      color: $text-on-surface-help;
      display: inherit;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
    }

    &__operation {
      &:global(.MuiTypography-subtitle2) {
        margin-top: 8px;
      }
    }

    &__info-section {
      border-bottom: 1px solid #e0e0e0;
      color: black;
      padding: 15px 22px;
      text-align: center;
    }

    &__message-btn {
      margin-top: 12px;
      width: 122px;
    }
  }
}

.icon {
  color: $warning;
  font-size: 40px;
}
