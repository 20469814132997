@import '~@gateway/components/src/.styles/global';

.wrapper {
  width: 100%;
}

.help-label {
  $icon-size: 40px;

  &__label {
    border-bottom: 1px dotted $text-on-surface-disabled;
    cursor: pointer;
  }

  &__tooltip {
    font-family: Roboto, sans-serif;

    &__balloon {
      &:global(.MuiTooltip-tooltip) {
        background-color: $primary;
        border-radius: 7px;
        padding: 16px;
      }

      :global(.MuiTooltip-arrow) {
        color: $primary;
      }
    }
  }

  &__drawer {
    &:global(.MuiPaper-root) {
      background-color: initial;
      box-shadow: none;
      color: $white;
      font-family: Roboto, sans-serif;
      width: auto;
    }
  }

  &__drawer__backdrop {
    &:global(.MuiBackdrop-root) {
      background-color: rgba($backdrop-overlay, .4);
    }
  }

  &__drawer__paper-anchor-bottom {
    $cutout-height: 26px;

    align-items: center;
    background:
      linear-gradient(0deg, $primary calc(100% - #{$cutout-height}), transparent $cutout-height),
      url('~@gateway/components/src/.assets/images/mobile-bottom-drawer-cutout-bg.svg');
    background-position: top calc(#{$icon-size} / 2) center;
    background-repeat: no-repeat;
    font-weight: 300;
    line-height: 22px;
    padding: 0 16px 40px;
  }

  &__drawer__icon {
    align-items: center;
    background: $on-secondary;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: $icon-size;
    justify-content: center;
    margin: 0;
    margin-bottom: 30px;
    width: $icon-size;
  }
}

.content {
  font-weight: 400;
}
