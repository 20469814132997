@import '../../.styles/global.scss';

.form-control {
  box-sizing: border-box;
  padding: 0 26px;
  width: 100%;
}

.form-label :global(.MuiTypography-root) {
  color: $darker-gray;
  font-size: 12px;
}

.form-value {
  &:global(.MuiFormControlLabel-root):not(:first-of-type) {
    margin-left: 30px;
  }

  :global(.MuiTypography-root) {
    font-size: 14px;
    font-weight: normal;
  }

  :global(.MuiIconButton-root):hover {
    background-color: transparent;
  }
}

.radio-bttn-group:global(.MuiFormGroup-root) {
  flex-direction: row;
}
