@import '~@gateway/components/src/.styles/global';

.notifications-list {
  height: 100%;
  left: unset;
  margin-top: 64px;
  position: fixed;
  right: 0;
  width: 322px;
  z-index: 1400;

  @include mobile {
    margin-top: 56px;
  }

  &-content {
    height: calc(100% - 150px);
    overflow: auto;
    padding: 16px;
  }

  :global(.slide-wrapper__header) {
    height: 14px;
  }
}
