@import '../../.styles/global';

.radio-group {
  display: flex;
  width: 100%;
}

.radio-container {
  display: flex;
  width: 50%;

  &_label {
    &:global(.MuiFormControlLabel-label) {
      color: $darker-gray;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.form-control {
  &:global(.MuiFormControl-root),
  &_date-range-root {
    margin-top: 24px;
    width: 100%;
  }

  :global(.MuiFormLabel-root) {
    font-size: 14px;
    font-weight: 400;
    line-height: 8px;
  }
}

.drop-down {
  color: $primary;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  line-height: 16px;
  width: 100%;

  &:global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline) {
    border-color: $primary;
  }

  :global(.MuiSelect-outlined) {
    font-size: 14px;
    font-weight: 400;
  }

  &::after,
  &::before {
    display: none;
  }

  &__icon {
    &:global(.MuiSelect-iconOpen) {
      color: $primary;
    }
  }

  &__select {
    background-color: transparent;
  }

  :global(.MuiSelect-select:focus) {
    background-color: $white;
  }
}

.drop-down__content {
  &:global(.MuiList-padding) {
    background-color: $white;
    border-radius: 4px;
    padding: 8px;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  :global(.MuiListItem-root) {
    border-bottom: 1px solid $light-gray;
    color: $info;
    font-size: 12px;
    font-weight: normal;
    height: 40px;
    line-height: 16px;
    padding: 16px 8px;

    &:last-child {
      border-bottom: none;
    }

    &:global(.Mui-selected) {
      background-color: $selected-item-color;

      &:hover {
        background-color: $hovered-item-color;
      }
    }

    &:global(.MuiListItem-button):hover {
      background-color: $hovered-item-color;
    }
  }
}

.drop-down__poper {
  &:global(.MuiPaper-root) {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .05), 0 1px 10px 0 rgba(0, 0, 0, .05), 0 2px 4px -1px rgba(0, 0, 0, .05);
  }
}
