@import '~@gateway/components/src/.styles/global';

@mixin tablet-screen {
  height: auto;
}

.biometric-notification-container {
  background-color: $white;

  .title-container {
    display: inline-block;
    height: 50px;
    line-height: 45px;
    margin-bottom: 10px;
    vertical-align: middle;
    width: 100%;

    .push-icon {
      background-image: url('../mfa-auth-option/assets/mfa-push-icon.svg');
      background-repeat: no-repeat;
      background-size: 50px 50px;
      float: left;
      height: 50px;
      margin-left: -5px;
      padding-right: 10px;
      vertical-align: middle;
      width: 50px;
    }

    .title {
      display: inline-block;
      font-size: 20px;
      line-height: normal;
      vertical-align: middle;
    }
  }

  .body {
    height: auto;
    margin: 5px 0;
    padding: 0 0 0 5px;

    .body-text {
      line-height: 1.375rem;
    }

    @include mobile {
      padding: 0;
    }
  }

  .body2 {
    align-items: baseline;
    display: flex;
  }

  .body3 {
    margin-bottom: 10px;
  }

  .caption-text {
    color: $neutral-666;
  }

  .payment-container {
    background-color: $primary-light;
    margin: 8px 0 24px;
    padding: 8px 24px;

    .to {
      color: $light-black;
      padding-right: 29px;
    }

    .amount {
      color: $light-black;
    }
  }

  .payment-details {
    align-items: center;
    display: flex;
    gap: 8px;
    overflow: auto;
    padding-top: 8px;
  }

  .payment-details-title {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  .request-link {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin-left: 4px;
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    .cancel-button {
      width: fit-content;
    }

    .continue-button {
      margin-left: 10px;
      padding: 8px;
      width: fit-content;
    }

    .resend-button {
      margin-left: 10px;
      padding: 8px;
      width: fit-content;
    }

    @include mobile {
      justify-content: flex-end;

      .cancel-button {
        width: 100%;
      }

      .resend-button {
        width: 100%;
      }

      .continue-button {
        width: 100%;
      }
    }
  }
}

.pending-message {
  display: flex;
  justify-content: flex-end;

  .pending-message-body {
    color: $error;
    font-size: 12px;
    font-weight: 400;
  }
}

.timer-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}
