@import '../.styles/global.scss';

.data-table-head {
  &__spinner {
    padding: 0 5px;
  }

  &:global(.MuiTableCell-head) {
    color: $primary;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 10px;
    vertical-align: middle;
  }

  :global(.MuiTableSortLabel-root) {
    &:hover {
      color: $primary;
    }

    &:active {
      color: $primary;
    }

    &:global(.MuiTableSortLabel-active) {
      color: $primary;

      &:global(.MuiTableSortLabel-root) {
        &:global(.MuiTableSortLabel-active) {
          :global(.MuiTableSortLabel-icon) {
            color: $primary;
          }
        }
      }
    }
  }
}
