@use 'sass:math';

@import './variables';

// returns the lower grid boundary or null if the smallest grid is selected
@function mdc-layout-grid-breakpoint-min($size) {
  @if not map-has-key($mdc-layout-grid-columns, $size) {
    @error 'Invalid style specified! Choose one of #{map-keys($mdc-layout-grid-columns)}';
  }
  $min: map-get($mdc-layout-grid-breakpoints, $size);

  @return if($min > 0, $min, null);
}

// returns the upper grid boundary or null if the largest grid is selected
@function mdc-layout-grid-breakpoint-max($size) {
  @if not map-has-key($mdc-layout-grid-columns, $size) {
    @error 'Invalid style specified! Choose one of #{map-keys($mdc-layout-grid-columns)}';
  }
  $names: map-keys($mdc-layout-grid-columns);
  $n: index($names, $size);
  $prev: if($n > 1, nth($names, $n - 1), null);

  @return if($prev, (mdc-layout-grid-breakpoint-min($prev) - 1px), null);
}

// Private mixins, meant for internal use.
@mixin mdc-layout-grid-media-query_($size) {
  @if not map-has-key($mdc-layout-grid-columns, $size) {
    @error 'Invalid style specified! Choose one of #{map-keys($mdc-layout-grid-columns)}';
  }

  $min: mdc-layout-grid-breakpoint-min($size);
  $max: mdc-layout-grid-breakpoint-max($size);

  @if $min == null and $max != null {
    // Phone
    @media (max-width: $max) {
      @content;
    }
  }

  @else if $min != null and $max != null {
    // Tablet
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  }

  @else if $min != null and $max == null {
    // Desktop
    @media (min-width: $min) {
      @content;
    }
  }

  @else {
    // Fallback - no breakpoints defined
    @content;
  }
}

@mixin mdc-layout-grid-cell-span_($size, $span, $gutter) {
  @if not map-has-key($mdc-layout-grid-columns, $size) {
    @error 'Invalid style specified! Choose one of #{map-keys($mdc-layout-grid-columns)}';
  }

  $percent: math.percentage(
    math.div($span, map-get($mdc-layout-grid-columns, $size))
  );

  @if $percent > 100% {
    $percent: 100%;
  }

  width: calc(#{$percent} - #{$gutter});
  width: calc(#{$percent} - var(--mdc-layout-grid-gutter-#{$size}, #{$gutter}));

  @supports (display: grid) {
    grid-column-end: span min($span, map-get($mdc-layout-grid-columns, $size));
    width: auto;
  }
}

// Public mixins, meant for developer usage.
@mixin mdc-layout-grid($size, $margin, $max-width: null) {
  @if not map-has-key($mdc-layout-grid-columns, $size) {
    @error 'Invalid style specified! Choose one of #{map-keys($mdc-layout-grid-columns)}';
  }

  box-sizing: border-box;
  margin: 0 auto;
  padding: $margin;
  padding: var(--mdc-layout-grid-margin - #{$size}, #{$margin});

  @if $max-width {
    max-width: $max-width;
  }
}

@mixin mdc-layout-grid-inner($size, $margin, $gutter) {
  @if not map-has-key($mdc-layout-grid-columns, $size) {
    @error 'Invalid style specified! Choose one of #{map-keys($mdc-layout-grid-columns)}';
  }

  align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  margin: calc(-1 * $gutter / 2);
  margin: calc(-1 / 2 * var(--mdc-layout-grid-gutter - #{$size}, #{$gutter}));

  @supports (display: grid) {
    display: grid;
    grid-gap: $gutter;
    grid-gap: var(--mdc-layout-grid-gutter - #{$size}, $gutter);
    grid-template-columns: repeat(map-get($mdc-layout-grid-columns, $size), minmax(0, 1fr));
    margin: 0;
  }
}

@mixin mdc-layout-grid-cell($size, $default-span, $gutter) {
  @if not map-has-key($mdc-layout-grid-columns, $size) {
    @error 'Invalid style specified! Choose one of #{map-keys($mdc-layout-grid-columns)}';
  }

  @include mdc-layout-grid-cell-span_($size, $default-span, $gutter);

  box-sizing: border-box;
  margin: calc($gutter / 2);
  margin: calc(var(--mdc-layout-grid-gutter-#{$size}, #{$gutter}) / 2);

  @supports (display: grid) {
    margin: 0;
  }
}

@mixin mdc-layout-grid-cell-order($order) {
  order: $order;
}

@mixin mdc-layout-grid-cell-align($position) {
  @if $position == 'top' {
    align-self: flex-start;

    @supports (display: grid) {
      align-self: start;
    }
  }

  @if $position == 'middle' {
    align-self: center;
  }

  @if $position == 'bottom' {
    align-self: flex-end;

    @supports (display: grid) {
      align-self: end;
    }
  }

  @if $position == 'stretch' {
    align-self: stretch;
  }
}

@mixin mdc-layout-grid-fixed-column-width($size, $margin, $gutter, $column-width) {
  @if not map-has-key($mdc-layout-grid-columns, $size) {
    @error 'Invalid style specified! Choose one of #{map-keys($mdc-layout-grid-columns)}';
  }

  $columnCount: map-get($mdc-layout-grid-columns, $size);
  $gutter-number: $columnCount - 1;
  $margin-number: 2;

  width: $column-width * $columnCount + $gutter * $gutter-number + $margin * $margin-number;
  width:
    calc(
      var(--mdc-layout-grid-column-width-#{$size}, #{$column-width}) * #{$columnCount} +
      var(--mdc-layout-grid-gutter-#{$size}, #{$gutter}) * #{$gutter-number} +
      var(--mdc-layout-grid-margin-#{$size}, #{$margin}) * #{$margin-number}
    );
}
