@import '~@gateway/components/src/.styles/global.scss';

.icon {
  color: $warning;
  font-size: 40px;
  position: relative;
  top: 5px;
}

.chat-icon-wrapper {
  z-index: $z-live-chat-icon;
}
