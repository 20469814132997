.exchange-rate-converter {
  &--label {
    align-items: center;
    display: flex;

    .icon {
      cursor: pointer;
      padding: 0 8px;
    }
  }

  &--position {
    position: relative;
    top: 20px;
  }
}

.rate-label-split-inline {
  font-size: .875rem !important;
  font-weight: 500 !important;
  padding-bottom: 5px;
}

.compare-arrows {
  transform: scaleX(1);
}

.compare-arrows-reversed {
  transform: scaleX(-1);
}
