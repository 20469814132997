@import '../../.styles/global.scss';

.multi-select-listbox {
  .multi-select-option {
    border-bottom: 1px solid $light-gray;
    color: $info;
    display: inherit;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    margin: 0;
    overflow: hidden;
    padding: 10px 16px;
    text-overflow: ellipsis;

    .autocomplete-option--text {
      white-space: nowrap;
    }

    &:hover {
      background-color: $hovered-faded-bg-color;
      cursor: pointer;
    }

    &[aria-selected='true'] {
      background-color: $hovered-faded-bg-color;
      cursor: pointer;

      .autocomplete-option--text {
        font-weight: 500;
      }
    }

    &[data-focus='true']:not([aria-selected='true']) {
      background-color: $hovered-faded-bg-color;
      cursor: pointer;
    }

    &:last-child {
      border: none;
    }

    :global(.MuiCheckbox-root) {
      color: $light-gray;
      padding: 0 8px 0 0;
    }

    :global(.MuiCheckbox-colorSecondary.Mui-checked) {
      color: $primary;
    }
  }
}

.pipe-separator {
  color: #757575;
  padding-left: 3px;
  padding-right: 3px;
}

.container {
  .autocomplete {
    &-root {
      .input-label {
        font-family: Roboto, sans-serif;
        font-size: .875rem;
        font-weight: 500;
        letter-spacing: .01rem;
        line-height: 1.25rem;
        pointer-events: none;
        z-index: 1;

        &:global(.MuiInputLabel-shrink) {
          transform: translate(14px, -6px) scale(.75);
        }
      }

      .input-root {
        height: auto;
        padding: 5px 9px;

        .input {
          font-size: 14px;
          font-weight: 400;
          padding: 5px 4px;
        }

        .end-adornment {
          .dropdown-icon__open {
            color: $primary;
          }
        }
      }
    }
  }

  .filter-chip {
    font-weight: 500;
    height: 24px;
  }
}
