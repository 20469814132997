@import '~@gateway/components/src/.styles/global';

.trailingIconToolTip {
  align-items: center;
  background: $primary;
  border-radius: 3px;
  color: $white;
  display: flex;
  height: auto;
  justify-content: center;
  margin: 10px -28px 0 0;
  text-align: center;
  width: 100%;

  &__noBorder {
    border: none;
    color: $error;
    display: flex;
  }
}
