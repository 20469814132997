@import '../.styles/global.scss';

.checkbox-form-control-label-root {
  .checkbox-root {
    :global(.MuiSvgIcon-root) {
      color: $placeholder-gray;
    }

    &:global(.Mui-checked) {
      :global(.MuiSvgIcon-root) {
        color: $primary;
      }
    }

    &:global(.Mui-disabled) {
      :global(.MuiSvgIcon-root) {
        color: $light-gray;
      }
    }

    &:global(.MuiCheckbox-indeterminate) {
      :global(.MuiSvgIcon-root) {
        color: $primary;
      }

      &:global(.Mui-disabled) {
        :global(.MuiSvgIcon-root) {
          color: $placeholder-gray;
        }
      }
    }
  }
}
