@import '../.styles/global';

.feedback {
  &__icon {
    color: $primary;
    cursor: pointer;
    height: 24px;
    margin-right: 24px;
    padding: 0;
    user-select: none;

    @include mobile {
      margin-right: 20px;
    }
  }

  &__label {
    color: $primary;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    padding-right: 12px;
  }
}
