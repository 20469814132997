@import '../../.styles/global.scss';

.select-input {
  :global {
    .mdc-text-field {
      height: 48px;

      &__input,
      .mdc-floating-label,
      &--outlined .mdc-floating-label--float-above {
        font-size: 14px;
      }

      &__input,
      .mdc-floating-label {
        top: 15px;
      }

      &--with-trailing-icon .mdc-text-field__icon {
        bottom: 12px;
        z-index: 0;
      }
    }
  }

  .slider {
    display: flex;
    flex-direction: column;
    height: 100%;

    .slider__content {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      padding: 0;
    }

    .content-container {
      height: 100%;
      overflow-y: auto;
      padding: 32px 24px;
    }

    .title-container {
      padding-bottom: 32px;

      &__title-item {
        font-weight: normal;

        &:nth-child(2) {
          font-size: 20px;
        }
      }
    }

    .buttons-container {
      align-items: flex-end;
      background-color: $white;
      bottom: 0;
      box-shadow: 0 -1px 6px 0 $box-shadow;
      box-sizing: border-box;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      min-height: 110px;
      padding: 24px 24px 40px;

      @include tablet {
        width: 472px;
      }

      @include mobile {
        width: 100vw;
      }

      &__wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      &__button {
        flex-basis: 48%;
        flex-grow: 1;
        height: auto;
        max-width: 48%;
        padding: 14px;

        &:first-child {
          margin-right: 24px;
        }

        & :global(.mdc-button__label) {
          letter-spacing: normal;
        }
      }
    }
  }
}
