@import '~@gateway/components/src/.styles/global';

.switch-dialog {
  padding: 16px;

  &:global(.mdc-dialog) {
    /* to override drawer component z-index */
    height: 80%;
    overflow: auto;
    top: 10%;
    z-index: 12;
  }
}

.close-div {
  cursor: pointer;
  padding-right: 8px;
  padding-top: 8px;
  position: absolute;
  right: 0;
}

.title {
  padding-bottom: 14px;
  padding-top: 24px;
}

.body {
  padding-bottom: 8px;
}

.user-info {
  padding-bottom: 8px;
}

.user {
  padding-bottom: 16px;
}

.user-span {
  font-weight: 400;
  padding-right: 45px;
}

.company-span {
  font-weight: 400;
}

.company-user-id {
  color: black;
  font-size: 14px;
  font-weight: 400;
}

.divider {
  border-top: 1px solid lightgray;
  padding-bottom: 16px;
  width: 97%;
}

.button-div {
  display: flex;
  justify-content: flex-end;
  padding: 0 8px 8px 0;
}

.add-button {
  background: #fff !important;
  border: 2px solid #e0e0e0 !important;
  border-radius: 4px !important;
  box-sizing: border-box !important;
  color: #757575 !important;
  width: fit-content;
}

.user-switch-div {
  display: flex;
  flex-direction: column;
  padding: 8px 0 16px 30px;
  width: 570px;
}

.content-div {
  padding-right: 16px;
}

.box-div {
  padding-bottom: 16px;
}

.box {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .05), 0 1px 10px 0 rgba(0, 0, 0, .05), 0 4px 5px 0 rgba(0, 0, 0, .05);
  cursor: pointer;
  margin-bottom: 16px;
  padding: 16px 16px 8px;
  width: 90%;
}

.box:hover {
  background-color: rgba(31, 141, 193, .15);
}

.box:active {
  background-color: rgba(31, 141, 193, .3);
}

.spinner {
  margin: auto;
  position: relative;
}

.empty-list {
  padding-bottom: 16px;
  padding-top: 16px;
  text-align: center;
}

.empty-list-icon {
  color: #e0e0e0;
  font-size: 40px !important;
}

.empty-list-content {
  color: #757575;
}

.empty-list-content-title {
  color: #757575;
  padding-bottom: 8px;
  padding-top: 8px;
}

.empty-list-learn-more {
  color: var(--primary-primary-500, #007db8);
  cursor: pointer;
}
