@import '~@gateway/components/src/.styles/global';

@mixin tablet-screen {
  height: auto;
}

.mfa-dialog {
  @include mobile {
    height: 100%;
    width: 100%;
  }

  /* stylelint-disable declaration-no-important */
  z-index: 1500 !important;

  :global(.MuiDialog-paperFullScreen) {
    align-items: center;
    display: grid;
    margin: 7px;
    max-height: 96%;
  }
}

.dialog-container {
  background: $white;
  height: auto;
  padding: 24px 10px 10px 24px;
  position: relative;
  width: 536px;

  @include mobile {
    height: 100%;
    padding: 24px;
    width: 100%;
  }

  @include tablet {
    padding: 24px;
  }
}

.disabled-title-container {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.close-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.close-icon-container {
  display: flex;
  justify-content: flex-end;

  &-icon {
    cursor: pointer;
    display: flex;
    filter: saturate(100%) brightness(0%);
  }
}

.biometric-notification-container {
  background-color: $white;

  .title {
    color: $info;
    font-size: 24px;
    line-height: 34px;
    margin: 0 0 15px;
    padding-left: .5rem;
  }

  .title-cas {
    color: $info;
    font-size: 20px;
    line-height: 20px;
    margin: 0 0 15px;
  }

  .title-cas-disabled {
    color: $info;
    font-size: 20px;
    line-height: 20px;
    margin: 0 5px;
  }

  .body-copy-cas {
    color: $info;
    font-size: 16px;
    line-height: 21px;
    margin: 0 0 5px;

    &__container {
      margin-left: 10px;
    }

    &__text {
      font-size: 15px;
      font-weight: 400;
    }

    &__text1 {
      font-size: 15px;
      font-weight: 400;
      margin: 0 0 35px;
    }
  }

  .body-copy {
    color: $info;
    font-size: 13px;
    line-height: 20px;
    padding-left: .5rem;
    width: 90%;

    &__text {
      font-weight: 400;
      margin-bottom: 14px;
    }

    &__text1 {
      font-weight: 400;
    }

    &__text2 {
      font-weight: 400;
    }
  }

  .request-link {
    cursor: pointer;
    font-size: 16px;

    @include mobile {
      font-weight: 400;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;

    .close-button {
      background-color: $primary;
      color: $white;
      margin-top: 10px;
      width: fit-content;
    }

    @include mobile {
      justify-content: flex-end;

      .close-button {
        width: 100%;
      }
    }
  }
}

.pending-message {
  display: flex;
  justify-content: flex-end;

  .pending-message-body {
    color: $error;
    font-size: 12px;
    font-weight: 400;
  }
}

.timer-container {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 200px;
}
