@import '~@gateway/components/src/.styles/global';

.auth-challenge-title-container {
  @include mobile {
    margin: 24px 0 0 8px;
  }

  .auth-challenge-title {
    @include mobile {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.auth-challenge-title-container-status-change {
  .error-icon {
    color: $error;
    font-size: 40px;
    margin-right: 11px;
    vertical-align: middle;
  }

  .auth-challenge-title-status-change {
    display: inline;
    vertical-align: middle;

    @include mobile {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .auth-challenge-message-status-change {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 20px 0 8px;
  }
}

.auth-challenge-grid {
  padding: 0;

  :global(.mdc-layout-grid__inner) {
    grid-gap: 0;
    justify-content: center;
  }

  .auth-options-container-layout1 {
    margin: 16px 0 35px;
  }

  .auth-options-container-layout2 {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 16px 0 40px;
  }

  .auth-options-container-layout3 {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 16px 0 40px;
    row-gap: 16px;
  }

  .auth-challenge-footer-container-layout1 {
    margin: 20px 0 35px 32px;

    @include tablet-portrait {
      margin: 20px 0 5px;
    }
  }

  .auth-challenge-disclaimer-container {
    margin: 0 0 10px;
  }

  .auto-dial-intro {
    color: $dim-gray;
    display: inline;

    @include mobile {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .auto-dial-more {
    color: $dim-gray;
    display: inline;

    @include mobile {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .cronto-push {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    @include mobile {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .security-key {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    @include mobile {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .collapse-toggler {
    cursor: pointer;
    margin-left: 5px;

    @include mobile {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .contact-us-container {
    margin-bottom: 16px;

    .contact-us-label {
      color: $black;
      display: inline-block;
      font-size: 16px;
      line-height: 22px;

      @include mobile {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .customer-support-link {
      color: $primary;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-left: 5px;

      @include mobile {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .sms-link-container {
    margin-bottom: 4px;

    .sms-label {
      color: $black;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 4px;

      @include mobile {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .sms-link {
      color: $primary;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}
