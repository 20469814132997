@import '~@gateway/components/src/.styles/global';

.auth-option-container {
  height: 100%;
  width: 100%;

  .auth-option-card {
    border-radius: 8px;
    height: 100%;
    width: 100%;
  }

  .auth-option-card-action-area {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    &--disabled {
      align-items: center;
      color: #757575;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }

    &--row-layout {
      display: flex;
      flex-direction: row;
      justify-content: start;
      justify-self: start;
      overflow: hidden;
      padding: 5px 0;
    }
  }

  .auth-option-card-action-area:hover {
    background-color: $primary-10;
  }

  .ripple {
    color: $ripple-blue;
  }

  .auth-option-image {
    align-items: center;
    background-repeat: no-repeat;
    background-size: 50px;
    height: 50px;
    margin-top: 10%;
    width: 50px;

    &--row-layout {
      background-repeat: no-repeat;
      background-size: 50px;
      height: 50px;
      margin-left: 24px;
      padding-left: 12px;
      width: 50px;
    }
  }

  .auth-option-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 5px;

    &--row-layout {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .auth-option-title {
    display: inline-block;
    margin: auto;
    white-space: nowrap;

    @include mobile {
      font-size: 1rem;
    }
  }

  .auth-option-description {
    white-space: nowrap;
  }

  .auth-option-description-fido-info {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
}
