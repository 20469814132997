@import '../../.styles/global';

.toolbar-btn-disabled {
  pointer-events: none;
}

.toolbar-btn-selected {
  background-color: $neutral-400;
  border-bottom: 3px solid $primary;
}

.toolbar-btn-border {
  &:global(.MuiPickersToolbarButton-toolbarBtn:last-child) {
    border-left: 1px solid $placeholder-gray;
  }
}
