@import '~@gateway/components/src/.styles/global';

.manage-login-settings {
  height: 100%;
  left: unset;
  margin-top: 56px;
  overflow-y: auto;
  position: fixed;
  right: 0;
  width: 322px;
  z-index: $z-index-slider;

  :global(.slide-wrapper__header) {
    height: 14px;
  }

  &-content {
    height: calc(100% - 150px);
    padding: 16px;
  }

  &-switch {
    margin-right: 0;
    padding-left: 15px;

    :global(.MuiFormControlLabel-root) {
      margin-right: 0;
    }
  }

  &-disabled {
    pointer-events: none;
  }

  &-container-disabled {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    :global(.MuiSwitch-switchBase .MuiSwitch-thumb) {
      background-color: $alto-gray;
    }

    :global(.switch_switch__1D7Ul .MuiSwitch-root .MuiSwitch-colorSecondary:not(.Mui-disabled) + .MuiSwitch-track) {
      background-color: $placeholder-gray;
      opacity: 1;
    }
  }

  &-container-enabled {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  &-label {
    font-size: 16.5px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
  }

  &-title-label-padding {
    padding: 10px 0 5px;
  }

  &-warning-red {
    color: $error;
  }

  &-user-preference-container {
    margin-bottom: 20px;
  }

  &-link-break {
    border-top: 1px solid rgba($grey-border, .38);
  }

  &-message-container {
    align-items: flex-start;
    display: flex;
    margin-top: 10px;

    .warning {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &-warning-icon {
    color: $warning;
    padding-right: 5px;
    padding-top: 4px;
  }
}
