@import '~@gateway/components/src/.styles/global';

.manage-direct-connect-pin {
  height: 100%;
  left: unset;
  margin-top: 56px;
  overflow-y: auto;
  position: fixed;
  right: 0;
  width: 325px;
  z-index: 1400;

  &-content {
    height: calc(100% - 150px);
    overflow: auto;
    padding: 16px;
  }

  &_header {
    padding-bottom: 16px;
  }

  &_pin-info {
    color: $secondary-650;
    padding-bottom: 41px;

    @include mobile {
      padding-bottom: 30px;
    }
  }

  &_pin-rule {
    /* stylelint-disable declaration-no-important */
    line-height: 20px !important;
    padding-bottom: 24px;
  }

  &_generate-button {
    margin-bottom: 24px;
  }

  &_invalidate-content {
    border-top: 1px solid rgba($grey-border, .38);
    padding-top: 25px;

    &_header {
      padding-bottom: 8px;
    }

    &_message {
      color: $secondary-650;
      padding-bottom: 16px;
    }

    &_button {
      background: #fff !important;
      border: 2px solid #007db8 !important;
      border-radius: 4px !important;
      box-sizing: border-box !important;
      color: $primary !important;
    }

    &_error-message {
      align-items: center;
      clear: both;
      color: $graph-red;
      display: flex;
      float: right;
    }

    &_icon {
      color: $graph-red;
      margin-right: 3px;

      &:global(.material-icons) {
        font-size: 16px;
      }
    }
  }

  &_save-content {
    margin-bottom: 24px;

    &_pin {
      align-items: center;
      border-bottom: 2px solid $primary;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 24px;
      padding-bottom: 8px;
    }

    &_pin_container {
      padding-right: 16px;
    }

    &_pin_v2 {
      align-items: center;
      border-bottom: 2px solid #757575;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    &_text {
      min-width: 267px;

      &:global(.MuiTypography-body1) {
        font-size: 14px;
      }
    }

    &_icon {
      color: $primary;
      margin-left: 5px;

      &:global(.material-icons) {
        cursor: pointer;
        font-size: 24px;
      }
    }

    &_icon_v2 {
      color: $primary;

      &:global(.material-icons) {
        cursor: pointer;
        font-size: 30px;
      }
    }

    &_save_button_v2 {
      padding: 0;
    }

    &_close_btn {
      background-color: $white;
      color: #212121;
      cursor: pointer;
      height: 20px;
      margin: 8px 8px 0 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 24px;
      z-index: 1;
    }
  }

  :global(.slide-wrapper__header) {
    height: 14px;
  }
}

.save-dialog-message {
  z-index: 1500 !important;
}
