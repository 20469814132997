@import '~@gateway/components/src/.styles/global';

.item {
  &__status-bar {
    align-items: center;
    background-color: $neutral-400;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    min-height: 46px;
    padding: 0 8px;
  }

  &__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 44px;
    padding: 0 8px;
  }

  &__label {
    font-size: 14px;
    line-height: 23px;
  }
}
