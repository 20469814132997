@import '../.styles/global';

.radio {
  &:global(.MuiRadio-root) {
    color: $light-gray;
  }
}

.checked {
  &:global(.MuiRadio-root) {
    color: $primary;
  }
}
