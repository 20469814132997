@import '../.styles/global';

.error-panel {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 0;

  :global(.rmwc-icon) {
    color: $light-gray;
    font-size: 72px;

    @include mobile {
      font-size: 40px;
    }
  }

  .message {
    color: $darker-gray;
    font-size: 20px;
    font-weight: 500;
    margin: 16px 0;
    padding: 0;

    @include mobile {
      font-size: 16px;
      margin: 8px 0;
    }
  }

  .message-secondary {
    color: $darker-gray;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    margin: 0;
    padding: 0;
    text-align: center;

    @include mobile {
      font-size: 14px;
    }
  }

  .support-message {
    color: $dim-gray;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    margin-top: 8px;
    padding: 0;
    text-align: center;

    @include mobile {
      font-size: 14px;
    }

    .link {
      color: $primary-700;
      cursor: pointer;
      margin-left: .3em;
    }
  }

  .refresh-action {
    border: none;
    color: $primary;
    margin-top: 16px;

    @include mobile {
      margin-top: 8px;
    }

    :global(.mdc-button__label) {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    :global(.rmwc-icon) {
      color: $primary;
      font-size: 18px;
      font-weight: 500;
    }

    &:hover {
      color: $on-secondary;

      :global(.rmwc-icon) {
        color: $on-secondary;
      }
    }
  }
}
