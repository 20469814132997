@import '~@gateway/components/src/.styles/global';

.popup {
  @include mobile {
    :global(.dialog-popup_dialog-popup__header__1_miu) {
      flex-direction: row;
    }

    :global(.dialog-popup_text__3gAsZ) {
      font-weight: 600;
      margin-top: 6px;
    }
  }
}

.icon {
  color: $info;
  font-size: 40px;
}

.warning {
  color: $warning;
}
