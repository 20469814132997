@import '~@gateway/components/src/.styles/global';

.live-chat-popup-content {
  &__info-section {
    border-bottom: 1px solid #e0e0e0;
    color: black;
    padding: 15px 22px;
    text-align: center;
  }

  &__live-chat-header {
    color: $primary-dark;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
  }

  &__live-chat-subheader {
    color: $text-on-surface-help;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
  }

  &__live-chat-button {
    text-align: center;

    &:global(.MuiButtonBase-root) {
      background-color: $japanese-laurel;
      border: none;
      color: $white;

      &:hover {
        background-color: $japanese-laurel;
      }
    }
  }
}
