@import '~@gateway/components/src/.styles/global.scss';

.input-box-container {
  left: 10px;
  margin-top: 16px;
  max-width: 67%;
  padding-bottom: 24px;
  position: relative;
  white-space: pre-line;

  @include mobile {
    max-width: 100%;
  }

  @include tablet {
    max-width: 62%;
  }

  @include tablet-portrait {
    max-width: 98%;
  }
}

.input-box-inner-container {
  margin: 0 24px;
}

.title-input {
  border-bottom: solid 1px $alto-gray;
  padding: 16px 0 24px;
}

.button-container-message-reply {
  display: flex;
  justify-content: flex-end;
  padding: 24px 0;
}

.buttons {
  height: 30px;
  width: 108px;
}

.date {
  padding: 24px 0 8px;
}

.container-for-drawer {
  left: 0;
  min-width: 100%;
  white-space: pre-line;
}

.title-padding {
  padding: 24px 16px;
}

.input-padding {
  padding: 0 16px;
}
