@import '~@gateway/components/src/.styles/global';
@import '../../../styles/mixins';
@import '../../../styles/common';

.dialog {
  @include desktop-or-tablet {
    margin-left: 62px;
  }

  .message {
    font-weight: 400;
  }
}

.icon {
  color: $error;

  @include dialog-icon;
}

.root {
  background-color: $surface-disabled;
  min-height: calc(100vh - 275px);

  article {
    background: #fff;

    /* Neutrals/Light Grey */
    border: 1px solid #e0e0e0;

    /* Elevation - Cards */
    border-radius: 4px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, .05), 0 1px 10px rgba(0, 0, 0, .05), 0 2px 4px rgba(0, 0, 0, .05);
    margin-bottom: 32px;
    padding: 8px 24px 24px;

    @include mobile {
      margin: 0 -16px 24px;
    }
  }

  .details-article {
    :global(.MuiTypography-root) {
      word-break: normal;
    }
  }

  .details-divider {
    border-color: $divider-bg-color;
  }

  .details-grid {
    grid-gap: 24px;
    padding-top: 24px;
  }

  section[class^='collapsable-details_details__field'] {
    margin: 0 0 12px;
  }

  .details-header {
    color: $black;
  }

  .grid-item-content {
    grid-column: 1/13;

    &:global(.MuiTypography-root),
    :global(.MuiTypography-body1),
    :global(.MuiTypography-body2) {
      font-weight: 400;
    }
  }

  .verification-buttons {
    grid-template-columns: repeat(12, minmax(0, 1fr));

    :global(.mdc-button) {
      border-color: $primary;
      color: $white;
      height: 42px;
      padding: 11px 8px;
      width: 200px;

      @include mobile {
        width: 100%;
      }
    }

    :global(.mdc-button#cancel-action-button) {
      color: $primary;

      &:disabled {
        color: $primary-light;
      }

      @include mobile {
        border: 2px solid $primary;
        margin-top: 16px;
      }
    }

    :global(.mdc-button#continue-button):disabled {
      background-color: $light-gray;
      border-color: $light-gray;
      color: $surface-disabled-ME;
    }

    @include mobile {
      padding-bottom: 18px;
    }
  }

  .cut-off-time-label {
    color: $info;
    margin-top: 25px;

    @include desktop-or-tablet {
      text-align: center;
    }
  }

  .list-items {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .error-message {
    color: $error;
    padding: 10px 0;
  }

  .fx-fee--info {
    word-break: break-word;
  }

  .spinner-root {
    background-color: $black;
    bottom: 0;
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100vw;

    .spinner {
      text-align: center;
    }
  }

  .title {
    color: $black;

    @include desktop-or-tablet {
      padding: 8px 16px 32px 0;
    }

    @include mobile {
      padding: 8px 0 24px;
    }
  }
}

.loading-label {
  color: $white;
  padding-top: 24px;
  text-align: center;
}

.masked-account {
  display: inline;
}

.pay-from-nickname {
  display: inline;
  overflow: hidden;
  word-break: break-all;

  @include desktop {
    max-width: 75%;
  }
}

.send-as {
  word-break: break-word;
}

.delivery-info-wrapper {
  .delivery-info {
    bottom: 3px;
    position: relative;
  }

  .info-icon {
    color: $primary;
    cursor: pointer;
    font-size: 20px;
    margin-left: 4px;
    position: relative;
    top: 2px;
    width: 38px;
  }
}

.field__tooltip--popper {
  .field__tooltip--body {
    background-color: $primary;
    color: $white;
    margin-top: 4px;
    padding: 16px;
    width: 254px;
  }

  .field__tooltip--text {
    font-weight: 400;
  }

  .field__tooltip--arrow {
    color: $primary;
  }
}

.failure-message {
  margin-top: 10px;
}

.anchor-link {
  font-weight: 500;
}
