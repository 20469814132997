$white: #fff;
$black: #212121;
$orange: #f56c00;
$orange2: #ef6c00;

$light-gray: #dcdcdc;
$light-black: #757575;
$alto-gray: #e0e0e0;
$light-green: #edf7ec;
$gray: #9b9b9b;
$disabled-gray: #ababab;
$disabled-gray2: #f3f2f2;
$disabled-gray3: #e2e2e2;
$disabled-gray4 : rgba(0, 0, 0, .12);
$disabled-background-gray: #fafafa;
$disabled-border-gray: #d3dee3;
$semi-gray: rgba($gray, .53);
$green: #098a00;
$japanese-laurel: #388e3c;
$light-green: #e1eee2;
$light-green-border: #98cc97;
$dim-gray: #717171;
$medium-gray: #888;
$darker-gray: #666;
$darker-gray-2: #333;
$dark-gray: #1b1b1b;
$graph-gray: #b9b9b9;
$graph-line-gray: rgba(220, 220, 220, .6);
$red: #df9598;
$dark-red: #a30808;
$graph-red: #b71c1c;
$graph-light-red: #f6e4e4;
$hero-light-red: #e9bbbb;
$light-red: #f1a7a7;
$light-red2: #fee7e4;
$white-90: #ffffffe6;
$grey-blue: #3d768e;
$grey-300: rgb(228 228 228);
$dark-grey-blue: #034867;
$dark-grey-blue2: #003342;
$light-grey-blue: #2177a0;
$light-grey-blue2: #146078;
$light-blue: #34baef;
$light-blue2: #33bdef;
$light-blue3: #b6d6eb;
$light-blue4: #e6f2f8;
$light-blue5: #e1eff6;
$light-blue6: #2691c3;
$light-blue7: #ebf5f9;
$light-blue8: #c2cde7;
$light-blue9: #e3f2f9;
$light-blue10: #d1e9f4;
$light-blue11: #d1e8f2;
$light-blue12: #1f8dc1;
$lighter-blue: #f3f9fb;
$graph-blue: #62dff8;
$tropical-blue: #cdeaf8;
$tropical-blue-faded: rgba(205, 234, 248, .25);
$placeholder-gray: #9e9e9e;
$divider-line-blue: #367089;
$dark-blue: #0060a0;
$dark-blue2: #004464;
$dark-font-color: #0d0d0d;
$green-font-color: #4c9840;

$transparent: transparentize(
  $color: $white,
  $amount: .4
);

$transparent-90: transparentize(
  $color: $white,
  $amount: .9
);

// surface colors
$surface: $white;
$surface-dark: #f5f5f5;
$surface-disabled: #f0f0f0;
$surface-disabled-ME: rgba(33, 33, 33, .38);
$surface-striped: #fafafa;
$surface-light: rgba(33, 33, 33, .12);
$surface-light-blue: #f3f9fb;
$surface-light-gray: #e0eff6;
$surface-warning: #f0dc0f;
$surface-light-warning: #ffe8d9;

$error: #b00020;
$error-2: #b71c1c;
$pending: #fdab02;
$success: #098a00;
$success-bright: #64dd17;
$success-light: #4bae46;
$success-medium: #4baf46;
$success-darker: #388e3c;
$warning: #ff9800;
$warning-highlight: #fbebe1;
$warning-2: #fff2e9;
$warning-dark: #e77503;
$warning-deep-dark: #ef6c00;
$warning-deep-dark-2: rgba(239, 108, 0, .25);
$warning-background: rgba(183, 102, 28, .1);
$warning-yellow: #f0dc0f1a;
$info: #212121;
$info-dark: #000;
$info-light: rgba(33, 33, 33, .38);
$emulation-background: #ef6c00;
$utility-info: #424242;

// text on surface colors
$text-on-surface-ME: rgba($black, .6); // Medium Emphasis
$text-on-surface-disabled-HE: rgba(255, 255, 255, .38); // Disable High Emphasis
$text-on-surface-disabled: rgba($black, .38); // Disabled
$text-on-surface-help: #808080; // Text-Helper
$surface-highlighted: rgba($info, .6);

// divider
$divider-gray: #d8d8d8;
$divider-dark: rgba(102, 102, 102, .3);
$divider-black: rgba($light-black, .2);

// primary colors
$primary: #007db8;
$primary-light: #e3f2f9;
$primary-dark: #004b6c;
$on-primary: $white;
$primary-1100: #208dc1;
$primary-1050: #007ebd;
$primary-1000: #374e5a;
$primary-950: #004b6c;
$primary-900: #0f6c95;
$primary-850: #004f72;
$primary-800: #0071ae;
$primary-750: #007cb6;
$primary-700: #0082c1;
$primary-650: #0095db;
$primary-600: #0094d5;
$primary-550: #004b6f;
$primary-500: #026b97;
$primary-450: #4abdf3;
$primary-400: #007db8;
$primary-350: #00a3e0;
$primary-300: #4fbdea;
$primary-200: #7fd0f0;
$primary-150: #b4dff3;
$primary-100: #b1e3f6;
$primary-50: #e0f4fc;
$primary-25: #eaf8fd;
$primary-10: #effafe;

// secondary colors
$secondary: #003758;
$on-secondary: #33bbef;
$secondary-900: #2e373f;
$secondary-850: #4a4a4a;
$secondary-800: #404c56;
$secondary-700: #4f5f6c;
$secondary-650: #616161;
$secondary-600: #5f7383;
$secondary-550: #6f6f6f;
$secondary-500: #6c8295;
$secondary-400: #8295a4;
$secondary-300: #99a8b5;
$secondary-200: #b6c1cb;
$secondary-150: #f2f2f2;
$secondary-100: #d3dadf;
$secondary-50: #eef0f2;
$secondary-10: #e4e4e4;
$secondary-5: #ccc;

// neutral colors
$neutral: #3a3a3a;
$neutral-900: #656565;
$neutral-800: #b1b3b3;
$neutral-700: #ccc;
$neutral-600: #f9f9f9;
$neutral-666: #666;
$neutral-500: #b3bbc4;
$neutral-400: #f0f0f0;

$black-light: rgba($black, .54);
$black-bg-light: rgba($black, .4);
$black-transparent: rgba($black, 0);
$on-secondary-light: rgba($on-secondary, .1);
$on-secondary-zero: rgba($on-secondary, 0);
$white-bg: rgba($white, .32);
$white-bg-transparent: rgba($white, .87);
$selected-item-color: transparentize($on-secondary, .92);
$hovered-item-color: transparentize($on-secondary, .96);

$faded-bg-color: rgba(240, 240, 240, .35);
$selected-bg-item-color: rgba($on-secondary, .08);
// Same as above but for cases where the transparency is not applicable.
$selected-bg-color-solid: #dbf2fc;
$hovered-bg-item-color: rgba($on-secondary, .04);
$hovered-faded-bg-color: rgba(0, 0, 0, .04);

$backdrop-overlay: rgba($black, .6);

//button bar
$button-bar-blue: #e3f2f9;
$button-bar-blue-2: #f3f9fb;

//border colors
$black-light-border: rgba(0, 0, 0, .25);
$white-light-border: rgba(255, 255, 255, .25);
$white-light-shadow: rgba($secondary-5, .1);
$light-gray-border: #c9c7c5;
$grey-border: #979797;
$blue-border: #33bef0;
$lighter-gray-border: #c4c4c4;
$shadow-gray: #bdbdbd;
$shadow-light-grey: #e0e0e0;
$table-tasks-border: rgba(171, 171, 171, 1);
$table-tasks-border-light: rgba(171, 171, 171, .3);
$orange-border: #ffab75;

//paper box shadow
$box-shadow: rgba($black, .05);
$light-box-shadow: rgba($black, .14);

//card box shadow
$card-box-shadow: 0 4px 5px $box-shadow, 0 1px 10px $box-shadow, 0 2px 4px $box-shadow;
$inverse-box-shadow: 0 -4px 4px $light-box-shadow;
$card-box-shadow-dark: 0 2px 4px 0 #00000026, 0 1px 10px 0 #00000026, 0 4px 5px 0 #00000026;

// divider color
$divider-bg-color: rgba($black, .15);
$divider-light: rgba($black, .12);
$divider-dark: #b6b6b6;
// table action bar
$table-description-bg-color: rgba(235, 245, 249, .6);
$table-action-bg-color: rgba(215, 236, 247, .7);

// sortable colors
$disabled-sort: #eee;
$disabled-sort-text: #767676;

//ripple color
$ripple-blue: rgba(0, 125, 184, .65);

//Transaction feature
$light-box-shadow: rgba(0, 0, 0, .05);
$light-primary-blue: #5db9ea;

// pre migrated feature
$pre-migrated-days-color: #004b6c;
$pre-migrated-header-bg-color: #fff5f5;

$light-gray-bg: #f4f7f9;
