@import '../.styles/global.scss';

.slide-wrapper {
  background-color: $white;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;

    .icon,
    .label {
      color: $primary;
    }

    .icon-placeholder {
      display: block;
      width: 24px;
    }

    .icon {
      cursor: pointer;
    }

    &--bordered {
      border-bottom: 1px solid $black-light-border;
    }

    &--dark {
      background-color: $primary;

      .icon,
      .label {
        color: $white;
      }
    }

    @include mobile {
      min-height: 48px;
      padding: 4px 16px;
    }
  }

  &__content {
    padding: 32px 24px;

    @include mobile {
      padding: 20px 16px;
    }
  }
}
