:global(.MuiMenuItem-root) {
  &:last-of-type {
    border-bottom: none;
  }
}

.menu-item-styles {
  &:global(.MuiMenuItem-root) {
    display: block;
    font-family: Roboto, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.25rem;
    padding: 8px 16px;

    @media (max-width: 1280px) {
      font-size: .875rem;
      line-height: 1.25rem;
    }

    @media (max-width: 1023px) {
      font-size: .875rem;
      line-height: 1.25rem;
    }

    @media (max-width: 767px) {
      font-size: .875rem;
      line-height: 1.25rem;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, .04);
    cursor: pointer;
  }
}
