@import '~@gateway/components/src/.styles/global';

.cancel-button {
  border: 0;
}

.cancel-button-mobile {
  border: 2px solid $primary;
}

.right-action-buttons {
  text-align: end;

  :global(.mdc-button) {
    margin-right: 16px;
  }

  :global(.mdc-button:last-of-type) {
    margin-right: 24px;
  }
}
