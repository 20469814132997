@import '../variables';
@import '../theme-colors';

/* colors */
$button-color: $white;
$button-border: $white;

$button--default-background: $white;
$button--default-color: $primary-400;

$button--default--disabled-background: $neutral-600;
$button--default--disabled-color: $neutral-800;
$button--default--disabled--hovered-background: $secondary-10;

$button--default--hovered-border: $transparent;
$button--default--hovered-background: $transparent;

$button--default__before__after-background: var(--mdc-theme-primary, $white);
$button--default--bordered-border: $primary-400;
$button--default--bordered--hovered-background: $primary-400;
$button--default--bordered--hovered-color: $white;
$button--default--blue-background: $primary-400;
$button--default--blue-color: $white;

$button--cancel-background: $white;
$button--cancel-color: $primary-400;
$button--cancel-border: $primary-400;
$button--cancel--disabled-background: $neutral-800;
$button--cancel--disabled-color: $neutral-800;
$button--cancel--disabled__before__after-background: var(--mdc-theme-primary, $white);

$button--confirm-background: $white;
$button--confirm-color: $primary-400;
$button--confirm-border: $primary-400;
$button--confirm--disabled-background: $white;
$button--confirm--disabled-color: $neutral-800;
$button--confirm--disabled-border: $neutral-800;
$button--confirm__before__after-background: var(--mdc-theme-primary, $white);

$button--dense--hovered-color: $mdc-theme-on-secondary;
$button--dense--active-color: $mdc-theme-secondary;
$button--dense--active-color: $mdc-theme-secondary;
$button--dense--disabled-color: $darker-gray;

/* text button variable setting */
$text-button--font-size: 14px;
$text-button--line-height: 20px;
$text-button--large--padding: 14px 8px;
$text-button--large--height: 42px;
$text-button--small--padding: 6px 8px;
$text-button--small--height: 30px;
