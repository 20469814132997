@import '../../.styles/global.scss';

@mixin radio-group($paddingLeft) {
  border-bottom: 1px solid $light-gray;
  padding-left: $paddingLeft;
}

.tree {
  &__item {
    background: $white;
    position: relative;

    :global(.MuiTreeItem-iconContainer) {
      position: absolute;
      right: 8px;
    }

    :global(.MuiTreeItem-label) {
      border-bottom: 1px solid $light-gray;
    }

    :global(.MuiTreeItem-group) {
      margin-left: 0;

      .MUI-form-control-label-root {
        padding-left: 30px;
      }

      :global(.MuiTreeItem-group) .MUI-form-control-label-root {
        padding-left: 60px;
      }
    }

    .body {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .header__subtitle {
      bottom: 10px;
      display: block;
      margin-left: 42px;
      position: relative;
    }

    .expand-icon {
      color: $primary;
      transform: rotate(90deg);
      transition: .3s ease-out;

      &_up {
        transform: rotate(-90deg);
      }
    }

    &-content--readonly {
      padding: 12px;
    }

    &--active {
      background: mix($mdc-theme-on-secondary, $white, 8%);
    }

    &--bottom {
      :global(.MuiTreeItem-label) {
        border-bottom: none;
      }
    }
  }

  &__radio-group:global(.MuiFormGroup-root) {
    flex-direction: row;
  }

  &__radio-item {
    :global(.MuiTypography-root) {
      font-size: 14px;
      font-weight: normal;
    }

    :global(.MuiRadio-root):hover {
      background-color: unset;
    }

    &--level-1 {
      @include radio-group(32px);
    }

    &--level-2 {
      @include radio-group(68px);
    }

    &--level-3 {
      @include radio-group(96px);
    }
  }

  // Lint is disabled because these styles are not within the item selector.
  /* stylelint-disable no-descending-specificity */
  :global(.MuiTreeItem-root .MuiTreeItem-content) {
    &:hover {
      background-color: rgba($mdc-theme-on-secondary, .04);
    }
  }

  :global(.MuiTreeItem-root:focus > .MuiTreeItem-content) {
    background-color: initial;

    &:hover {
      background-color: rgba($mdc-theme-on-secondary, .04);
    }
  }

  :global(
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate)
  ~ .mdc-checkbox__background) {
    border-color: $light-gray;
  }

  .MUI-form-control-label-root {
    margin-left: 0;
  }
}

.tree-enhanced {
  &__item {
    position: relative;

    :global(.MuiTreeItem-iconContainer) {
      height: 24px;
      padding: 4px 8px;
      position: absolute;
      top: 0;
      width: 24px;
    }

    :global(.MuiCollapse-wrapperInner) {
      border-bottom: 1px solid $light-gray;
    }

    :global(.MuiTreeItem-group) {
      margin-left: 0;

      .MUI-form-control-label-root {
        margin-left: 0;
        margin-right: 0;
      }

      .item-content {
        display: flex;
      }
    }

    .body {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .header__subtitle {
      bottom: 4px;
      display: block;
      margin-left: 32px;
      position: relative;
    }

    .expand-icon {
      color: $primary;
      transform: rotate(-90deg);
      transition: .3s ease-out;

      &_up {
        transform: rotate(0deg);
      }
    }

    &-content--readonly {
      padding: 12px;
    }

    &--active {
      background: mix($mdc-theme-on-secondary, $white, 8%);
    }

    &--bottom {
      :global(.MuiTreeItem-label) {
        border-bottom: none;
      }
    }

    &--level-1 {
      background-color: $white;

      :global(.MuiTreeItem-label) {
        padding-left: 40px;
      }

      ul {
        :global(.MuiCollapse-wrapperInner) {
          background-color: $lighter-blue;

          :global(.MuiTreeItem-iconContainer) {
            height: 24px;
            left: 28px;
            padding: 4px 8px;
            position: absolute;
            top: 0;
            width: 24px;
          }
        }
      }
    }

    &--level-1-service-info {
      background-color: $white;

      :global(.MuiTreeItem-label) {
        padding-left: 40px;
      }

      ul {
        min-height: 0;
        padding-top: 48px;
      }
    }

    &--level-2 {
      background-color: $lighter-blue;

      :global(.MuiTreeItem-label) {
        padding-left: 68px;
      }

      ul {
        :global(.MuiCollapse-wrapperInner) {
          background-color: $primary-light;
        }
      }

      &--noGrouping {
        background-color: $lighter-blue;

        :global(.MuiTreeItem-label) {
          padding-left: 40px;
        }

        ul {
          :global(.MuiCollapse-wrapperInner) {
            background-color: $primary-light;
          }
        }
      }
    }

    &--level-3 {
      background-color: $primary-light;

      :global(.MuiTreeItem-label) {
        padding-left: 96px;
      }

      &--noGrouping {
        background-color: $primary-light;

        :global(.MuiTreeItem-label) {
          padding-left: 68px;
        }
      }
    }

    &--last-parent {
      ul {
        :global(.MuiCollapse-wrapperInner) {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        }

        li {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  .service-tree_tooltip__container {
    :global(.rmwc-icon) {
      margin-left: 16px;
    }
  }

  :global(.MuiTreeItem-root .MuiTreeItem-content) {
    &:hover {
      background-color: rgba($mdc-theme-on-secondary, .04);
    }

    border-bottom: 1px solid $light-gray;
  }

  :global(.MuiTreeView-root li:last-of-type .MuiTreeItem-content) {
    border-bottom: none;
  }

  :global(.MuiCollapse-wrapperInner .MuiTreeItem-content) {
    border-bottom: none;
  }

  :global(.MuiTreeItem-root:focus > .MuiTreeItem-content) {
    background-color: initial;

    &:hover {
      background-color: rgba($mdc-theme-on-secondary, .04);
    }
  }

  :global(
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate)
  ~ .mdc-checkbox__background) {
    border-color: $light-gray;
  }

  .MUI-form-control-label-root {
    margin-left: 0;
  }

  :global(.MuiCheckbox-root) {
    padding: 4px 8px 4px 0;
  }
}

.label-info {
  align-items: center;
  background-color: $lighter-blue;
  display: flex;
  height: 56px;
  left: 0;
  padding-left: 68px;
  position: absolute;
  width: calc(100% - 68px);

  :global(.rmwc-icon) {
    padding-right: 8px;
  }
}

.card-status {
  border: 1px solid $placeholder-gray;
  border-radius: 2px;
  color: $placeholder-gray;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-left: 8px;
  padding: 1px 8px;
}

.load-more {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.expand-all-button.expand-all-button {
  border-radius: 0;
  border-right: 1px solid $alto-gray;
  color: $primary;
  height: 20px;
  margin-bottom: 12px;
  padding: 0 8px 0 0;
}

.collapse-all-button.collapse-all-button {
  color: $primary;
  height: 20px;
  margin-bottom: 12px;
  padding: 0 8px;
}
