@import '~@gateway/components/src/.styles/global';

.webauthn-modal-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: auto;
  padding: 8px;
  width: 536px;

  .close-icon {
    align-self: end;
    background-color: $white;
    color: $primary;
    cursor: pointer;
    width: 24px;
  }

  .title-container {
    display: flex;
    padding-bottom: 16px;
    padding-left: 16px;

    .title {
      padding-bottom: 10px;
      padding-left: 10px;
    }

    .security-key-icon {
      background-image: url('../mfa-auth-option/assets/mfa-fido-icon.svg');
      background-repeat: no-repeat;
      margin-top: 2px;
      width: 47px;
    }
  }

  .body {
    padding-left: 16px;
  }

  .image-container {
    display: flex;
    justify-content: space-evenly;

    .plug-in-container {
      display: flex;
    }

    .instructions-container {
      display: flex;
    }

    .plug-in-image {
      background-image: url('../../page-layout/components/profile-settings-drawer/components/trusted-devices/components/assets/connect_security_key_icon.svg');
      background-repeat: no-repeat;
      height: 58px;
      width: 90px;
    }

    .instructions-image {
      background-image: url('../../page-layout/components/profile-settings-drawer/components/trusted-devices/components/assets/instruction_icon.svg');
      background-repeat: no-repeat;
      height: 24px;
      margin-right: 10px;
      margin-top: 10px;
      width: 74px;
    }

    .plug-in-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      padding-top: 8px;
    }

    .instructions-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      padding-top: 8px;
    }
  }

  .button-container {
    display: flex;
    flex-direction: row-reverse;

    .start-button {
      width: fit-content;
    }

    .cancel-button {
      width: fit-content;
    }
  }

  .payment-container {
    background-color: $primary-light;
    margin: 8px 0 24px;
    padding: 8px 24px;

    .to {
      color: $light-black;
      padding-right: 29px;
    }

    .amount {
      color: $light-black;
    }
  }

  .payment-details {
    align-items: center;
    display: flex;
    gap: 8px;
    overflow: auto;
    padding-top: 8px;
  }

  .payment-details-title {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  .support-link-container {
    margin-bottom: 16px;
    padding-left: 16px;
    padding-top: 8px;

    .support-link-label {
      color: $black;
      display: inline-block;
      font-size: 14px;
      line-height: 22px;

      @include mobile {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .support-link {
      color: $primary;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      margin-left: 5px;

      @include mobile {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
