@import '../.styles/global';

.tablev2 {
  & :global(.MuiTableCell-root) {
    box-sizing: border-box;
    height: 40px;
    padding: 0;
  }

  & :global(.MuiTableCell-head) {
    border-bottom: 1px solid $placeholder-gray;
    font-size: 14px;
  }

  &:global(.MuiTableContainer-root) {
    overflow-x: unset;
  }

  & :global(.MuiTableCell-paddingCheckbox) {
    padding: 0 4px;
  }
}

.account-label {
  &__checkbox {
    padding-left: 0;
  }

  &__header-label:first-of-type {
    padding-left: 45px;
  }

  &__sticky-header {
    background-color: $white;
    border-bottom: 1px solid $placeholder-gray;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.buttons-container {
  align-items: center;
  background-color: $white;
  bottom: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15), 0 1px 10px 0 rgba(0, 0, 0, .15), 0 4px 5px 0 rgba(0, 0, 0, .15);
  display: inline-flex;
  justify-content: flex-end;
  margin-left: -21px;
  overflow: scroll;
  padding: 12px 37px 12px 2px;
  position: fixed;

  &__selected-count {
    color: $info-dark;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    left: 28px;
    line-height: 22px;
    margin-left: 0;
    margin-top: 10px;
    position: absolute;
  }

  &__button {
    align-items: center;
    background: $primary !important;
    border-radius: 4px;
    border-style: none;
    box-shadow: none !important;
    color: $white;
    cursor: pointer;
    display: flex;
    font-size: .875rem;
    font-weight: bold;
    justify-content: center;
    margin-left: 33.5rem;
    padding: 12px 36px;
    width: 5px !important;

    &:disabled {
      background: $disabled-gray !important;
      color: $text-on-surface-disabled-HE !important;
    }
  }
}

.expand {
  &:global(.MuiTableCell-root) {
    box-sizing: border-box;
    width: 24px;
  }

  &:global(.MuiTableCell-paddingCheckbox) {
    padding: 0;
  }
}

.select {
  &:global(.MuiTableCell-root) {
    box-sizing: border-box;
    width: 24px;
  }

  &__checkbox {
    &:global(.MuiFormControlLabel-root) {
      margin-right: 0;
      padding-left: 70px;
    }
  }
}

.group {
  & :global(.MuiTableCell-root) {
    background-color: $surface-dark;
    height: 40px;
  }

  &__label {
    &:global(.MuiTypography-root) {
      margin-left: 12px;
    }
  }

  &__custom-lable {
    &:global(.MuiTypography-root) {
      display: flex;
    }
  }

  &__sublabel {
    &:global(.MuiTypography-root) {
      color: $disabled-gray;
    }
  }
}

.action-cell {
  &__container {
    align-items: center;
    display: flex;

    :global(.MuiTypography-body2) {
      font-size: 14px;
      font-weight: 500;
    }

    &--indented {
      justify-content: flex-end;
      margin-left: 24px;
    }
  }

  &__expansion-icon {
    color: $primary;
    margin: 0 8px;
    vertical-align: middle;
  }

  &__hide-toggle-button {
    opacity: 0;
  }

  &__checkbox-wrapper {
    & :global(.MuiFormControlLabel-root) {
      margin-right: 0;
    }
  }

  &__empty-cell {
    width: 40px;
  }
}
