@import '../.styles/global.scss';

.switch {
  :global(.MuiSwitch-root) {
    margin: 0;

    :global(.MuiSwitch-thumb) {
      background-color: $switch--checked__thumb-border;
    }

    :global(.Mui-disabled) {
      :global(.MuiIconButton-label) {
        :global(.MuiSwitch-thumb) {
          background-color: $secondary-150;
        }
      }
    }

    :global(.MuiSwitch-colorSecondary:not(.Mui-disabled) + .MuiSwitch-track) {
      background-color: $switch__track-background;
    }

    :global(.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track) {
      background-color: $neutral-800;
      opacity: .8;
    }

    :global(.MuiSwitch-colorSecondary.Mui-checked:not(.Mui-disabled) + .MuiSwitch-track) {
      background-color: $on-secondary;
      opacity: .55;
    }
  }
}
