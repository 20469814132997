@import '~@gateway/components/src/.styles/global';
@import '../../../scene.module.scss';

.field {
  display: flex;
  flex-direction: column;

  &-label {
    color: $light-black;
    font-weight: 400;
    padding-right: 4px;
  }

  &-text {
    font-weight: 400;
    text-overflow: ellipsis;
  }
}

.cop-warning {
  display: flex;
  flex-direction: column;

  &--text-container {
    margin-bottom: 16px;
  }

  &--subtext-container {
    align-items: baseline;
    display: flex;

    &__space {
      &:global(.MuiTypography-root) {
        margin-right: 5px;
      }
    }
  }

  &__header {
    display: flex;
  }

  &__value {
    display: flex;
    flex-grow: 1;
  }

  &__ml {
    padding-left: 8px;
  }

  &__border {
    border: 1px solid $placeholder-gray;
    border-radius: 4px;
    margin: 8px 0;
    padding: 0 8px;
  }
}

.warning {
  align-items: center;
  display: flex;
}

.icon {
  color: $warning;
  margin: 0 16px 0 8px;

  &:global(.material-icons) {
    font-size: 40px;

    @include mobile {
      font-size: 24px;
    }
  }
}

.icon-error {
  color: $error;
}

.bold-text {
  font-weight: 500;
}
