@import '~@gateway/components/src/.styles/global';

.action-button {
  border: none;
}

.copy-container {
  align-items: center;
  display: flex;
  font-weight: bold;
  width: 100%;

  .copy-message {
    display: flex;
    padding-right: 8px;
  }
}

.template-details-title {
  align-items: center;
  display: flex;

  .back-arrow-icon {
    color: $white;
    cursor: pointer;
    font-size: 24px;
    margin-right: 20px;
    margin-top: 10px;
  }
}

.warning-icon {
  color: $error;

  &:global(.material-icons) {
    font-size: 40px;
  }
}

.error-dialog {
  .content {
    margin-right: 5px;
  }

  .save-error-icon {
    color: $error;
    font-size: 40px;
  }
}

.template-details {
  background-color: $surface-disabled;
}

.placeholder__cell {
  .error-icon {
    color: $light-gray;
    font-size: 40px;
  }

  .support-link {
    color: $primary;
    font-weight: 500;
    padding-left: 5px;
    text-decoration: none;
  }

  .error-message-title {
    color: $darker-gray;
    padding-top: 8px;
  }

  .error-text {
    color: $dim-gray;
    padding: 15px 16px 8px;
    text-align: center;
  }
}

.template-error-message {
  align-items: center;
  color: $light-black;
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: center;

  .error-icon {
    color: $light-gray;
    font-size: 72px;
    margin-bottom: 16px;
  }

  .error-text {
    margin-bottom: 12px;
  }
}

.empty-template-list-message {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  height: 50vh;
  justify-content: center;
  padding: 0;

  .empty-template-list-icon {
    color: $alto-gray;
    font-size: 72px;
  }

  .empty-template-list-header {
    /* Desktop + Tablet (MD)/Heading/Heading 6 */

    color: $light-black;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;

    /* identical to box height, or 140% */

    text-align: center;
  }

  .empty-template-message {
    color: #757575;
    font-size: 1rem;
  }
}

.account-name {
  display: inline-block;
  max-width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;

  @include tablet-portrait {
    max-width: 49%;
  }

  @include tablet-landscape {
    max-width: 60%;
  }

  @include mobile {
    max-width: 55%;
  }

  &-check {
    max-width: 58%;
  }
}
