@import '../.styles/global';

.loaderPanel {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  max-width: $grid-xl-max-width;
  pointer-events: none;
  top: 0;
  width: 100%;

  &--absolute {
    position: absolute;
  }
}

.loaderWrapper {
  padding: 24px;
}
