@import '@gateway/components/src/.styles/global';

.circular-progress {
  &__label {
    align-items: center;
    bottom: 0;
    color: $primary;
    display: inline-flex;
    justify-content: center;
  }
}

.urgent {
  color: $graph-red;
}

.timer-container {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
}
