@import 'variables';

/* Mobile */
@mixin mobile {
  @media only screen
    and (max-width: 767px) {
    @content;
  }
}

/* Mobile or tablet */
@mixin mobile-or-tablet {
  @media only screen
    and (max-width: 1279px) {
    @content;
  }
}

/* Tablet */
@mixin tablet {
  @media only screen
    and (max-width: 1279px)
    and (min-width: 768px) {
    @content;
  }
}

/* Tablet (orientation: portrait) */
@mixin tablet-portrait {
  @media only screen
    and (max-width: 1023px)
    and (min-width: 768px)
    and (max-aspect-ratio: 16/9) {
    @content;
  }
}

/* Tablet-portrait or mobile */
@mixin tablet-portrait-or-mobile {
  @media only screen
    and (max-width: 1023px) {
    @content;
  }
}

/* Tablet (orientation: landscape) */
@mixin tablet-landscape {
  @media only screen
    and (max-width: 1279px)
    and (min-width: 1024px)
    and (max-aspect-ratio: 16/9) {
    @content;
  }
}

/* Desktop */
@mixin desktop {
  @media only screen
    and (min-width: 1280px) {
    @content;
  }
}

/* Desktop or tablet */
@mixin desktop-or-tablet {
  @media only screen
    and (min-width: 768px) {
    @content;
  }
}

/* Desktop or tablet landscape */
@mixin desktop-or-tablet-landscape {
  @media only screen
    and (min-width: 1024px) {
    @content;
  }
}

/* Desktop Extra-Large */
@mixin desktop-xl {
  @media only screen
    and (min-width: 1440px) {
    @content;
  }
}

/* Desktop Extra-Large-Plus */
@mixin desktop-plus {
  @media only screen
    and (min-width: 1776px) {
    @content;
  }
}

/* min screen for drag and drop 3 liner */
@mixin drag-and-drop-breakpoint {
  @media only screen
    and (max-width: 1610px) {
    @content;
  }
}

@mixin side-drawer {
  max-width: none;
  width: 100vw;

  @include desktop-or-tablet {
    width: 472px;
  }

  @include desktop {
    width: 616px;
  }
}

@mixin dropdown {
  background: $white;
  border-radius: 4px;
  box-shadow: $menu-box-shadow;
  position: absolute;
}

@mixin menu-item {
  color: $info;

  &::before,
  &::after {
    display: none;
  }
}

@mixin grid-custom {
  min-width: $grid-child-min-width;
  padding: $grid-child-padding;

  @media (min-width: $grid-xl-breakpoint) {
    min-width: $grid-xl-max-width;
  }
}

@mixin grid-cell-center {
  display: flex;
  flex-direction: column;
}

@mixin grid-xl-max-width-override {
  @media (min-width: $grid-xl-breakpoint) {
    max-width: 100%;
  }
}

@mixin hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin scrollbar-thin {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;

    &-thumb {
      background-color: darkgrey;
      border-radius: 4px;
    }
  }
}

@mixin transform-icon {
  transform: rotate(180deg);
  transition: transform .3s;
}

@mixin fixed-footer {
  background-color: $white;
  border-top: $light-gray solid 1px;
  bottom: 0;
  height: 90px;
  position: fixed;
  right: 0;
  width: calc(100% - #{$nav-sidebar-width});

  @media (min-width: $nav-sidebar-xl-breakpoint) {
    width: calc(100% - #{$nav-sidebar-xl-width});
  }

  @include mobile {
    height: 100px;
    width: 100%;
  }
}

@mixin form-error-colors($new-color) {
  :global(.Mui-error) {
    color: $new-color;
  }

  :global(.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline) {
    border-color: $new-color;
  }
}

@mixin dashboard-filter-dropdown() {
  margin-top: -16px;
  right: 42px;
}

@mixin hide-bage {
  :global(.MuiBadge-badge.MuiBadge-invisible) {
    display: none;
  }
}
