@import '../.styles/global.scss';

.circular-progress {
  &__container {
    display: inline-flex;
    padding: 8px;
    position: relative;
  }

  &__label {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
