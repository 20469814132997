.progress-manager-item {
  &__label {
    align-items: center;
    display: flex;
  }

  &__name {
    margin-left: 8px;
    overflow-wrap: anywhere;
  }
}
