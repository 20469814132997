@import '~@gateway/components/src/.styles/global';

.message-container {
  /**
  * Disabled because overriding .MuiTypography styling in .message-container
  * does not violate specificity. We do not want to override .MuiTypography
  * for the entire dialog, only inside the .message-container as per UI spec.
  **/
  /* stylelint-disable no-descending-specificity */
  :global(.MuiTypography-body1) {
    font-size: 14px;
    font-weight: 400;
  }

  :global(.MuiTypography-body2) {
    font-weight: 400;
  }

  /* stylelint-enable no-descending-specificity */
  .expired-label {
    padding-bottom: 16px;
  }

  .final-rate-secured-container {
    background-color: $light-green;
    border: 1px solid $light-green-border;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    height: 56px;
    margin: 8px 0;

    .final-rate-success-icon {
      padding: 4px 0 0 6px;

      .check-circle-icon {
        fill: $success;
        font-size: 24px;
        margin-top: 4px;
        padding-right: 8px;
      }
    }

    .final-rate-secured-content {
      padding: 8px 8px 8px 0;

      .final-rate-secured-header {
        :global(.MuiTypography-body2) {
          font-weight: 400;
        }
      }

      .final-rate-secured-value {
        display: flex;
        flex-direction: row;

        :global(.MuiTypography-body2) {
          font-size: 14px;
          font-weight: 500;
        }

        .timer-content {
          display: flex;
          padding: 2px;

          .timer-safe {
            color: $success;
          }

          .timer-warning {
            color: $error;
          }
        }
      }
    }
  }

  .payment-details {
    column-gap: 4px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(136px, 1fr));
    margin: 28px 0;
    row-gap: 12px;
    width: 100%;

    .payment-details-field {
      margin: 0;
      margin-right: 39px;
    }

    .payment-details-field:first-child {
      width: 145px;
    }

    .payment-details-field:last-child {
      margin: 0;
    }
  }

  .split-final-rate-instructions-table {
    &:global(.MuiTableContainer-root) {
      max-width: 526px;
    }
  }

  .table-row-content-left {
    overflow-wrap: break-word;
    width: 332px;
  }
}
