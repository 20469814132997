@import '~@gateway/components/src/.styles/global';

.password-enter-form {
  .form-heading {
    color: $info;
    font-size: 14px;
    height: 20px;
    letter-spacing: 0;
    line-height: 20px;
    width: 100%;
  }

  .form-text {
    color: $secondary-650;
    font-size: 14px;
    height: 44px;
    line-height: 22px;
    margin-top: 8px;
    width: 100%;
  }

  .form-submit-button {
    height: 42px;
    margin: $textfield-margin;
  }

  .textfield {
    margin: $textfield-margin;
    width: 100%;
  }
}

:global {
  .user-locked {
    z-index: 15000 !important; /* stylelint-disable-line declaration-no-important */
  }
}
