@import '~@gateway/components/src/.styles/global';

.password-reset-form {
  margin-bottom: 24px;

  .criteria-elem {
    align-items: baseline;
    display: flex;
    margin-bottom: 8px;

    &__text {
      font-size: 14px;
      line-height: 20px;

      &--error {
        color: $error;
      }
    }
  }

  .header-logo {
    margin: 24px 0 32px;

    &--img {
      height: 39px;
    }
  }

  .password-help-label-text {
    margin-top: 20px;
  }

  .password-group-form {
    @include mobile {
      height: auto;
    }
  }

  .error-message-container {
    margin-left: 20px;
    width: 168px;

    @include mobile {
      margin-left: 0;
      width: 100%;
    }
  }

  .password-instructions {
    margin-bottom: 24px;
  }

  .input-field {
    background-color: var(--neutrals-grey-00, #fff);
    box-sizing: border-box;
    height: 48px;
    margin: $textfield-margin;

    @include mobile {
      width: 100%;
    }
  }

  .input-field:first-of-type {
    margin-top: 0;
  }

  .button {
    border-width: 2px;
    box-sizing: border-box;
    height: 42px;
  }

  .error-criteria {
    color: $error;
  }

  .error-message-text {
    font-weight: 400;
  }

  .server-error-text {
    color: $error;
    display: block;
    font-size: 14px;
    line-height: 20px;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 1rem;

    @include mobile {
      margin-top: 1rem;
    }

    .cancel-button {
      width: 100%;
    }

    .continue-button {
      border: none;
      margin-left: 10px;
      padding: 6px;
      width: 100%;
    }
  }

  &.change-password-page {
    gap: 0;

    .error-message-container {
      margin-bottom: 30px;
      margin-left: 0;
      width: 100%;
    }

    .input-field {
      margin: $textfield-margin;
      width: 100%;
    }

    .password-confirm-input {
      margin-bottom: 40px;
    }

    .password-help-label {
      margin-bottom: 4px;
    }

    .buttons-container {
      margin-top: 0;
    }

    :global {
      .mdc-layout-grid__inner {
        gap: 0;
      }

      .textfield-container {
        margin-bottom: 14px;
      }
    }
  }

  :global(.textfield-container) {
    margin-bottom: 25px;

    @include mobile {
      margin-bottom: .5rem;
    }

    &:last-of-type {
      margin-bottom: 10px;

      @include mobile {
        margin-bottom: .2rem;
      }
    }
  }
}
