@mixin inline-grid($count) {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat($count, calc(34% - 24px));
  padding: 16px 0;
}

.inline-grid {
  &--one-col {
    @include inline-grid(1);

    &--extended {
      grid-template-columns: 1fr;
    }
  }

  &--two-col {
    @include inline-grid(2);

    &--extended {
      grid-template-columns: 1fr 2fr;
    }
  }

  &--three-col {
    @include inline-grid(3);
  }
}
