@import '~@gateway/components/src/.styles/global';

.message-container {
  /**
  * Disabled because overriding .MuiTypography styling in .message-container
  * does not violate specificity. We do not want to override .MuiTypography
  * for the entire dialog, only inside the .message-container as per UI spec.
  **/
  /* stylelint-disable no-descending-specificity */
  :global(.MuiTypography-body1) {
    font-size: 16px;
    font-weight: 400;
  }

  :global(.MuiTypography-body2) {
    font-weight: 400;
  }
  /* stylelint-enable no-descending-specificity */

  .expired-label {
    padding-bottom: 16px;
  }

  .rate-timer {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;

    @include mobile {
      flex-direction: column;
    }

    .timer-container {
      display: flex;

      @include mobile {
        padding-top: 4px;
      }

      .timer {
        font-size: 16px;
      }

      .timer-label {
        color: $darker-gray;
        font-size: 16px;
      }

      .timer-safe {
        color: $success;
      }

      .timer-warning {
        color: $error;
      }
    }
  }

  .amount-container {
    display: flex;

    @include mobile {
      flex-direction: column;
    }

    .amount-label {
      color: $surface-disabled-ME;
      padding-bottom: 4px;
    }

    .first-amount {
      @include desktop-or-tablet {
        padding-right: 52px;
      }

      @include mobile {
        padding: 16px 0;
      }
    }

    .send-date {
      margin-right: 12px;
    }

    .cutoff-send-date {
      color: $warning-dark;
    }
  }

  .final-rate-cutoff-subtitle {
    margin-bottom: 32px;

    :global(.MuiTypography-caption) {
      display: inline;

      &:nth-child(2) {
        color: $warning-dark;
      }
    }
  }

  .est-delivery {
    margin-top: 24px;

    @include mobile {
      margin-top: 4px;
    }

    &--label {
      color: $surface-disabled-ME;
      margin-top: 8px;
    }
  }
}
