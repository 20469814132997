@import '../.styles/global.scss';

$mdc-layout-grid-breakpoints: $grid-breakpoints;
$mdc-layout-grid-columns: $grid-columns;
$mdc-layout-grid-default-margin: $grid-margin;
$mdc-layout-grid-default-gutter: $grid-gutter;
$mdc-layout-grid-column-width: $grid-column-width;
$mdc-layout-grid-default-column-span: $grid-column-span;
$mdc-layout-grid-max-width: $grid-max-width;

@import './layout-grid/mdc-layout-grid.scss';

.mdc-layout-grid {
  @media (min-width: $grid-xl-breakpoint) {
    max-width: $grid-xl-max-width;
  }
}
