@import '~@gateway/components/src/.styles/global';

.instruction-table {
  align-items: flex-start;
  color: $black;
  display: flex;
  flex-direction: column;
  padding: 0;

  .instruction-table-content {
    :global(.MuiTableCell-root) {
      padding: 0;
    }

    .instruction-table-header {
      background-color: $alto-gray;

      :global(.MuiTableCell-head) {
        border-bottom: 1px solid $placeholder-gray;
        color: $black;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 8px 4px;
      }

      .right-header {
        &:global(.MuiTableCell-head) {
          text-align: right;
        }
      }
    }

    .instruction-table-body {
      .table-row {
        .instruction-table-value {
          padding: 8px 4px;
        }

        .instruction-table-last-value {
          padding: 6px 4px;
          text-align: right;
          vertical-align: top;
        }

        .instruction-table-icon-value {
          color: $japanese-laurel;
          padding: 6px 8px;
          text-align: center;
          width: 1rem;
        }

        .table-row-content-left {
          overflow-wrap: break-word;
          width: 332px;
        }

        .sub-text {
          color: $light-black;

          &:global(.MuiTypography-body1) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
