@import '../.styles/global.scss';

.striped {
  &:global(.MuiTableRow-root) {
    &:nth-of-type(even) {
      background-color: transparentize($surface-disabled, .65);
    }
  }

  &--hover {
    &:global(:not(.MuiTableRow-head)) {
      &:hover {
        background-color: $primary-25;
      }
    }
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}
