@import '~@gateway/components/src/.styles/global';

.soft-bulletin {
  &-description {
    color: $info;
    font-size: 12px;
    word-break: break-word;
  }

  &-subject {
    &:global(.MuiTypography-body1) {
      margin-bottom: 15px;
      word-break: break-word;
    }
  }
}
