@import '../.styles/global';

.highlight-wrapper {
  background-color: $hovered-item-color;

  .highlight {
    border: none;
    border-radius: 0;
    color: $info;
  }
}

.start-highlight-wrapper {
  &_mid-row {
    background: linear-gradient(90deg, $white 50%, $hovered-item-color 50%);
  }

  .start-highlight {
    background-color: $primary;
    border-radius: 50%;
    color: $white;

    &:hover {
      background-color: $primary;
    }
  }
}

.end-highlight-wrapper {
  &_mid-row {
    background: linear-gradient(90deg, $hovered-item-color 50%, $white 50%);
  }

  .end-highlight {
    background-color: $primary;
    border-radius: 50%;
    color: $white;

    &:hover {
      background-color: $primary;
    }
  }
}

.end-row {
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
}

.start-row {
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
}

.end-picker {
  :global(.MuiInputBase-root) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  :global(.PrivateNotchedOutline-root-1) {
    border-left: 0;
  }
}

.start-picker {
  :global(.MuiInputBase-root) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: -1px;
  }
}

.start-picker,
.end-picker {
  &:global(.MuiTextField-root) {
    width: 50%;
  }
}
