@import '~@gateway/components/src/.styles/global';

@mixin fontSize() {
  @include mobile {
    font-size: 16px;
  }
}

.unmasked-account {
  @include fontSize();

  display: flex;
  flex-direction: column;
  line-height: 16px;
  width: 100%;

  @include mobile {
    width: 100%;
  }

  .title-wrapper {
    font-weight: 500;
  }

  .number-wrapper {
    font-weight: 400;
    white-space: break-spaces;
    word-break: break-word;
  }

  & :global(.material-icons) {
    font-size: 16px;
  }

  &__label {
    flex-grow: 1;
    flex-shrink: 0;
    margin-bottom: 4px;
    margin-right: 24px;
    min-width: 130px;
    text-align: left;
    text-transform: capitalize;
  }

  &__account-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;

    @include mobile {
      width: 100%;
    }

    &__icon {
      @include fontSize();

      align-self: flex-start;
      cursor: pointer;
      line-height: 16px;
      margin-left: 8px;
    }
  }

  &__snackbar {
    &:global(.MuiSnackbar-anchorOriginBottomCenter) {
      bottom: 10px;
    }
  }
}
