@import '~@gateway/components/src/.styles/global';

.dialog {
  @include mobile {
    height: 100%;
    width: 100%;
  }

  /* stylelint-disable declaration-no-important */
  z-index: 1500 !important;

  :global(.MuiDialog-paperFullScreen) {
    align-items: center;
    display: grid;
    margin: 7px;
    max-height: 96%;
  }
}

.content-container {
  background: $white;
  height: auto;
  padding: 30px;
  position: relative;
  width: 536px;

  @include mobile {
    height: 100%;
    padding: 24px;
    width: 100%;
  }

  @include tablet {
    padding: 24px;
  }

  .mfa-modal-close-btn {
    background-color: $white;
    color: $primary-400;
    cursor: pointer;
    height: 20px;
    margin: 23px 16px 0 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    z-index: 1;

    @include mobile {
      margin: 13px 13px 0 0;
    }
  }

  .fido-options-cell {
    padding: 0;

    :global(.mdc-layout-grid__inner) {
      grid-gap: 0;
      justify-content: center;
    }

    .title-container {
      margin-bottom: 24px;

      &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
      }

      &__subtitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-top: 24px;

        &--user-id {
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }

    .option-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
