@import '~@gateway/components/src/.styles/global';

.premigrated-client-error-dialog {
  @include desktop-or-tablet {
    margin-left: 62px;
  }

  .icon {
    color: $error;
    font-size: 40px;
  }

  :global(.mdc-button) {
    height: 30px;
    min-width: 51px;
  }

  .message {
    font-weight: 400;
  }

  .ul-list {
    margin: 0 0 24px;
  }

  .days_container,
  .days_container_one_day {
    align-items: center;
    display: flex;
    gap: .5rem;
    margin-bottom: 24px;
    margin-top: 40px;
    padding: 0 36px;

    .remaining_days {
      color: $pre-migrated-days-color;
      font-weight: 800;
    }
  }

  .feature-unavailable-container {
    .sub-header {
      margin-bottom: 24px;
    }

    .sub-header,
    .sub-header-1 {
      font-weight: 400;
    }
  }
}
