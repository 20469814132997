@import '~@gateway/components/src/.styles/global';

.country-name {
  margin-left: 8px;
}

.country-list-item {
  cursor: pointer;
  height: 44px;

  &:first-of-type {
    padding-top: 16px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $surface-light-gray;
  }

  &:hover {
    background-color: $surface-dark;
  }
}
