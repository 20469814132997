@import '../variables';

/* colors */
$textfield--outlined--focused-border: $primary-400;
$textfield--focused-carret: $primary-400;

$textfield__text-helper--focused: $primary-400;
$textfield__floating-label: $primary-400;

$textfield--helperText--margin-left: 16px;
$textfield--helperText--margin-top: 4px;

$textfield--margin-top: 20px;
$textfield--margin-bottom: 20px;
$textfield--height: 48px;

$textfield--padding: 16px 14px 16px 14px;
$autocomplete--padding: 11px;

$textfield--height:48px;
