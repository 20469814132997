@import '~@gateway/components/src/.styles/global';

.dialog {
  :global(.transition-modal-content) {
    max-height: calc(100vh - 150px);
    max-width: 570px;
    overflow: auto;
    width: 570px;
  }

  &--error {
    :global(.transition-modal-content) {
      width: auto;
    }

    @include mobile {
      :global(.transition-modal-content) {
        max-width: 70%;
      }
    }
  }

  :global(.mdc-layout-grid__inner) {
    align-items: center;
    display: flex;
    width: auto;
  }

  :global(.transition-modal-buttons) {
    left: 15px;
    position: relative;
    top: 10px;
  }

  :global(.MuiFormControl-root) {
    margin: 20px 0;
  }

  &--no-bottom-spacing {
    :global(.transition-modal-info) {
      margin-bottom: -35px;
      margin-top: 0;
    }
  }
}

.drawer {
  &--error {
    :global(.MuiPaper-root) {
      background-image: url('~@gateway/components/src/.assets/images/svb_chevron_light.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
